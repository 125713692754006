import { createSelector } from 'reselect';
import { RootState } from '..';

const ticketsState = (state: RootState) => state.tickets;

export const selectError = createSelector(ticketsState, (tickets) => tickets.error);
export const selectTickets = createSelector(ticketsState, (tickets) => tickets.tickets);
export const selectTicketDetails = createSelector(ticketsState, (tickets) => tickets.tickets.ticketDetails);
export const selectTicketHistory = createSelector(ticketsState, (tickets) => tickets.tickets.history);
export const selectLoading = createSelector(ticketsState, (tickets) => tickets.loading);
export const selectActionSuccessfully = createSelector(ticketsState, (tickets) => tickets.actionSuccessfully);
