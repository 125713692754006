import React from 'react';
import { styled } from '@mui/material/styles';
import theme from '../../theme';
import { Typography } from '@mui/material';
import { hexToRGB } from '../../helpers';

/* ------- Styles ------- */
const ProgressWrapper = styled('div')({
  width: '100%',
  display: 'flex',
  gap: '2px',
  alignItems: 'center',
});

const Progress = styled('div')<{
  color: string;
  total: number;
}>(({ color, total }) => ({
  position: 'relative',
  width: `${total}%`,
  height: '12px',
  backgroundColor: hexToRGB(color, 0.2),
  borderRadius: '8px',
}));

const FilledProgress = styled('div')<{
  color: string;
  value: number;
  total: number;
}>(({ color, value, total }) => ({
  position: 'absolute',
  width: `${value}%`,
  height: '12px',
  backgroundColor: color,
  borderRadius: value === total ? '8px' : '8px 0px 0px 8px',
}));

const ProgressDivider = styled('div')<{
  numberOfDivider: number;
}>(({ numberOfDivider }) => ({
  position: 'absolute',
  left: `${numberOfDivider * 10}%`,
  width: '2px',
  height: '12px',
  backgroundColor: '#fff',
}));

/* ------- Types ------- */
interface IProgressBarProps {
  value: number;
  total: number;
  color?: 'success' | 'warning' | 'error' | 'primary' | string;
}

const ProgressBar: React.FC<IProgressBarProps> = ({ value, total, color = 'primary' }) => {
  const getColor = () => {
    switch (color) {
      case 'success':
        return theme.palette.success.main;
      case 'warning':
        return theme.palette.warning.main;
      case 'error':
        return theme.palette.error.main;
      case 'primary':
        return theme.palette.primary.main;
      default:
        return color;
    }
  };

  return (
    <ProgressWrapper data-testid='progressBar'>
      <Progress data-testid={`progressItem-${color}`} color={getColor()} total={total}>
        <FilledProgress color={getColor()} value={parseInt(value.toFixed(2))} total={total} />
        {Array.from({ length: (total - 10) / 10 }, (_, i) => i + 1).map((_, index) => (
          <ProgressDivider key={index} numberOfDivider={index + 1} />
        ))}
      </Progress>
      <Typography
        variant='body1'
        sx={{ fontWeight: '700', marginLeft: '16px' }}
        color={getColor()}
      >{`${value === 0 ? value : value.toFixed(2)}%`}</Typography>
    </ProgressWrapper>
  );
};

export default ProgressBar;
