import React, { useState, useEffect } from 'react';
import { User } from '../helpers';
import { useSelector } from './redux';
import { selectAuthUser } from '../store/selectors/user';
import { useNavigate } from 'react-router-dom';

interface IAccessControl {
  children: React.ReactNode;
  noAccessComponent?: React.ReactNode;
  accessKeys: string[];
  redirectUrl?: string;
}

const useAccessControl = () => {
  const authUser = useSelector(selectAuthUser);
  const navigate = useNavigate();
  const [userAccess, setUserAccess] = useState(new User(authUser?.organizationPermissions));

  const AccessControl: React.FC<IAccessControl> = ({ children, noAccessComponent, accessKeys, redirectUrl }) => {
    if (accessKeys.some((key) => userAccess[key])) {
      return children;
    }

    if (redirectUrl) {
      navigate(redirectUrl);
    }

    if (noAccessComponent) {
      return noAccessComponent;
    }

    return null;
  };

  useEffect(() => {
    setUserAccess(new User(authUser?.organizationPermissions));
  }, [authUser]);

  return { userAccess, AccessControl };
};

export default useAccessControl;
