import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from '../../../../../../hooks/redux';
import { sendServiceDocumentPending } from '../../../../../../store/slices/machine';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import FileUpload from '../../../../../../components/FileUpload';
import { selectMachineDetails } from '../../../../../../store/selectors/machine';

/* ------- Types ------- */
interface IUploadDocumentProps {
  open: boolean;
  onClose: () => void;
}

/* ------- Components ------- */
const UploadDocument: React.FC<IUploadDocumentProps> = ({ open, onClose }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const machineDetails = useSelector(selectMachineDetails);
  const [file, setFile] = useState<File[]>([]);

  const handleSaveFiles = () => {
    if (machineDetails && file) {
      const formData = new FormData();
      formData.append('file', file[0]);

      dispatch(
        sendServiceDocumentPending({
          machineId: machineDetails.id,
          file: formData,
        }),
      );

      onClose();
    }
  };

  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth='xs'>
      <DialogTitle
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          fontSize: '24px',
          fontWeight: '700',
          color: (theme) => theme.palette.black[100],
        }}
      >
        {t('lab:detailView:serviceDocuments:upload:title')}
        <IconButton
          size='small'
          aria-label='close'
          color='inherit'
          onClick={onClose}
          sx={{ '&:hover': { background: 'none' } }}
        >
          <CloseIcon fontSize='small' />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <FileUpload
          selectFileText={t('lab:detailView:serviceDocuments:upload:selectFile')}
          infoText={t('lab:detailView:serviceDocuments:upload:infoText')}
          fileTypes={{ 'text/pdf': ['.pdf'] }}
          maxFiles={1}
          onChange={setFile}
        />
      </DialogContent>
      <DialogActions sx={{ justifyContent: 'center', gap: '24px', padding: '8px 24px 24px' }}>
        <Button variant='outlined' color='secondary' onClick={onClose} fullWidth>
          {t('common:cancel')}
        </Button>
        <Button variant='contained' onClick={handleSaveFiles} fullWidth color='secondary' disabled={!file.length}>
          {t('common:upload')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default UploadDocument;
