import React, { useEffect, useRef, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from '../../../../../hooks/redux';
import useAccessControl from '../../../../../hooks/useAccessControl';
import { selectMachineDetails, selectServiceDocuments } from '../../../../../store/selectors/machine';
import {
  deleteServiceDocumentPending,
  downloadDocumentFile,
  fetchDocumentFilePending,
  fetchServiceDocumentsPending,
  resetActionFlagsPending,
  resetDocumentFilePending,
} from '../../../../../store/slices/machine';
import {
  Avatar,
  Box,
  Button,
  Link,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  styled,
} from '@mui/material';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import InsertDriveFileOutlinedIcon from '@mui/icons-material/InsertDriveFileOutlined';
import FileUploadOutlinedIcon from '@mui/icons-material/FileUploadOutlined';
import ContextMenu, { ContextButton } from '../../../../../components/ContextMenu';
import DeleteDialog from '../../../../../components/DeleteDialog';
import CustomSnackbar from '../../../../../components/Snackbar';
import Filter from './Filter';
import UploadDocument from './UploadDocument';
import { TServiceDocuments } from '../../../../../types';
import { formatDate, getUserById } from '../../../../../helpers';
import FileViewer from '../../../../../components/FileViewer';
import Loader from '../../../../../components/Loader';
import { selectUser, selectUsers } from '../../../../../store/selectors/user';

/* ------- Styles ------- */
const NoDocuments = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
  alignItems: 'center',
  verticalAlign: 'middle',
  height: '100%',

  '& svg': {
    fontSize: '74px',
    color: theme.palette.grey[80],
  },

  '& h6': {
    color: theme.palette.black[60],
    fontWeight: 700,
  },

  '& p': {
    color: theme.palette.black[60],
  },
}));

const ServiceDocumentsHeader = styled(Box)<{ justifyContent: string }>(({ justifyContent }) => ({
  display: 'flex',
  gap: '32px',
  justifyContent,
  margin: '0 0 16px 0',
}));

const User = styled(Box)(() => ({
  display: 'flex',
  gap: '8px',
  alignItems: 'center',
}));

const TableAvatar = styled(Avatar)(({ theme }) => ({
  display: 'inline-flex',
  width: '36px',
  height: '36px',
  fontSize: '12px',
  fontWeight: 500,
  backgroundColor: theme.palette.magenta[20],
  color: theme.palette.magenta[100],
}));

/* ------- Types ------- */
interface Column {
  id: 'machineId' | 'documentName' | 'uploadedByUser' | 'uploadedDate' | 'actions';
  label: string;
  minWidth?: number;
  align?: 'left' | 'right' | 'center';
  format?: (value: number | string) => string | JSX.Element;
}

export type TFilterValues = {
  uploadedDate: string;
};

interface Data {
  documentName: string;
  uploadedDate: string;
  uploadedByUser: string;
  actions: JSX.Element;
}

/* ------- Components ------- */
const Actions: React.FC<{ userIsCreator: boolean; onRemove: () => void; onDownload: () => void }> = ({
  userIsCreator,
  onRemove,
  onDownload,
}) => {
  const { userAccess } = useAccessControl();
  const { t } = useTranslation();
  const [contextOpen, setContextOpen] = useState<boolean>(false);
  const menuAnchorRef = useRef<HTMLButtonElement | null>(null);

  const handleRemove = () => {
    setContextOpen(false);
    onRemove();
  };

  const handleDownload = () => {
    setContextOpen(false);
    onDownload();
  };

  const getActionsForUser = () => {
    if (userAccess.delete_modify_documents_allowed || userIsCreator) {
      return [
        { name: t('lab:detailView:serviceDocuments:actions:remove'), callback: handleRemove },
        { name: t('lab:detailView:serviceDocuments:actions:download'), callback: handleDownload },
      ];
    }

    return [{ name: t('lab:detailView:serviceDocuments:actions:download'), callback: handleDownload }];
  };

  return (
    <Box>
      <ContextButton
        ref={menuAnchorRef}
        testId='tableActions'
        menuOpen={contextOpen}
        handleOpenMenu={() => setContextOpen(true)}
      >
        <MoreHorizIcon />
      </ContextButton>
      <ContextMenu
        menuOpen={contextOpen}
        anchorEl={menuAnchorRef.current}
        handleCloseMenu={() => setContextOpen(false)}
        contextActions={getActionsForUser()}
        hoverColor={(theme) => theme.palette.others.blue[20]}
      />
    </Box>
  );
};

const ServiceDocuments = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const user = useSelector(selectUser);
  const users = useSelector(selectUsers);
  const machineDetails = useSelector(selectMachineDetails);
  const serviceDocuments = useSelector(selectServiceDocuments);
  const [openUploadDocument, setOpenUploadDocument] = useState<boolean>(false);
  const [openPDFViewer, setOpenPDFViewer] = useState<string | null>();
  const [deleteDocument, setDeleteDocument] = useState<{
    dialogOpen: boolean;
    documentName: TServiceDocuments['documentName'];
  }>({ dialogOpen: false, documentName: '' });
  const [filterValues, setFilterValues] = useState<TFilterValues>({
    uploadedDate: 'all',
  });
  const dateFormat: Intl.DateTimeFormatOptions = {
    day: 'numeric',
    month: 'numeric',
    year: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
  };

  useEffect(() => {
    if (machineDetails) {
      dispatch(fetchServiceDocumentsPending({ machineId: machineDetails.id }));
    }
  }, [machineDetails, dispatch]);

  const handleFilePreview = (documentName: string) => {
    if (machineDetails && documentName) {
      dispatch(fetchDocumentFilePending({ machineId: machineDetails?.id, documentName }));
      setOpenPDFViewer(documentName);
    }
  };

  const handleFileDownload = (documentName: string) => {
    if (machineDetails && documentName) {
      dispatch(downloadDocumentFile({ machineId: machineDetails?.id, documentName }));
    }
  };

  const handleCloseFileViewer = () => {
    setOpenPDFViewer(null);
    dispatch(resetDocumentFilePending());
  };

  const handleSaveDeleteDocument = (documentName: TServiceDocuments['documentName']) => {
    setDeleteDocument({ dialogOpen: true, documentName });
  };

  const handleDeleteDocument = () => {
    if (deleteDocument && machineDetails) {
      setDeleteDocument({ ...deleteDocument, dialogOpen: false });
      dispatch(
        deleteServiceDocumentPending({ machineId: machineDetails?.id, documentName: deleteDocument.documentName }),
      );
    }
  };

  const handleSnackbarClose = () => {
    if (serviceDocuments.successfullySend) {
      dispatch(resetActionFlagsPending({ successfullySend: false }));
    }
    if (serviceDocuments.successfullyDeleted) {
      dispatch(resetActionFlagsPending({ successfullyDeleted: false }));
    }
  };

  const renderUser: (userId: string) => JSX.Element = (userId) => {
    if (!users)
      return (
        <User>
          <Typography component='span' variant='body1'>
            {t('user:unknownUser')}
          </Typography>
        </User>
      );

    const uploadingUser = getUserById(users, userId);
    return (
      <User>
        {uploadingUser ? (
          <>
            <TableAvatar>{uploadingUser.displayName.match(/\b(\w)/g)?.join('')}</TableAvatar>
            <Typography component='span' variant='body1'>
              {uploadingUser.displayName}
            </Typography>
          </>
        ) : (
          <Typography component='span' variant='body1'>
            {t('user:unknownUser')}
          </Typography>
        )}
      </User>
    );
  };

  const createData = (documentName, uploadedDate, uploadedByUser, actions): Data => {
    return { documentName, uploadedDate, uploadedByUser, actions };
  };

  const generateRows: (data: TServiceDocuments[]) => Data[] = (data) =>
    data.map(({ documentName, uploadedByUser, uploadedDate }) =>
      createData(
        documentName,
        uploadedDate,
        uploadedByUser,
        <Actions
          userIsCreator={uploadedByUser === user?.userId}
          onRemove={() => handleSaveDeleteDocument(documentName)}
          onDownload={() => handleFileDownload(documentName)}
        />,
      ),
    );

  const columns: readonly Column[] = [
    {
      id: 'documentName',
      label: t('lab:detailView:serviceDocuments:table:header:documentName'),
      align: 'left',
      minWidth: 170,
      format: (value) => (
        <Link
          component='button'
          onClick={() => handleFilePreview(value as string)}
          sx={{ fontWeight: 400, textDecoration: 'underline', cursor: 'pointer' }}
        >
          {value}
        </Link>
      ),
    },
    {
      id: 'uploadedDate',
      label: t('lab:detailView:serviceDocuments:table:header:uploadedDate'),
      align: 'left',
      minWidth: 100,
      format: (value) => formatDate(value as string, 'de', dateFormat),
    },
    {
      id: 'uploadedByUser',
      label: t('lab:detailView:serviceDocuments:table:header:uploadedByUser'),
      align: 'left',
      minWidth: 100,
      format: (value) => renderUser(value as string),
    },
    {
      id: 'actions',
      label: t('lab:detailView:serviceDocuments:table:header:actions'),
      minWidth: 80,
      align: 'right',
    },
  ];

  return serviceDocuments.loading ? (
    <Loader />
  ) : (
    <>
      <ServiceDocumentsHeader justifyContent={serviceDocuments.list?.length ? 'space-between' : 'flex-end'}>
        {serviceDocuments.list?.length ? (
          <Filter filterValues={filterValues} setFilterValues={setFilterValues} />
        ) : null}
        <Button
          variant='contained'
          color='info'
          startIcon={<FileUploadOutlinedIcon />}
          onClick={() => setOpenUploadDocument(true)}
        >
          {t('lab:detailView:serviceDocuments:upload:title')}
        </Button>
      </ServiceDocumentsHeader>
      {!serviceDocuments.list?.length ? (
        <NoDocuments>
          <InsertDriveFileOutlinedIcon />
          <Typography variant='h6' component='h6'>
            {t('lab:detailView:serviceDocuments:noDocuments:title')}
          </Typography>
          <Typography>{t('lab:detailView:serviceDocuments:noDocuments:text')}</Typography>
        </NoDocuments>
      ) : (
        <Paper sx={{ width: '100%', overflow: 'hidden' }}>
          <TableContainer sx={{ maxHeight: 440 }}>
            <Table stickyHeader aria-label='sticky table'>
              <TableHead>
                <TableRow>
                  {columns.map((column) => (
                    <TableCell
                      key={column.id}
                      align={column.align}
                      sx={{
                        textTransform: 'uppercase',
                        color: (theme) => theme.palette.grey[100],
                        minWidth: column.minWidth,
                      }}
                    >
                      {column.label}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {generateRows(serviceDocuments.list).map((row) => {
                  return (
                    <TableRow hover tabIndex={-1} key={row.documentName}>
                      {columns.map((column) => {
                        const value = row[column.id];
                        return (
                          <TableCell
                            key={column.id}
                            align={column.align}
                            sx={{
                              fontSize: '16px',
                              fontWeight: 400,
                              color: (theme) =>
                                column.id === 'documentName'
                                  ? theme.palette.others.blue[100]
                                  : theme.palette.black[100],
                            }}
                          >
                            {column.id === 'documentName' && (
                              <InsertDriveFileOutlinedIcon
                                sx={{
                                  verticalAlign: 'bottom',
                                  marginRight: '8px',
                                }}
                              />
                            )}
                            {column.format ? column.format(value) : value}
                          </TableCell>
                        );
                      })}
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
        </Paper>
      )}

      <UploadDocument open={openUploadDocument} onClose={() => setOpenUploadDocument(false)} />

      <FileViewer
        open={!!openPDFViewer}
        file={serviceDocuments.file as string}
        fileName={openPDFViewer as string}
        onClose={handleCloseFileViewer}
        closeLabel={t('common:close')}
      />

      <DeleteDialog
        open={deleteDocument.dialogOpen}
        onConfirm={handleDeleteDocument}
        onClose={() => setDeleteDocument({ ...deleteDocument, dialogOpen: false })}
        dialogTitle={t('lab:detailView:serviceDocuments:delete:title')}
        dialogText={
          <Trans
            i18nKey='lab:detailView:serviceDocuments:delete:text'
            values={{
              document: deleteDocument.documentName,
              lineBreak: '<br />',
            }}
            components={{ bold: <strong /> }}
          />
        }
      />

      <CustomSnackbar
        severity='success'
        open={serviceDocuments.successfullySend || serviceDocuments.successfullyDeleted}
        onClose={handleSnackbarClose}
        message={
          serviceDocuments.successfullySend ? (
            t('lab:detailView:serviceDocuments:upload:postSuccess')
          ) : (
            <Trans
              i18nKey='lab:detailView:serviceDocuments:delete:deleteSuccess'
              values={{ document: deleteDocument.documentName }}
              components={{ bold: <strong /> }}
            />
          )
        }
      />

      {serviceDocuments.sendOrDeletePending && (
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            width: '100%',
            height: '100%',
            position: 'absolute',
            top: 0,
            left: 0,
            backgroundColor: 'rgba(0, 0, 0, 0.5)',
            zIndex: 9999,
          }}
        >
          <Loader sx={{ backgroundColor: 'rgba(255, 255, 255, 0.2)' }} />
        </Box>
      )}
    </>
  );
};

export default ServiceDocuments;
