import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from '../../../../../../hooks/redux';
import { Box, Button, MenuItem, Select, Tooltip as MuiTooltip, Typography, styled } from '@mui/material';
import { selectMachineDetails, selectTelemetry } from '../../../../../../store/selectors/machine';
import StackedBarChartIcon from '@mui/icons-material/StackedBarChart';
import { ReactSVG } from 'react-svg';
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Legend, Tooltip } from 'chart.js';
import { Bar } from 'react-chartjs-2';
import LinePieDiagram from '../../../../../../components/LinePieDiagram';
import { createFilterTimeRange, formatDate } from '../../../../../../helpers';
import { TAvailabilityHistogram, TTimeFilterValue } from '../../../../../../types';
import {
  fetchAvailabilityGraphPending,
  fetchAvailabilityHistogramPending,
} from '../../../../../../store/slices/machine';
import theme from '../../../../../../theme';

/* ------- ChartJS ------- */
ChartJS.register(CategoryScale, LinearScale, BarElement, Legend, Tooltip);

/* ------- Styles ------- */
const Wrapper = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: '32px',
  padding: '24px',
  border: `1px solid ${theme.palette.grey[20]}`,
  minHeight: '344px',
  borderRadius: '4px',
}));

const AvailabilityBtn = styled(Button)<{ active: number }>(({ active, theme }) => ({
  padding: '8px',
  width: '40px',
  minWidth: '40px',
  height: '40px',
  color: active ? theme.palette.secondary.main : theme.palette.black[60],
  borderColor: active ? theme.palette.secondary.main : theme.palette.black[60],

  '&:hover': {
    color: theme.palette.secondary.main,
    borderColor: theme.palette.secondary.main,
    backgroundColor: 'transparent',
  },
}));

const NoDataLabel = styled(Typography)(({ theme }) => ({
  textAlign: 'center',
  fontWeight: 700,
  color: theme.palette.grey[60],
  height: '100%',
  alignContent: 'center',
}));

/* ------- Types ------- */
type THistogramData = {
  labels: string[];
  datasets: {
    label: string;
    data: number[];
    backgroundColor: string;
  }[];
};

/* ------- Components ------- */
const Availability: React.FC = () => {
  const {
    t,
    i18n: { language },
  } = useTranslation();
  const dispatch = useDispatch();
  const machineDetails = useSelector(selectMachineDetails);
  const machineTelemetry = useSelector(selectTelemetry);

  const [availabilityRangeOne, setAvailabilityRangeOne] = useState<TTimeFilterValue>('today');
  const [availabilityRangeTwo, setAvailabilityRangeTwo] = useState<TTimeFilterValue>('currentMonth');
  const [availabilityView, setAvailabilityView] = useState<'graph' | 'histogram'>('graph');

  const diagramColorMap = {
    Running: theme.palette.success.main,
    Manual: theme.palette.purple[100],
    Stopped: theme.palette.error.main,
    Off: theme.palette.grey[40],
    Unknown: theme.palette.grey[80],
  };

  /* ------- ChartJS ------- */
  const histogramOptions = {
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      x: {
        stacked: true,
      },
      y: {
        stacked: true,
      },
    },
    plugins: {
      legend: {
        position: 'bottom' as const,
        align: 'start' as const,
        labels: {
          padding: 16,
          boxWidth: 10,
          boxHeight: 10,
          font: {
            size: 14,
          },
        },
      },
      tooltip: {
        displayColors: false,
        callbacks: {
          title: (context) => {
            return t(`lab:detailView:overview:availability:histogramTooltipTitle:${context[0].dataset.label}`);
          },
          label: (context) => {
            const duration = context.formattedValue.split(',');
            if (duration.length > 1) {
              return `${duration[0]}h ${duration[1]}min`;
            }

            return `${duration[0]}h`;
          },
        },
      },
    },
  };

  const availabilityCompareOptions: { label: string; value: TTimeFilterValue }[] = [
    { label: t('lab:detailView:overview:availability:today'), value: 'today' },
    { label: t('lab:detailView:overview:availability:yesterday'), value: 'yesterday' },
    { label: t('lab:detailView:overview:availability:currentWeek'), value: 'currentWeek' },
    { label: t('lab:detailView:overview:availability:currentMonth'), value: 'currentMonth' },
    { label: t('lab:detailView:overview:availability:currentYear'), value: 'currentYear' },
  ];

  useEffect(() => {
    if (machineDetails?.id) {
      if (availabilityView === 'graph') {
        const rangeOneGraph = createFilterTimeRange(availabilityRangeOne);
        const rangeTwoGraph = createFilterTimeRange(availabilityRangeTwo);

        dispatch(
          fetchAvailabilityGraphPending({
            machineId: machineDetails.id,
            times: {
              compareGraphOne: {
                fromTime: rangeOneGraph.from,
                toTime: rangeOneGraph.to,
              },
              compareGraphTwo: {
                fromTime: rangeTwoGraph.from,
                toTime: rangeTwoGraph.to,
              },
            },
          }),
        );
      } else if (availabilityView === 'histogram') {
        const rangeHistogram = createFilterTimeRange(availabilityRangeTwo);

        dispatch(
          fetchAvailabilityHistogramPending({
            machineId: machineDetails.id,
            fromTime: rangeHistogram.from,
            toTime: rangeHistogram.to,
            binTimeSpan: '1.00:00:00',
          }),
        );
      }
    }
  }, [availabilityView, availabilityRangeOne, availabilityRangeTwo]); // eslint-disable-line react-hooks/exhaustive-deps

  const generateHistogramData = () => {
    const { availabilityHistogram } = machineTelemetry;
    const dateFormat: Intl.DateTimeFormatOptions = { weekday: 'short', day: 'numeric', month: 'short' };
    const data: THistogramData = { labels: [], datasets: [] };

    const findItemByTimestamp = (groupedData: TAvailabilityHistogram[], label: string) =>
      groupedData.find((item) => formatDate(item.sourceTimestamp, language, dateFormat) === label);

    const mapStatusToColor = (status: string) => {
      if (status === 'Off' || status === 'Stopped') {
        return 'error';
      }

      if (status === 'Running' || status === 'Manual') {
        return 'success';
      }

      return 'grey';
    };

    if (availabilityHistogram) {
      // create labels
      data.labels = availabilityHistogram.map((item) => formatDate(item.sourceTimestamp, language, dateFormat));

      // remove duplicates from labels
      data.labels = [...new Set(data.labels)];

      // group machineHistogram data by status
      const groupedData = availabilityHistogram.reduce(
        (acc, cur) => {
          if (!acc[cur.status]) {
            acc[cur.status] = [];
          }
          acc[cur.status].push(cur);
          return acc;
        },
        {} as Record<string, TAvailabilityHistogram[]>,
      );

      // create datasets
      Object.keys(groupedData).forEach((status) => {
        data.datasets.push({
          label: status,
          backgroundColor:
            theme.palette[mapStatusToColor(status)]?.main || theme.palette[mapStatusToColor(status)][100],
          data: data.labels.map((label) => {
            const item = findItemByTimestamp(groupedData[status], label);
            // use only hours from timeSpan
            if (item) return parseFloat(item.timeSpan.split(':').slice(0, 1).join(':'));

            return 0;
          }),
        });
      });
    }

    return data;
  };

  return (
    <Wrapper>
      <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
        <Typography variant='h6' sx={{ fontWeight: 700 }}>
          {t('lab:detailView:overview:availability:title')}
        </Typography>

        <Box sx={{ display: 'flex', gap: '16px', alignItems: 'center' }}>
          {availabilityView === 'graph' && (
            <>
              <Select
                size='small'
                value={availabilityRangeOne}
                onChange={(e) => setAvailabilityRangeOne(e.target.value as TTimeFilterValue)}
              >
                {availabilityCompareOptions.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </Select>

              <Typography variant='body2' sx={{ color: (theme) => theme.palette.black[60], display: 'inline' }}>
                {t('lab:detailView:overview:availability:compareLabel')}
              </Typography>
            </>
          )}
          <Select
            size='small'
            value={availabilityRangeTwo}
            onChange={(e) => setAvailabilityRangeTwo(e.target.value as TTimeFilterValue)}
          >
            {availabilityCompareOptions.map((option) => (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </Select>

          <Box sx={{ display: 'flex', gap: '12px' }}>
            <MuiTooltip title={t('lab:detailView:overview:availability:graphTooltip')} placement='top' arrow>
              <AvailabilityBtn
                variant='outlined'
                onClick={() => setAvailabilityView('graph')}
                active={availabilityView === 'graph' ? 1 : 0}
              >
                <ReactSVG
                  src='/images/icons/full_stacked_bar.svg'
                  style={{ display: 'inline-block', marginTop: '6px' }}
                  data-testid='FullStackedBarIcon'
                />
              </AvailabilityBtn>
            </MuiTooltip>
            <MuiTooltip title={t('lab:detailView:overview:availability:histogramTooltip')} placement='top' arrow>
              <AvailabilityBtn
                variant='outlined'
                onClick={() => setAvailabilityView('histogram')}
                active={availabilityView === 'histogram' ? 1 : 0}
              >
                <StackedBarChartIcon />
              </AvailabilityBtn>
            </MuiTooltip>
          </Box>
        </Box>
      </Box>

      {availabilityView === 'graph' &&
        machineTelemetry.availabilityGraph.compareGraphOne &&
        machineTelemetry.availabilityGraph.compareGraphTwo && (
          <>
            <LinePieDiagram
              sections={machineTelemetry.availabilityGraph.compareGraphOne}
              title={t(`lab:detailView:overview:availability:${availabilityRangeOne}`)}
              noDataLabel={t('lab:detailView:overview:availability:noData')}
              showProgressLabel
              colorMap={diagramColorMap}
            />
            <LinePieDiagram
              sections={machineTelemetry.availabilityGraph.compareGraphTwo}
              title={t(`lab:detailView:overview:availability:${availabilityRangeTwo}`)}
              noDataLabel={t('lab:detailView:overview:availability:noData')}
              showProgressLabel
              showLegend
              colorMap={diagramColorMap}
            />
          </>
        )}

      {availabilityView === 'histogram' && (
        <>
          {machineTelemetry.availabilityHistogram?.length ? (
            <Box sx={{ height: '225px' }} data-testid='availabilityHistogram'>
              <Bar options={histogramOptions} data={generateHistogramData()} />
            </Box>
          ) : (
            <NoDataLabel variant='body1' sx={{ fontSize: '20px' }}>
              {t('lab:detailView:overview:availability:noData')}
            </NoDataLabel>
          )}
        </>
      )}
    </Wrapper>
  );
};

export default Availability;
