import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, FormControl, MenuItem, Select, SelectChangeEvent, Typography, styled } from '@mui/material';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import DateRangePicker from '../../../components/DateRangePicker';
import { DateRange } from '@mui/x-date-pickers-pro/models';
import dayjs from 'dayjs';
import { formatDate } from '../../../helpers';
import { useDispatch, useSelector } from '../../../hooks/redux';
import { selectMachines } from '../../../store/selectors/machine';
import { fetchMachinesPending } from '../../../store/slices/machine';
import { ISSUE_STATUS, TICKET_STATUS, NOTIFICATION_TYPE, TTimeFilterValue } from '../../../types';

/* ------- Styles ------- */
const SelectLabel = styled(Typography)(({ theme }) => ({
  display: 'inline',
  color: theme.palette.black[60],
  marginRight: '4px',
}));

/* ------- Types ------- */
export type TFilterValues = {
  machine?: string | 'all';
  notificationType: NOTIFICATION_TYPE | 'all';
  started: 'all' | TTimeFilterValue;
  issueStatus: ISSUE_STATUS | 'all';
  ticketStatus: TICKET_STATUS | 'all';
};

interface IFilterProps {
  filterValues: TFilterValues;
  setFilterValues: (filterValues: TFilterValues) => void;
  machinesFilterActive?: boolean;
}

/* ------- Components ------- */
const Filter: React.FC<IFilterProps> = ({ filterValues, setFilterValues, machinesFilterActive = false }) => {
  const {
    t,
    i18n: { language },
  } = useTranslation();
  const dispatch = useDispatch();
  const machines = useSelector(selectMachines);
  const [customRangeOpen, setCustomRangeOpen] = useState(false);
  const dateFormat: Intl.DateTimeFormatOptions = { day: '2-digit', month: '2-digit', year: 'numeric' };

  useEffect(() => {
    if (machinesFilterActive && !machines) {
      dispatch(fetchMachinesPending());
    }
  }, [machinesFilterActive, machines, dispatch]);

  const handleFilterChange = (event: SelectChangeEvent<string>) => {
    if (event.target.name === 'started' && event.target.value === 'customRange') {
      setCustomRangeOpen(true);
    } else {
      setFilterValues({ ...filterValues, [event.target.name]: event.target.value });
    }
  };

  const applyCustomRange = (range: DateRange<dayjs.Dayjs>) => {
    const rangeString = `${range[0]?.toISOString()},${range[1]?.toISOString()}`;
    setFilterValues({ ...filterValues, started: rangeString });
    setCustomRangeOpen(false);
  };

  return (
    <>
      <Box display='flex' gap='16px'>
        {machinesFilterActive && (
          <FormControl size='small'>
            <Select
              color='secondary'
              labelId='machine'
              name='machine'
              value={filterValues.machine}
              renderValue={(value) => (
                <>
                  <SelectLabel>{t('notifications:filter:labels:machines')}</SelectLabel>
                  {value === 'all'
                    ? t('notifications:filter:values:all')
                    : machines?.filter((machine) => machine.serialNumber === value)[0]?.name}
                </>
              )}
              onChange={handleFilterChange}
              IconComponent={KeyboardArrowDownIcon}
            >
              <MenuItem value='all'>{t('notifications:filter:values:all')}</MenuItem>
              {machines?.map((machine) => (
                <MenuItem key={machine.id} value={machine.serialNumber}>
                  {machine.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        )}

        <FormControl size='small'>
          <Select
            color='secondary'
            labelId='notificationType'
            name='notificationType'
            value={filterValues.notificationType}
            renderValue={(value) => (
              <>
                <SelectLabel>{t('notifications:filter:labels:notificationType')}</SelectLabel>
                {t(`notifications:filter:values:${value.toLowerCase()}`)}
              </>
            )}
            onChange={handleFilterChange}
            IconComponent={KeyboardArrowDownIcon}
          >
            <MenuItem color='secondary' value='all'>
              {t('notifications:filter:values:all')}
            </MenuItem>
            <MenuItem value='ERROR'>{t('notifications:filter:values:error')}</MenuItem>
            <MenuItem value='WARNING'>{t('notifications:filter:values:warning')}</MenuItem>
          </Select>
        </FormControl>

        <FormControl size='small'>
          <Select
            color='secondary'
            labelId='started'
            name='started'
            value={filterValues.started}
            renderValue={(value) => (
              <>
                <SelectLabel>{t('notifications:filter:labels:started')}</SelectLabel>
                {dayjs(value.split(',')[0]).isValid()
                  ? `${formatDate(value.split(',')[0], language, dateFormat)} - ${formatDate(value.split(',')[1], language, dateFormat)}`
                  : t(`notifications:filter:values:${value}`)}
              </>
            )}
            onChange={handleFilterChange}
            IconComponent={KeyboardArrowDownIcon}
          >
            <MenuItem value='all'>{t('notifications:filter:values:all')}</MenuItem>
            <MenuItem value='today'>{t('notifications:filter:values:today')}</MenuItem>
            <MenuItem value='yesterday'>{t('notifications:filter:values:yesterday')}</MenuItem>
            <MenuItem value='currentWeek'>{t('notifications:filter:values:currentWeek')}</MenuItem>
            <MenuItem value='currentMonth'>{t('notifications:filter:values:currentMonth')}</MenuItem>
            <MenuItem value='currentYear'>{t('notifications:filter:values:currentYear')}</MenuItem>
            <MenuItem value='customRange' sx={{ borderTop: (theme) => `1px solid ${theme.palette.grey[40]}` }}>
              {t('notifications:filter:values:customRange')}
            </MenuItem>
          </Select>
        </FormControl>

        <FormControl size='small'>
          <Select
            color='secondary'
            labelId='issueStatus'
            name='issueStatus'
            value={filterValues.issueStatus}
            renderValue={(value) => (
              <>
                <SelectLabel>{t('notifications:filter:labels:issueStatus')}</SelectLabel>
                {t(`notifications:filter:values:${value.toLowerCase()}`)}
              </>
            )}
            onChange={handleFilterChange}
            IconComponent={KeyboardArrowDownIcon}
          >
            <MenuItem value='all'>{t('notifications:filter:values:all')}</MenuItem>
            <MenuItem value='ACTIVE'>{t('notifications:filter:values:active')}</MenuItem>
            <MenuItem value='INACTIVE'>{t('notifications:filter:values:inactive')}</MenuItem>
          </Select>
        </FormControl>

        <FormControl size='small'>
          <Select
            color='secondary'
            labelId='ticketStatus'
            name='ticketStatus'
            value={filterValues.ticketStatus}
            renderValue={(value) => (
              <>
                <SelectLabel>{t('notifications:filter:labels:ticketStatus')}</SelectLabel>
                {t(`notifications:filter:values:${value.toLowerCase()}`)}
              </>
            )}
            onChange={handleFilterChange}
            IconComponent={KeyboardArrowDownIcon}
          >
            <MenuItem value='all'>{t('notifications:filter:values:all')}</MenuItem>
            <MenuItem value='NEW'>{t('notifications:filter:values:new')}</MenuItem>
            <MenuItem value='PROGRESS'>{t('notifications:filter:values:progress')}</MenuItem>
            <MenuItem value='RESOLVED'>{t('notifications:filter:values:resolved')}</MenuItem>
          </Select>
        </FormControl>
      </Box>

      <DateRangePicker
        open={customRangeOpen}
        onClose={() => setCustomRangeOpen(false)}
        onApply={(value) => applyCustomRange(value)}
      />
    </>
  );
};

export default Filter;
