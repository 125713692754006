import React from 'react';
import { Box, Typography, Button, styled } from '@mui/material';
import InsertDriveFileOutlinedIcon from '@mui/icons-material/InsertDriveFileOutlined';
import ZoomInIcon from '@mui/icons-material/ZoomIn';
import ZoomOutIcon from '@mui/icons-material/ZoomOut';

/*------- Styles -------*/
const Header = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  padding: '16px 32px',
  color: '#ffffff',
  backgroundColor: theme.palette.black[100],
}));

const HeaderArea = styled(Box)(() => ({
  display: 'flex',
  alignItems: 'center',
  gap: '16px',
}));

const CloseButton = styled(Button)(({ theme }) => ({
  textTransform: 'capitalize',
  marginLeft: '16px',
  color: theme.palette.white.main,
  border: `1px solid ${theme.palette.white.main}`,

  '&:hover': {
    background: 'rgba(255, 255, 255, 0.2)',
    color: theme.palette.white.main,
    border: `1px solid ${theme.palette.white.main}`,
  },
}));

/*------- Types -------*/
interface IFileHeaderProps {
  fileName?: string;
  scale: number;
  setScale: (scale: number) => void;
  closeLabel: string | React.ReactNode;
  onClose: () => void;
}

/*------- Components -------*/
const FileHeader: React.FC<IFileHeaderProps> = ({ fileName, scale, setScale, closeLabel, onClose }) => {
  const isMinZoom = scale < 0.6;
  const isMaxZoom = scale >= 2.0;

  const zoomOut = () => {
    if (!isMinZoom) setScale(scale - 0.1);
  };

  const zoomIn = () => {
    if (!isMaxZoom) setScale(scale + 0.1);
  };

  return (
    <Header>
      <HeaderArea>
        <InsertDriveFileOutlinedIcon />
        {fileName && <Typography>{fileName}</Typography>}
      </HeaderArea>
      <HeaderArea>
        <ZoomInIcon
          onClick={zoomIn}
          sx={{
            color: isMaxZoom ? (theme) => theme.palette.grey[100] : (theme) => theme.palette.white.main,
            cursor: 'pointer',
          }}
        />
        <ZoomOutIcon
          onClick={zoomOut}
          sx={{
            color: isMinZoom ? (theme) => theme.palette.grey[100] : (theme) => theme.palette.white.main,
            cursor: 'pointer',
          }}
        />
        <CloseButton variant='outlined' onClick={onClose}>
          {closeLabel}
        </CloseButton>
      </HeaderArea>
    </Header>
  );
};

export default FileHeader;
