import React from 'react';
import theme from '../../theme';
import { Chip, Tooltip, styled } from '@mui/material';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';

/* ------- Styles ------- */
const LabelWithIcon = styled('span')({
  display: 'flex',
  alignItems: 'center',
  gap: '4px',
  fontWeight: 500,
});

const Label = styled('span')({
  fontWeight: 500,
});

/* ------- Types ------- */
interface IStatusBadgeProps {
  label: string | number;
  status: 'error' | 'warning' | 'running' | 'stopped' | 'waiting' | 'manual' | 'off' | 'unknown';
  tooltip?: string;
  onClick?: () => void;
}

const StatusBadge: React.FC<IStatusBadgeProps> = ({ label, tooltip, status, onClick }) => {
  const getColor = () => {
    switch (status) {
      case 'error':
        return theme.palette.error.main;
      case 'warning':
        return theme.palette.warning.main;
      case 'running':
      case 'manual':
        return theme.palette.success.main;
      case 'stopped':
        return theme.palette.black[100];
      case 'waiting':
        return theme.palette.purple[100];
      case 'off':
        return theme.palette.grey[20];
      case 'unknown':
        return theme.palette.grey[100];
    }
  };

  const getTextColor = () => {
    switch (status) {
      case 'warning':
        return theme.palette.black[100];
      case 'off':
        return theme.palette.black[60];
      default:
        return theme.palette.white.main;
    }
  };

  const getLabel = () => {
    switch (status) {
      case 'error':
        return (
          <LabelWithIcon>
            <ErrorOutlineIcon />
            {label}
          </LabelWithIcon>
        );
      case 'warning':
        return (
          <LabelWithIcon>
            <WarningAmberIcon />
            {label}
          </LabelWithIcon>
        );
      default:
        return <Label>{label}</Label>;
    }
  };

  return (
    <Tooltip key={1} title={tooltip} placement='top' arrow>
      <Chip
        label={getLabel()}
        sx={{
          cursor: onClick ? 'pointer' : 'auto',
          backgroundColor: getColor(),
          color: getTextColor(),
          '&:hover': {
            backgroundColor: getColor(),
          },
        }}
        onClick={onClick}
      />
    </Tooltip>
  );
};

export default StatusBadge;
