import { call, put, takeLatest } from 'redux-saga/effects';
import apiClient from '../services/apiClient';
import {
  fetchNotificationsPending,
  fetchNotificationsSuccess,
  actionFailed,
  TPayloadNotifications,
} from '../store/slices/notifications';

function* fetchNotificationsSaga({ payload }: { payload: TPayloadNotifications }) {
  try {
    const { data } = yield call(apiClient.post, `/machines/notifications`, payload, {
      useOptoUrl: true,
    });

    yield put(fetchNotificationsSuccess({ list: data.data, total: data.totalCount }));
  } catch (error) {
    yield put(actionFailed(error));
  }
}

export default function* watch() {
  yield takeLatest(fetchNotificationsPending, fetchNotificationsSaga);
}
