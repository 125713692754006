import React from 'react';
import Layout from '../../components/Layout';
import { Typography } from '@mui/material';

/* ------- Components ------- */
const Settings = () => {
  return (
    <Layout>
      <Typography variant='h5'>Settings</Typography>
    </Layout>
  );
};

export default Settings;
