import { AxiosRequestConfig } from 'axios';
import Axios from './apiClient.config';
import { REACT_APP_ONEIOT_API_DOMAIN, REACT_APP_OPTOTECH_API_DOMAIN } from '../../config';

type TRequestParams = {
  useOptoUrl?: boolean;
} & AxiosRequestConfig;

const apiClient = {
  get(url: string, params?: TRequestParams): Promise<object> {
    return Axios.get(url, {
      baseURL: params?.useOptoUrl ? REACT_APP_OPTOTECH_API_DOMAIN : REACT_APP_ONEIOT_API_DOMAIN,
      ...params,
    }).then((response) => {
      if (response?.status >= 200 && response.status < 300) {
        return response.data;
      }
      return response;
    });
  },

  post(url: string, data?: object, params?: TRequestParams): Promise<object> {
    return Axios.post(url, data, {
      baseURL: params?.useOptoUrl ? REACT_APP_OPTOTECH_API_DOMAIN : REACT_APP_ONEIOT_API_DOMAIN,
      ...params,
    }).then((response) => {
      if (response?.status >= 200 && response.status < 300) {
        return response.data;
      }
      return response;
    });
  },

  put(url: string, data?: object, params?: TRequestParams): Promise<object> {
    return Axios.put(url, data, {
      baseURL: params?.useOptoUrl ? REACT_APP_OPTOTECH_API_DOMAIN : REACT_APP_ONEIOT_API_DOMAIN,
      ...params,
    }).then((response) => {
      if (response?.status >= 200 && response.status < 300) {
        return response.data;
      }
      return response;
    });
  },

  delete(url: string, id?: string, params?: TRequestParams): Promise<object> {
    return Axios.delete(id ? `${url}/${id}` : url, {
      baseURL: params?.useOptoUrl ? REACT_APP_OPTOTECH_API_DOMAIN : REACT_APP_ONEIOT_API_DOMAIN,
      ...params,
    }).then((response) => {
      if (response?.status >= 200 && response.status < 300) {
        return response.data;
      }
      return response;
    });
  },
};

export default apiClient;
