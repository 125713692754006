import { createSlice } from '@reduxjs/toolkit';
import { ApolloError } from '@apollo/client/errors';
import {
  ISSUE_STATUS,
  NOTIFICATION_TYPE,
  TAvailabilityGraph,
  TAvailabilityHistogram,
  TKnowledgeFile,
  TMachine,
  TNotification,
  TServiceDocuments,
  TSpindleData,
  TTelemetryData,
} from '../../types';

export type TPayloadMachineData = {
  machineId: string;
};

export type TPayloadMachineName = {
  machineId: string;
  name: string;
};

export type TPayloadTelemetryGraph = {
  machineId: string;
  times: {
    compareGraphOne: {
      fromTime: string;
      toTime: string;
    };
    compareGraphTwo: {
      fromTime: string;
      toTime: string;
    };
  };
};

export type TPayloadTelemetryHistogram = {
  machineId: string;
  fromTime: string;
  toTime: string;
  binTimeSpan: string;
};

export type TPayloadNotifications = {
  serialNumber: string;
  body?: {
    page?: {
      pageNumber: number;
      pageSize: number;
    };
    timeRange?: {
      from: string;
      to: string;
    };
    issueStatus?: ISSUE_STATUS;
    notificationType?: NOTIFICATION_TYPE;
  };
};

export type TPayloadNewServiceDocument = {
  machineId: string;
  file: FormData;
};

export type TPayloadServiceDocument = {
  machineId: string;
  documentName: string;
};

export type TPayloadKnowledgeFiles = {
  machineType: string;
  machineVersion: string;
};

export type TPayloadKnowledgeFile = {
  machineType: string;
  machineVersion: string;
  documentName: string;
};

type TActionFlags = {
  successfullySend?: boolean;
  successfullyDeleted?: boolean;
};

type TPayloadError = {
  error: ApolloError;
};

export interface IMachine {
  machines: TMachine[] | null;
  machineDetails: {
    machine: TMachine | null;
    telemetry: {
      telemetryData: TTelemetryData | null;
      spindleData: TSpindleData[] | null;
      availabilityGraph: {
        compareGraphOne: TAvailabilityGraph[] | null;
        compareGraphTwo: TAvailabilityGraph[] | null;
      };
      availabilityHistogram: TAvailabilityHistogram[] | null;
    };
    notifications: {
      list: TNotification[] | null;
      total: number;
      loading: boolean;
    };
    serviceDocuments: {
      list: TServiceDocuments[] | null;
      file: string | null;
      sendOrDeletePending: boolean;
      successfullySend: boolean;
      successfullyDeleted: boolean;
      loading: boolean;
    };
    knowledgeFiles: {
      list: TKnowledgeFile[] | null;
      loading: boolean;
    };
  };
  loading: boolean;
  error: ApolloError | null;
}

/*-----------------INITIAL STATE-----------------*/
export const initialState: IMachine = {
  machines: null,
  machineDetails: {
    machine: null,
    telemetry: {
      telemetryData: null,
      spindleData: null,
      availabilityGraph: {
        compareGraphOne: null,
        compareGraphTwo: null,
      },
      availabilityHistogram: null,
    },
    notifications: {
      list: null,
      total: 0,
      loading: false,
    },
    serviceDocuments: {
      list: null,
      file: null,
      sendOrDeletePending: false,
      successfullySend: false,
      successfullyDeleted: false,
      loading: false,
    },
    knowledgeFiles: {
      list: null,
      loading: false,
    },
  },
  loading: false,
  error: null,
};

/*-----------------SLICE-----------------*/
const machineSlice = createSlice({
  name: 'machine',
  initialState,
  reducers: {
    /*-----------------Pending Actions/Reducers-----------------*/
    fetchMachinesPending: (state) => ({ ...state, loading: state.machines === null }),
    fetchMachineDataPending: (
      state,
      action: {
        payload: TPayloadMachineData;
      },
    ) => ({
      ...state,
      loading: state.machineDetails.machine?.id !== action.payload.machineId,
    }),
    setMachineNamePending: (
      state,
      _action: {
        payload: TPayloadMachineName;
      },
    ) => state,
    fetchTelemetryPending: (
      state,
      _action: {
        payload: TPayloadMachineData;
      },
    ) => state,
    fetchAvailabilityGraphPending: (
      state,
      _action: {
        payload: TPayloadTelemetryGraph;
      },
    ) => state,
    fetchAvailabilityHistogramPending: (
      state,
      _action: {
        payload: TPayloadTelemetryHistogram;
      },
    ) => state,
    fetchMachineNotificationsPending: (
      state,
      _action: {
        payload: TPayloadNotifications;
      },
    ) => ({
      ...state,
      machineDetails: {
        ...state.machineDetails,
        notifications: {
          ...state.machineDetails.notifications,
          loading: true,
        },
      },
    }),
    fetchServiceDocumentsPending: (
      state,
      _action: {
        payload: TPayloadMachineData;
      },
    ) => ({
      ...state,
      machineDetails: {
        ...state.machineDetails,
        serviceDocuments: {
          ...state.machineDetails.serviceDocuments,
          loading: true,
        },
      },
    }),
    fetchDocumentFilePending: (
      state,
      _action: {
        payload: TPayloadServiceDocument;
      },
    ) => state,
    downloadDocumentFile: (
      state,
      _action: {
        payload: TPayloadServiceDocument;
      },
    ) => state,
    resetDocumentFilePending: (state) => {
      return {
        ...state,
        machineDetails: {
          ...state.machineDetails,
          serviceDocuments: {
            ...state.machineDetails.serviceDocuments,
            file: null,
          },
        },
      };
    },
    sendServiceDocumentPending: (
      state,
      _action: {
        payload: TPayloadNewServiceDocument;
      },
    ) => ({
      ...state,
      machineDetails: {
        ...state.machineDetails,
        serviceDocuments: { ...state.machineDetails.serviceDocuments, sendOrDeletePending: true },
      },
    }),
    deleteServiceDocumentPending: (
      state,
      _action: {
        payload: TPayloadServiceDocument;
      },
    ) => ({
      ...state,
      machineDetails: {
        ...state.machineDetails,
        serviceDocuments: { ...state.machineDetails.serviceDocuments, sendOrDeletePending: true },
      },
    }),
    fetchKnowledgeFilesPending: (
      state,
      _action: {
        payload: TPayloadKnowledgeFiles;
      },
    ) => ({
      ...state,
      machineDetails: {
        ...state.machineDetails,
        knowledgeFiles: { ...state.machineDetails.knowledgeFiles, loading: true },
      },
    }),
    downloadKnowledgeFilePending: (
      state,
      _action: {
        payload: TPayloadKnowledgeFile;
      },
    ) => state,
    resetActionFlagsPending: (state, action: { payload: TActionFlags }) => {
      const { payload } = action;
      return {
        ...state,
        machineDetails: {
          ...state.machineDetails,
          serviceDocuments: {
            ...state.machineDetails.serviceDocuments,
            ...payload,
          },
        },
      };
    },

    /*-----------------Success Actions/Reducers-----------------*/
    fetchMachinesSuccess: (state, { payload }: { payload: TMachine[] }) => {
      return {
        ...state,
        machines: payload,
        loading: false,
      };
    },
    fetchMachineDataSuccess: (state, { payload }: { payload: TMachine }) => {
      return {
        ...state,
        machineDetails: {
          ...state.machineDetails,
          machine: payload,
        },
        loading: false,
      };
    },
    setMachineNameSuccess: (state, { payload }: { payload: TMachine }) => {
      return {
        ...state,
        machineDetails: {
          ...state.machineDetails,
          machine: payload,
        },
      };
    },
    fetchTelemetrySuccess: (
      state,
      { payload }: { payload: { telemetryData: TTelemetryData; spindleData: TSpindleData[] } },
    ) => {
      return {
        ...state,
        machineDetails: {
          ...state.machineDetails,
          telemetry: {
            ...state.machineDetails.telemetry,
            telemetryData: payload.telemetryData,
            spindleData: payload.spindleData,
          },
        },
      };
    },
    fetchAvailabilityGraphSuccess: (
      state,
      { payload }: { payload: { compareGraphOne: TAvailabilityGraph[]; compareGraphTwo: TAvailabilityGraph[] } },
    ) => {
      return {
        ...state,
        machineDetails: {
          ...state.machineDetails,
          telemetry: {
            ...state.machineDetails.telemetry,
            availabilityGraph: {
              ...state.machineDetails.telemetry.availabilityGraph,
              compareGraphOne: payload.compareGraphOne,
              compareGraphTwo: payload.compareGraphTwo,
            },
          },
        },
      };
    },
    fetchAvailabilityHistogramSuccess: (state, { payload }: { payload: TAvailabilityHistogram[] }) => {
      return {
        ...state,
        machineDetails: {
          ...state.machineDetails,
          telemetry: {
            ...state.machineDetails.telemetry,
            availabilityHistogram: payload,
          },
        },
      };
    },
    fetchMachineNotificationsSuccess: (state, { payload }: { payload: { list: TNotification[]; total: number } }) => {
      return {
        ...state,
        machineDetails: {
          ...state.machineDetails,
          notifications: {
            list: payload.list,
            total: payload.total,
            loading: false,
          },
        },
      };
    },
    fetchServiceDocumentsSuccess: (state, { payload }: { payload: TServiceDocuments[] }) => {
      return {
        ...state,
        machineDetails: {
          ...state.machineDetails,
          serviceDocuments: {
            ...state.machineDetails.serviceDocuments,
            list: payload,
            loading: false,
          },
        },
      };
    },
    fetchDocumentFileSuccess: (state, { payload }: { payload: string }) => {
      return {
        ...state,
        machineDetails: {
          ...state.machineDetails,
          serviceDocuments: {
            ...state.machineDetails.serviceDocuments,
            file: payload,
          },
        },
      };
    },
    sendServiceDocumentSuccess: (state, { payload }: { payload: { success: boolean } }) => {
      return {
        ...state,
        machineDetails: {
          ...state.machineDetails,
          serviceDocuments: {
            ...state.machineDetails.serviceDocuments,
            sendOrDeletePending: false,
            successfullySend: payload.success,
          },
        },
      };
    },
    deleteServiceDocumentSuccess: (state, { payload }: { payload: { success: boolean } }) => {
      return {
        ...state,
        machineDetails: {
          ...state.machineDetails,
          serviceDocuments: {
            ...state.machineDetails.serviceDocuments,
            sendOrDeletePending: false,
            successfullyDeleted: payload.success,
          },
        },
      };
    },
    fetchKnowledgeFilesSuccess: (state, { payload }: { payload: TKnowledgeFile[] }) => {
      return {
        ...state,
        machineDetails: {
          ...state.machineDetails,
          knowledgeFiles: {
            list: payload,
            loading: false,
          },
        },
      };
    },

    /*-----------------Failed Actions/Reducers-----------------*/
    actionFailed: (state, { payload }: { payload: TPayloadError }) => {
      return {
        ...state,
        machineDetails: {
          ...state.machineDetails,
          notifications: {
            ...state.machineDetails.notifications,
            loading: false,
          },
          serviceDocuments: {
            ...state.machineDetails.serviceDocuments,
            loading: false,
          },
          knowledgeFiles: {
            ...state.machineDetails.knowledgeFiles,
            loading: false,
          },
        },
        loading: false,
        error: payload.error,
      };
    },
    sendServiceDocumentFailed: (state, { payload }: { payload: TPayloadError }) => {
      return {
        ...state,
        machineDetails: {
          ...state.machineDetails,
          serviceDocuments: {
            ...state.machineDetails.serviceDocuments,
            sendOrDeletePending: false,
            successfullySend: false,
          },
        },
        error: payload.error,
      };
    },
    deleteServiceDocumentFailed: (state, { payload }: { payload: TPayloadError }) => {
      return {
        ...state,
        machineDetails: {
          ...state.machineDetails,
          serviceDocuments: {
            ...state.machineDetails.serviceDocuments,
            sendOrDeletePending: false,
            successfullyDeleted: false,
          },
        },
        error: payload.error,
      };
    },
  },
});

export const {
  fetchMachinesPending,
  fetchMachinesSuccess,
  fetchMachineDataPending,
  fetchMachineDataSuccess,
  setMachineNamePending,
  setMachineNameSuccess,
  fetchTelemetryPending,
  fetchTelemetrySuccess,
  fetchAvailabilityGraphPending,
  fetchAvailabilityGraphSuccess,
  fetchAvailabilityHistogramPending,
  fetchAvailabilityHistogramSuccess,
  fetchMachineNotificationsPending,
  fetchMachineNotificationsSuccess,
  fetchServiceDocumentsPending,
  fetchServiceDocumentsSuccess,
  fetchDocumentFilePending,
  fetchDocumentFileSuccess,
  downloadDocumentFile,
  sendServiceDocumentPending,
  sendServiceDocumentSuccess,
  sendServiceDocumentFailed,
  deleteServiceDocumentPending,
  deleteServiceDocumentSuccess,
  deleteServiceDocumentFailed,
  resetDocumentFilePending,
  fetchKnowledgeFilesPending,
  fetchKnowledgeFilesSuccess,
  downloadKnowledgeFilePending,
  resetActionFlagsPending,
  actionFailed,
} = machineSlice.actions; // eslint-disable-line

export default machineSlice.reducer;
