import React, { useEffect, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { useTranslation } from 'react-i18next';
import { Avatar, Box, Typography, styled } from '@mui/material';
import MuiAccordion, { AccordionProps } from '@mui/material/Accordion';
import MuiAccordionSummary, { AccordionSummaryProps } from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import { TTicket } from '../../types';
import { isEqual, capitalize } from 'lodash';
import { formatDate, splitCamelCase } from '../../helpers';

/* ------- Styles ------- */
const Accordion = styled((props: AccordionProps) => <MuiAccordion disableGutters elevation={0} square {...props} />)(
  ({ theme }) => ({
    borderTop: `1px solid ${theme.palette.grey[10]}`,
    borderBottom: `1px solid ${theme.palette.grey[10]}`,
    '&:not(:last-child)': {
      borderBottom: 0,
    },
    '&::before': {
      display: 'none',
    },
  }),
);

const AccordionSummary = styled((props: AccordionSummaryProps) => (
  <MuiAccordionSummary expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: '0.9rem' }} />} {...props} />
))(({ theme }) => ({
  backgroundColor: 'transparent',
  flexDirection: 'row-reverse',
  '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
    transform: 'rotate(90deg)',
  },
  '& .MuiAccordionSummary-content': {
    display: 'flex',
    justifyContent: 'space-between',
    gap: '32px',
    alignContent: 'center',
    marginLeft: theme.spacing(1),
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
  borderTop: `1px solid ${theme.palette.grey[10]}`,
}));

const Change = styled(Typography)<{ version: 'new' | 'old' }>(({ version, theme }) => ({
  display: 'inline-block',
  borderRadius: '4px',
  padding: '8px 12px',
  fontSize: '14px',
  fontWeight: 400,
  backgroundColor: version === 'new' ? theme.palette.others.green[10] : theme.palette.grey[10],
  textDecoration: version === 'old' ? 'line-through' : 'none',
}));

const CustomAvatar = styled(Avatar)<{ color: string; backgroundcolor: string }>(({ color, backgroundcolor }) => ({
  display: 'inline-flex',
  width: '28px',
  height: '28px',
  fontSize: '10px',
  fontWeight: 500,
  marginRight: '8px',
  color,
  backgroundColor: backgroundcolor,
}));

/* ------- Types ------- */
type TComparison = {
  title: string;
  new: string;
  old: string;
};

type THistory = {
  title: string | JSX.Element;
  date: string;
  changes: TComparison[];
};

interface IChangeHistoryProps {
  history: TTicket[] | null;
  ignoreAttributes?: string[];
};

/* ------- Components ------- */
const ChangeHistory: React.FC<IChangeHistoryProps> = ({ history, ignoreAttributes = [] }) => {
  const {
    i18n: { language },
  } = useTranslation();
  const [expanded, setExpanded] = useState<string | false>('panel1');
  const [changes, setChanges] = useState<THistory[]>([]);
  const dateFormat: Intl.DateTimeFormatOptions = {
    day: 'numeric',
    month: 'numeric',
    year: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
  };

  const handleChange = (panel: string) => (_event: React.SyntheticEvent, newExpanded: boolean) => {
    setExpanded(newExpanded ? panel : false);
  };

  const compareObjects = (objects: TTicket[]) => {
    if (objects.length < 2) {
      return [];
    }

    const differences: THistory[] = [];

    for (let i = 0; i < objects.length - 1; i++) {
      const obj1 = objects[i];
      const obj2 = objects[i + 1];
      const keys = new Set([...Object.keys(obj1), ...Object.keys(obj2)]);

      const diff: THistory = {
        title: `${objects[i].updatedByUser.displayName} changed`,
        date: formatDate(objects[i].updatedAt, language, dateFormat),
        changes: [],
      };

      keys.forEach((key) => {
        if (!ignoreAttributes.includes(key)) {
          const value1 = obj1[key];
          const value2 = obj2[key];
          if (!isEqual(value1, value2)) {
            diff.changes.push({ title: key, new: value2, old: value1 });
          }
        }
      });

      if (diff.changes.length) {
        if (diff.changes.length < 2) {
          diff.title = (
            <div>
              <CustomAvatar color='#866815' backgroundcolor='rgba(134, 104, 21, 0.1)'>
                {`${objects[i].updatedByUser.displayName.split(' ')[0].charAt(0)}${objects[i].updatedByUser.displayName.split(' ')[1].charAt(0)}`}
              </CustomAvatar>
              {objects[i].updatedByUser.displayName} {!diff.changes[0].old ? 'added' : 'changed'}{' '}
              <b>{capitalize(diff.changes[0].title)}</b>
            </div>
          );
        } else {
          diff.title = (
            <div>
              <CustomAvatar color='#866815' backgroundcolor='rgba(134, 104, 21, 0.1)'>
                {`${objects[i].updatedByUser.displayName.split(' ')[0].charAt(0)}${objects[i].updatedByUser.displayName.split(' ')[1].charAt(0)}`}
              </CustomAvatar>
              {objects[i].updatedByUser.displayName} made <b>Multiple Changes</b>
            </div>
          );
        }

        differences.push(diff);
      }
    }

    // reverse array to get latest changes first
    return differences.reverse();
  };

  const renderObjects = (key: string, value: object | Array<object>) => {
    const attrWithDisplayName = ['createdByUser', 'updatedByUser', 'approvedByUser', 'assignedUser'];

    if (attrWithDisplayName.includes(key)) {
      return value.displayName;
    } else if (key === 'machineDetails') {
      return value.name;
    } else if (key === 'fileAttachments') {
      return value.map((file) => file.fileName).join(', ');
    } else if (key === 'relatedError') {
      return value.message;
    }
  };

  const renderChangeTitle = (change: TComparison) => {
    const formatedTitle = capitalize(splitCamelCase(change.title));

    if (!change.new.length) {
      return (
        <span>
          Removed <b>{formatedTitle}</b>
        </span>
      );
    } else if (!change.old.length) {
      return (
        <span>
          Added <b>{formatedTitle}</b>
        </span>
      );
    }

    return (
      <span>
        Edited <b>{formatedTitle}</b>
      </span>
    );
  };

  useEffect(() => {
    history && setChanges(compareObjects(history));
  }, [history]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      {!changes.length ? (
        <Typography variant='body2' sx={{ color: (theme) => theme.palette.black[60] }}>
          No History
        </Typography>
      ) : (
        changes.map((item, index) => (
          <Accordion
            key={uuidv4()}
            expanded={expanded === `panel${index + 1}`}
            onChange={handleChange(`panel${index + 1}`)}
          >
            <AccordionSummary aria-controls={`panel${index + 1}-content`}>
              {typeof item.title === 'string' ? (
                <Typography variant='body2' sx={{ fontWeight: 400 }}>
                  {item.title}
                </Typography>
              ) : (
                item.title
              )}
              <Typography variant='body2' sx={{ fontWeight: 400, color: (theme) => theme.palette.black[60] }}>
                {item.date}
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              {item.changes.map((change) => (
                <React.Fragment key={uuidv4()}>
                  {item.changes.length > 1 && (
                    <Typography
                      variant='body2'
                      marginBottom={1}
                      sx={{ color: (theme) => theme.palette.black[60], fontWeight: 400 }}
                    >
                      {renderChangeTitle(change)}
                    </Typography>
                  )}
                  <Box display='flex' alignItems='center' gap={2} marginBottom={2}>
                    <Change version='new'>
                      {typeof change.new === 'object' ? renderObjects(change.title, change.new) : change.new}
                    </Change>
                    <Change version='old'>
                      {typeof change.old === 'object' ? renderObjects(change.title, change.old) : change.old}
                    </Change>
                  </Box>
                </React.Fragment>
              ))}
            </AccordionDetails>
          </Accordion>
        ))
      )}
    </>
  );
};

export default ChangeHistory;
