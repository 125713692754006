import { createSelector } from 'reselect';
import { RootState } from '..';

const machineState = (state: RootState) => state.machine;

export const selectLoading = createSelector(machineState, (machine) => machine.loading);
export const selectMachines = createSelector(machineState, (machine) => machine.machines);
export const selectMachineDetails = createSelector(machineState, (machine) => machine.machineDetails.machine);
export const selectTelemetry = createSelector(machineState, (machine) => machine.machineDetails.telemetry);
export const selectMachineNotifications = createSelector(
  machineState,
  (machine) => machine.machineDetails.notifications,
);
export const selectServiceDocuments = createSelector(
  machineState,
  (machine) => machine.machineDetails.serviceDocuments,
);
export const selectKnowledgeFiles = createSelector(machineState, (machine) => machine.machineDetails.knowledgeFiles);
