import { combineReducers } from 'redux';
import userReducer from './slices/user';
import machineReducer from './slices/machine';
import notificationsReducer from './slices/notifications';
import ticketsReducer from './slices/tickets';

export default combineReducers({
  user: userReducer,
  machine: machineReducer,
  notifications: notificationsReducer,
  tickets: ticketsReducer,
});
