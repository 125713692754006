import { ApiError } from './apiError';
import axios, { AxiosError, AxiosInstance, AxiosRequestConfig, AxiosResponse } from 'axios';
import { getErrorMessage, setAuthHeader } from './helpers';
import { REACT_APP_ONEIOT_API_DOMAIN } from '../../config';

const exceptions = (instance: AxiosInstance) => {
  instance.interceptors.response.use(
    (response: AxiosResponse) => response,
    (error: AxiosError) => {
      if (error.response) {
        return Promise.reject(new ApiError(getErrorMessage(error), error.response.status, error.response.data));
      }
    },
  );
};

const makeInstance = () => {
  const config: AxiosRequestConfig = {
    baseURL: REACT_APP_ONEIOT_API_DOMAIN,
    headers: {
      'Content-Type': 'application/json; charset=UTF-8',
      'X-Requested-With': 'XMLHttpRequest',
    },
  };
  const instance = axios.create(config);

  exceptions(instance);
  setAuthHeader(instance);

  return instance;
};

export default makeInstance();
