import React from 'react';
import { useTranslation } from 'react-i18next';
import { Card, CardContent, Typography, Button, styled } from '@mui/material';
import KeyIcon from '@mui/icons-material/Key';

/* ------- Styles ------- */
const Wrapper = styled('div')({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  height: '100vh',
  backgroundImage: 'url("/images/login-bg.png")',
  backgroundRepeat: 'no-repeat',
  backgroundSize: 'cover',
});

const StyledCard = styled(Card)({
  minWidth: '49%',
  minHeight: '51%',
  padding: '40px 60px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
});

const StyledCardContent = styled(CardContent)({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
});

const StyledLabel = styled(Typography)(
  ({ theme }) => `
    fontWeight: 700;
    color: ${theme.palette.text.primary};
  `,
);

const StyledText = styled(Typography)(
  ({ theme }) => `
    color: ${theme.palette.black[60]}
  `,
);

/* ------- Types ------- */
interface IUnauthenticated {
  handleClick: () => void;
}

/* ------- Components ------- */
const Unauthenticated: React.FC<IUnauthenticated> = ({ handleClick }) => {
  const { t } = useTranslation();

  return (
    <Wrapper>
      <StyledCard>
        <StyledCardContent>
          <KeyIcon sx={{ alignSelf: 'center', color: 'primary.main', fontSize: '88px' }} />
          <StyledLabel variant='h4'>{t('common:notAuthenticated')}</StyledLabel>
          <br />
          <StyledText>{t('common:haveNoAccess')}</StyledText>
          <br />
          <br />
          <Button
            variant='contained'
            color='secondary'
            onClick={handleClick}
            data-testid='unauthenticated-button-to-login'
          >
            {t('user:login:submit')}
          </Button>
        </StyledCardContent>
      </StyledCard>
    </Wrapper>
  );
};

export default Unauthenticated;
