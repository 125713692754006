import React from 'react';
import { styled, Theme, CSSObject } from '@mui/material/styles';
import {
  Drawer as MuiDrawer,
  Box,
  CssBaseline,
  Divider,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Typography,
} from '@mui/material';
import { ChevronLeft, ChevronRight } from '@mui/icons-material';
import LanguageSwitch from '../LanguageSwitch';
import useCalculatedDimensions from '../../hooks/useCalculatedDimensions';

/* ------- Styles ------- */
const openedMixin = (theme: Theme, sidebarWidth): CSSObject => ({
  width: sidebarWidth,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: 'hidden',
});

const closedMixin = (theme: Theme): CSSObject => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: 'hidden',
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up('sm')]: {
    width: `calc(${theme.spacing(9)} + 1px)`,
  },
});

const DrawerHeader = styled('div')({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  margin: '24px 16px 24px 24px',
});

const DrawerTitle = styled(Typography)({
  fontWeight: '400',
  fontSize: 24,
});

const DrawerFooter = styled('div')<{ open: boolean }>(({ open }) => ({
  width: '78%',
  position: 'absolute',
  bottom: '24px',
  left: open ? '40px' : '16px',
  display: 'flex',
  alignItems: 'baseline',
  justifyContent: 'space-between',
}));

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' && prop !== 'sidebarWidth' })<{
  sidebarWidth?: number;
}>(({ theme, open, sidebarWidth }) => ({
  width: sidebarWidth,
  minWidth: '100px',
  flexShrink: 0,
  whiteSpace: 'nowrap',
  boxSizing: 'border-box',
  ...(open && {
    ...openedMixin(theme, sidebarWidth),
    '& .MuiDrawer-paper': openedMixin(theme, sidebarWidth),
  }),
  ...(!open && {
    ...closedMixin(theme),
    '& .MuiDrawer-paper': closedMixin(theme),
  }),

  '& .MuiDrawer-paper': {
    position: 'relative',
    overflowX: 'hidden',
  },
}));

/* ------- Types ------- */
export type TItem = {
  id: string;
  name: string;
  permitted: boolean;
  icon: JSX.Element;
  onClick: () => void;
  disabled?: boolean;
};

interface ISidebarProps {
  logo?: JSX.Element;
  title?: string;
  activeItemId: string | undefined;
  groupedItems: TItem[][];
  open: boolean;
  onChange?: () => void;
}
/* ------- Components ------- */
const Sidebar: React.FC<ISidebarProps> = ({ logo, title, activeItemId, groupedItems, open, onChange }) => {
  const { height, width } = useCalculatedDimensions([]);

  const renderListItem = (item: TItem) => {
    const isItemSelected = activeItemId === item.id;
    const Item = (
      <ListItem key={item.id}>
        <ListItemButton
          onClick={item.onClick}
          disabled={item.disabled}
          sx={{
            minHeight: 48,
            justifyContent: 'center',
            px: 3.5,
            backgroundColor: (theme) => (isItemSelected ? theme.palette.magenta[100] : theme.palette.white.main),
            borderRadius: '4px',
            ':hover': {
              backgroundColor: (theme) => (isItemSelected ? theme.palette.magenta[100] : theme.palette.magenta[20]),
            },
          }}
        >
          <ListItemIcon
            sx={{
              minWidth: 0,
              mr: open ? 3 : 'auto',
              justifyContent: 'center',
              marginRight: open ? '10px' : 0,
              color: (theme) => (isItemSelected ? theme.palette.white.main : theme.palette.black[100]),
            }}
          >
            {item.icon}
          </ListItemIcon>
          {open ? (
            <ListItemText
              primary={item.name}
              sx={{
                color: (theme) => (isItemSelected ? theme.palette.white.main : theme.palette.black[100]),
              }}
            />
          ) : null}
        </ListItemButton>
      </ListItem>
    );

    return Item;
  };

  return (
    <Box sx={{ display: 'inline-flex', height }}>
      <CssBaseline />
      <Drawer variant='permanent' open={open} sidebarWidth={width * 0.17} data-testid='sidebar'>
        <DrawerHeader sx={!open ? { marginLeft: '16px', justifyContent: 'center' } : {}}>
          {logo}
          {title && open && <DrawerTitle>{title}</DrawerTitle>}
          {onChange && (
            <IconButton
              sx={{
                borderRadius: '4px',
                color: (theme) => theme.palette.black[100],
                '.MuiTouchRipple-ripple .MuiTouchRipple-child': {
                  borderRadius: '4px',
                  backgroundColor: (theme) => theme.palette.magenta[20],
                },
                ':hover': {
                  backgroundColor: (theme) => theme.palette.magenta[20],
                },
              }}
              onClick={onChange}
            >
              {open ? <ChevronLeft /> : <ChevronRight />}
            </IconButton>
          )}
        </DrawerHeader>

        {groupedItems.map((group, index) => (
          <React.Fragment key={`itemGroup-${index}`}>
            <List disablePadding>{group.filter((item) => item.permitted).map((item) => renderListItem(item))}</List>
            {index !== groupedItems.length - 1 && <Divider sx={{ margin: '16px' }} data-testid='divider' />}
          </React.Fragment>
        ))}

        <DrawerFooter open={open}>
          <LanguageSwitch
            iconColor={(theme: Theme) => theme.palette.magenta[100]}
            hoverColor={(theme: Theme) => theme.palette.magenta[20]}
          />
        </DrawerFooter>
      </Drawer>
    </Box>
  );
};

export default Sidebar;
