import { TOverviewGroup } from './types';

export const MUI_PRO_KEY: string =
  'ee7433b44adc7e5b36d9547a9d6573fcTz05MzM2NCxFPTE3NTExODQ3NjIwMDAsUz1wcm8sTE09c3Vic2NyaXB0aW9uLEtWPTI=';

export const routes: Record<string, string> = Object.freeze({
  LANDING: '/',
  LAB: '/lab',
  NOTIFICATIONS: '/notifications',
  SERVICE: '/service',
  ADMIN: '/administration',
  SETTINGS: '/settings',
  UNAUTHORIZED: '/403',
});

export const accessKeys = Object.freeze({
  LAB_ALLOWED: 'lab_allowed',
  NOTIFICATIONS_ALLOWED: 'notifications_allowed',
  SERVICE_ALLOWED: 'service_allowed',
  PARAM_SETTINGS_ALLOWED: 'param_settings_allowed',
  USER_MANAGEMENT_ALLOWED: 'user_management_allowed',
  USER_READ_ALLOWED: 'user_read_allowed',
  CREAT_EDIT_TICKETS_ALLOWED: 'creat_edit_tickets_allowed',
  APPROVE_ACCEPT_TICKETS_ALLOWED: 'approve_accept_tickets_allowed',
  DELETE_MODIFY_DOCUMENTS_ALLOWED: 'delete_modify_documents_allowed',
});

export const labOverviewGroups: Record<string, TOverviewGroup> = Object.freeze({
  ISSUES: 'issues',
  PROCESS: 'process',
});

export const SNACKBAR_TIMEOUT: number = 5000;

export const FETCH_INTERVAL: number = 5000; // ms, 5 seconds
