import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from '../../../../../../hooks/redux';
import { selectMachineDetails, selectTelemetry } from '../../../../../../store/selectors/machine';
import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import ProgressBar from '../../../../../../components/ProgressBar';
import { TSpindleData } from '../../../../../../types';

/* ------- Types ------- */
interface Column {
  id: 'name' | 'currentSpeed' | 'targetSpeed' | 'load';
  label: string;
  minWidth?: number;
  align?: 'left' | 'right' | 'center';
  format?: (value: number | string) => string | JSX.Element;
}

interface Data {
  name: string;
  currentSpeed: number;
  targetSpeed: number;
  load: JSX.Element;
}

/* ------- Components ------- */
const SpindleSpeeds = () => {
  const { t } = useTranslation();
  const machineDetails = useSelector(selectMachineDetails);
  const telemetry = useSelector(selectTelemetry);

  const getColors = (value: number) => {
    if (machineDetails?.typeDescription === 'Polishing') {
      if (value <= 60) {
        return 'success';
      } else {
        return 'error';
      }
    }

    if (machineDetails?.typeDescription === 'Grinding') {
      if (value <= 89) {
        return 'success';
      } else {
        return 'error';
      }
    }

    return 'success';
  };

  const renderLoad = (value: number | string) => {
    const loadValue = typeof value === 'number' ? value : 0;
    return <ProgressBar value={loadValue} total={100} color={getColors(loadValue)} />;
  };

  const columns: readonly Column[] = [
    {
      id: 'name',
      label: t('lab:detailView:overview:spindleSpeeds:tableName'),
      align: 'left',
      minWidth: 120,
      format: (value) => <strong>{value}</strong>,
    },
    {
      id: 'currentSpeed',
      label: t('lab:detailView:overview:spindleSpeeds:tableCurrentSpeed'),
      align: 'left',
      format: (value) => Math.round(value as number).toString(),
    },
    {
      id: 'targetSpeed',
      label: t('lab:detailView:overview:spindleSpeeds:tableTargetSpeed'),
      align: 'left',
      format: (value) => Math.round(value as number).toString(),
    },
    {
      id: 'load',
      label: t('lab:detailView:overview:spindleSpeeds:tableLoad'),
      align: 'left',
      minWidth: 250,
      format: (value) => renderLoad(value),
    },
  ];

  const createData = (name, currentSpeed, targetSpeed, load): Data => {
    return { name, currentSpeed, targetSpeed, load };
  };

  const generateRows: (data: TSpindleData[]) => Data[] = (data) =>
    data.map(({ name, currentSpeed, targetSpeed, load }) => createData(name, currentSpeed, targetSpeed, load));

  return (
    <Paper
      sx={{
        width: '100%',
        height: '100%',
        overflow: 'hidden',
        boxShadow: 'none',
        border: (theme) => `1px solid ${theme.palette.grey[20]}`,
      }}
    >
      <TableContainer sx={{ maxHeight: 440 }}>
        <Table stickyHeader aria-label='sticky table'>
          <TableHead>
            <TableRow>
              {columns.map((column) => (
                <TableCell
                  key={column.id}
                  align={column.align}
                  sx={{
                    textTransform: 'uppercase',
                    color: (theme) => theme.palette.grey[100],
                    minWidth: column.minWidth,
                  }}
                >
                  {column.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {telemetry.spindleData?.length
              ? generateRows(telemetry.spindleData).map((row) => {
                  return (
                    <TableRow hover tabIndex={-1} key={row.name}>
                      {columns.map((column) => {
                        const value = row[column.id];
                        return (
                          <TableCell key={column.id} align={column.align} sx={{ fontWeight: 400, padding: '20px' }}>
                            {column.format ? column.format(value) : value}
                          </TableCell>
                        );
                      })}
                    </TableRow>
                  );
                })
              : null}
          </TableBody>
        </Table>
      </TableContainer>
    </Paper>
  );
};

export default SpindleSpeeds;
