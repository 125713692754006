import React from 'react';
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';

const ExtraWidthBtn = styled(Button)({
  minWidth: '186px',
  minHeight: '42px',
});

interface IExtraWidthButtonProps {
  children: React.ReactNode;
  variant?: 'contained' | 'outlined' | 'text';
  color?: 'inherit' | 'primary' | 'secondary' | 'success' | 'error' | 'info' | 'warning';
  disabled?: boolean;
  onClick: () => void;
  sx?: object;
}

const ExtraWidthButton: React.FC<IExtraWidthButtonProps> = ({
  children,
  onClick,
  variant,
  color,
  disabled,
  sx,
  ...rest
}) => {
  return (
    <ExtraWidthBtn onClick={onClick} variant={variant} color={color} disabled={disabled} sx={sx} {...rest}>
      {children}
    </ExtraWidthBtn>
  );
};

export default ExtraWidthButton;
