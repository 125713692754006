import React from 'react';
import { styled } from '@mui/material/styles';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';

/* ------- Styles ------- */
const CloseButtonContainer = styled('div')`
  text-align: right;
  padding: 10px 5px 0 0;
  cursor: pointer;
  line-height: 1;
`;

/* ------- Types ------- */
interface ICloseButtonProps {
  onClick: () => void;
  styles?: React.CSSProperties;
  color?: unknown;
}

/* ------- Components ------- */
const CloseButton: React.FC<ICloseButtonProps> = ({ onClick, styles, color }) => {
  return (
    <CloseButtonContainer style={{ ...styles }}>
      <CloseOutlinedIcon onClick={onClick} data-testid='close-button' sx={{ color: color as string }} />
    </CloseButtonContainer>
  );
};

export default CloseButton;
