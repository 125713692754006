import React from 'react';
import { Breadcrumbs, Link, Typography } from '@mui/material';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';

interface IBreadcrumbProps {
  breadcrumbItems: { pathname: string; label: string }[];
}

const Breadcrumb: React.FC<IBreadcrumbProps> = ({ breadcrumbItems }) => (
  <Breadcrumbs
    separator={<NavigateNextIcon fontSize='small' sx={{ color: (theme) => theme.palette.black[100] }} />}
    sx={{
      width: '100%',
      height: '72px',
      padding: '24px 40px',
    }}
    id='breadcrumb'
    aria-label='breadcrumb'
    data-testid='breadcrumb'
  >
    {breadcrumbItems.length > 1 &&
      breadcrumbItems.map((breadcrumb, index) =>
        index + 1 < breadcrumbItems.length ? (
          <Link
            key={breadcrumb.pathname}
            underline='hover'
            href={`/${breadcrumbItems
              .slice(0, index + 1)
              .map((item) => item.pathname)
              .join('/')}`}
            color={(theme) => theme.palette.black[60]}
            sx={{ fontWeight: 400 }}
          >
            {breadcrumb.label}
          </Link>
        ) : (
          <Typography key={breadcrumb.pathname} color='text.primary' fontWeight={500}>
            {breadcrumb.label}
          </Typography>
        ),
      )}
  </Breadcrumbs>
);

export default Breadcrumb;
