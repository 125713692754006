import React from 'react';
import { Box, Typography, styled } from '@mui/material';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';

/*------- Styles -------*/
const Controls = styled(Box)(() => ({
  position: 'fixed',
  bottom: '24px',
  left: '50%',
  transform: 'translateX(-50%)',
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  padding: '8px',
  color: '#ffffff',
  backgroundColor: 'rgba(0, 0, 0, 0.6)',
  borderRadius: '4px',
  zIndex: 10000,
}));

/*------- Types -------*/
interface IPageControlProps {
  numPages: number;
  pageNumber: number;
  setPageNumber: (pageNumber: number) => void;
}

/*------- Components -------*/
const PageControl: React.FC<IPageControlProps> = ({ numPages, pageNumber, setPageNumber }) => {
  const isFirstPage = pageNumber === 1;
  const isLastPage = pageNumber === numPages;

  const goToPrevPage = () => {
    if (!isFirstPage) setPageNumber(pageNumber - 1);
  };
  const goToNextPage = () => {
    if (!isLastPage) setPageNumber(pageNumber + 1);
  };

  return (
    <Controls>
      <ChevronLeftIcon
        onClick={goToPrevPage}
        sx={{
          cursor: 'pointer',
          color: isFirstPage ? (theme) => theme.palette.grey[100] : (theme) => theme.palette.white.main,
        }}
      />
      <Typography variant='body2'>
        {pageNumber} of {numPages}
      </Typography>
      <ChevronRightIcon
        onClick={goToNextPage}
        sx={{
          cursor: 'pointer',
          color: isLastPage ? (theme) => theme.palette.grey[100] : (theme) => theme.palette.white.main,
        }}
      />
    </Controls>
  );
};

export default PageControl;
