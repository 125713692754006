import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  IconButton,
  TextField,
  FormLabel,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

interface IEditNameDialogProps {
  open: boolean;
  onConfirm: (name: string) => void;
  onClose: () => void;
  dialogTitle: string | React.ReactNode;
  inputLabel: string | React.ReactNode;
  inputValue?: string;
}

const EditNameDialog: React.FC<IEditNameDialogProps> = ({
  open,
  onConfirm,
  onClose,
  dialogTitle,
  inputLabel,
  inputValue = '',
}) => {
  const { t } = useTranslation();
  const [name, setName] = useState<string>(inputValue);

  useEffect(() => {
    setName(inputValue);
  }, [inputValue]);

  const handleClose = () => {
    setName('');
    onClose();
  };

  const handleConfirm = () => {
    onConfirm(name);
    handleClose();
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          fontSize: '24px',
          fontWeight: '700',
          color: (theme) => theme.palette.black[100],
        }}
      >
        {dialogTitle}
        <IconButton
          size='small'
          aria-label='close'
          color='inherit'
          onClick={handleClose}
          sx={{ '&:hover': { background: 'none' } }}
        >
          <CloseIcon fontSize='small' />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <FormControl sx={{ minWidth: '400px' }}>
          <FormLabel sx={{ color: (theme) => theme.palette.black[100], fontWeight: '500', fontSize: '12px' }}>
            {inputLabel}
          </FormLabel>
          <TextField
            id='name'
            name='name'
            value={name}
            variant='outlined'
            size='small'
            margin='dense'
            color='secondary'
            fullWidth
            focused
            onChange={(e) => setName(e.target.value)}
          />
        </FormControl>
      </DialogContent>
      <DialogActions sx={{ justifyContent: 'center', gap: '24px', padding: '8px 24px 24px' }}>
        <Button variant='outlined' onClick={handleClose} fullWidth color='secondary'>
          {t('common:cancel')}
        </Button>
        <Button variant='contained' disabled={!name} onClick={handleConfirm} fullWidth color='secondary'>
          {t('common:save')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default EditNameDialog;
