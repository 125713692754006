import React, { SyntheticEvent, useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from '../../../../hooks/redux';
import { selectLoading, selectMachineDetails } from '../../../../store/selectors/machine';
import {
  fetchMachineDataPending,
  fetchTelemetryPending,
  setMachineNamePending,
} from '../../../../store/slices/machine';
import Grid from '@mui/material/Unstable_Grid2'; // Grid version 2
import { Box, Link, Tab, Tabs, Typography, styled } from '@mui/material';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import StatusBadge from '../../../../components/StatusBadge';
import EditNameDialog from './partials/EditNameDialog';
import Overview from './Overview';
import Notifications from './Notifications';
import useCalculatedDimensions from '../../../../hooks/useCalculatedDimensions';
import ServiceDocuments from './ServiceDocuments';
import KnowledgeDatabase from './KnowledgeDatabase';
import { TMachineState } from '../../../../types';
import { FETCH_INTERVAL, routes } from '../../../../constants';
import { selectFeatureFlags } from '../../../../store/selectors/user';

/* ------- Styles ------- */
const DetailsTitle = styled(Typography)(() => ({
  display: 'inline',
  marginBottom: '24px',
}));

const StatusWrapper = styled(Box)(() => ({
  display: 'inline-flex',
  gap: '12px',
  justifyContent: 'flex-start',
  marginLeft: '24px',
}));

const SubHeader = styled(Box)(() => ({
  display: 'flex',
  gap: '40px',
  justifyContent: 'space-between',
  marginBottom: '24px',

  '& > div': {
    display: 'inline-flex',
    gap: '24px',
  },
}));

const MachineInfo = styled(Typography)(({ theme }) => ({
  display: 'inline',
  color: theme.palette.black[60],
}));

const Action = styled(Link)(() => ({
  display: 'flex',
  gap: '8px',
  fontWeight: 400,
  cursor: 'pointer',
}));

const Counter = styled('div')(({ theme }) => ({
  display: 'inline',
  borderRadius: '33px',
  padding: '4px 8px',
  marginLeft: '8px',
  backgroundColor: theme.palette.grey[10],
}));

const TabPanel = styled('div')<{ height: number }>(({ height }) => ({
  padding: '32px 4px',
  overflow: 'auto',
  height,
  msOverflowStyle: 'none' /* IE and Edge */,
  scrollbarWidth: 'none' /* Firefox */,
  '&::-webkit-scrollbar': {
    display: 'none',
  },
}));

/* ------- Types ------- */
interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
  height: number;
}

/* ------- Components ------- */
const CustomTabPanel: React.FC<TabPanelProps> = ({ children, value, index, height, ...other }) => (
  <TabPanel role='tabpanel' hidden={value !== index} {...other} height={height}>
    {value === index && children}
  </TabPanel>
);

const DetailView: React.FC = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const featureFlags = useSelector(selectFeatureFlags);
  const machineDetails = useSelector(selectMachineDetails);
  const loading = useSelector(selectLoading);
  const breadcrumbRef = document.getElementById('breadcrumb');
  const header = useRef(null);
  const subHeader = useRef(null);
  const tabNav = useRef(null);
  const { height } = useCalculatedDimensions([breadcrumbRef, header.current, subHeader.current, tabNav.current]);
  const [activeTab, setActiveTab] = useState<number>(0);
  const [openChangeName, setOpenChangeName] = useState<boolean>(false);

  useEffect(() => {
    let interval;
    // get machine id from url
    const machineId = window.location.pathname.split('/').splice(1)[1];

    // if tab is overview (index 0) and not loading
    if (activeTab === 0 && !loading) {
      // first load machine and telemetry data
      dispatch(fetchMachineDataPending({ machineId }));
      dispatch(fetchTelemetryPending({ machineId }));

      // then refetch machines every x seconds based on FETCH_INTERVAL constant
      interval = setInterval(() => {
        dispatch(fetchMachineDataPending({ machineId }));
        dispatch(fetchTelemetryPending({ machineId }));
      }, FETCH_INTERVAL);
    }

    // stop fetching if tab is not overview
    if (activeTab !== 0) {
      clearInterval(interval);
    }

    return () => {
      clearInterval(interval);
    };
  }, [dispatch, activeTab]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleTabChange = (_event: SyntheticEvent, newValue: number) => {
    setActiveTab(newValue);
  };

  const handleChangeName = (name: string) => {
    if (machineDetails) {
      dispatch(setMachineNamePending({ machineId: machineDetails.id, name }));
    }
  };

  const renderCounter = () => {
    const errors = machineDetails?.activeNotifications.errorsCount || 0;
    const warnings = machineDetails?.activeNotifications.warningsCount || 0;

    return errors > 0 || warnings > 0 ? <Counter>{errors + warnings}</Counter> : null;
  };

  return (
    <>
      <Grid container>
        <Grid xs={12} sx={{ marginBottom: '16px' }} ref={header}>
          {machineDetails && (
            <>
              <DetailsTitle variant='h5'>{machineDetails.name}</DetailsTitle>
              <StatusWrapper>
                <StatusBadge
                  status={machineDetails.state.toLowerCase() as TMachineState}
                  label={t(`lab:status:${machineDetails.state.toLowerCase()}`)}
                  tooltip={
                    machineDetails.state.toLowerCase() === 'unknown' ? t('lab:status:tooltip:unknown') : undefined
                  }
                />
                {machineDetails.activeNotifications.errorsCount > 0 && (
                  <StatusBadge
                    status='error'
                    label={machineDetails.activeNotifications.errorsCount}
                    tooltip={`${machineDetails.activeNotifications.errorsCount} ${t('lab:status:tooltip:error')}`}
                    onClick={() => navigate(routes.NOTIFICATIONS)}
                  />
                )}
                {machineDetails.activeNotifications.warningsCount > 0 && (
                  <StatusBadge
                    status='warning'
                    label={machineDetails.activeNotifications.warningsCount}
                    tooltip={`${machineDetails.activeNotifications.warningsCount} ${t('lab:status:tooltip:warning')}`}
                    onClick={() => navigate(routes.NOTIFICATIONS)}
                  />
                )}
              </StatusWrapper>
            </>
          )}
        </Grid>

        <Grid xs={12} ref={subHeader}>
          <SubHeader>
            <Box>
              <MachineInfo>{`${t('lab:detailView:header:category')} ${machineDetails?.typeDescription}`}</MachineInfo>
              <MachineInfo>{`${t('lab:detailView:header:type')} ${machineDetails?.type}`}</MachineInfo>
              <MachineInfo>{`${t('lab:detailView:header:serialNumber')} ${machineDetails?.serialNumber}`}</MachineInfo>
            </Box>
            <Action onClick={() => setOpenChangeName(true)}>
              <EditOutlinedIcon />
              {t('lab:detailView:header:actionEdit')}
            </Action>
          </SubHeader>
        </Grid>

        <Grid xs={12}>
          <Tabs
            ref={tabNav}
            value={activeTab}
            onChange={handleTabChange}
            textColor='secondary'
            indicatorColor='secondary'
            sx={{ borderBottom: (theme) => `1px solid ${theme.palette.grey[20]}` }}
          >
            <Tab label={<span>{t('lab:detailView:tabs:overview')}</span>} />
            <Tab
              label={
                <span>
                  {t('lab:detailView:tabs:notifications')} {renderCounter()}
                </span>
              }
            />
            {featureFlags?.includes('OnlineMode') && (
              <Tab label={<span>{t('lab:detailView:tabs:serviceDocuments')}</span>} />
            )}
            {featureFlags?.includes('OnlineMode') && (
              <Tab label={<span>{t('lab:detailView:tabs:knowledgeDatabase')}</span>} />
            )}
          </Tabs>

          <CustomTabPanel value={activeTab} index={0} height={height - 50 /* tab height with padding */}>
            <Overview />
          </CustomTabPanel>
          <CustomTabPanel value={activeTab} index={1} height={height - 50 /* tab height with padding */}>
            <Notifications />
          </CustomTabPanel>
          {featureFlags?.includes('OnlineMode') && (
            <>
              <CustomTabPanel value={activeTab} index={2} height={height - 50 /* tab height with padding */}>
                <ServiceDocuments />
              </CustomTabPanel>
              <CustomTabPanel value={activeTab} index={3} height={height - 50 /* tab height with padding */}>
                <KnowledgeDatabase />
              </CustomTabPanel>
            </>
          )}
        </Grid>
      </Grid>

      <EditNameDialog
        open={openChangeName}
        onClose={() => setOpenChangeName(false)}
        onConfirm={handleChangeName}
        inputValue={machineDetails?.name}
        dialogTitle={t('lab:detailView:editNameDialog:title')}
        inputLabel={t('lab:detailView:editNameDialog:label')}
      />
    </>
  );
};

export default DetailView;
