import { LogLevel } from '@azure/msal-browser';
import {
  REACT_APP_SIGNUP_SIGNIN_POLICY,
  REACT_APP_AUTHORITY_DOMAIN,
  REACT_APP_AUTHORITY_URI,
  REACT_APP_CLIENT_ID,
  REACT_APP_SCOPES_URI,
} from '../../config';

// Browser check variables
// If you support IE, our recommendation is that you sign-in using Redirect APIs
// If you as a developer are testing using Edge InPrivate mode, please add "isEdge" to the if check
const ua = window.navigator.userAgent;
const msie = ua.indexOf('MSIE ');
const msie11 = ua.indexOf('Trident/');
const msedge = ua.indexOf('Edge/');
const firefox = ua.indexOf('Firefox');
const isIE = msie > 0 || msie11 > 0;
const isEdge = msedge > 0;
const isFirefox = firefox > 0; // Only needed if you need to support the redirect flow in Firefox incognito

/**
 * Enter here the user flows and custom policies for your B2C application
 * To learn more about user flows, visit: https://docs.microsoft.com/en-us/azure/active-directory-b2c/user-flow-overview
 * To learn more about custom policies, visit: https://docs.microsoft.com/en-us/azure/active-directory-b2c/custom-policy-overview
 */
export const b2cPolicies = {
  names: {
    signUpSignIn: REACT_APP_SIGNUP_SIGNIN_POLICY,
    // editProfile: "B2C_1_ProfileEditPolicy"
  },
  authorities: {
    signUpSignIn: {
      authority: `https://${REACT_APP_AUTHORITY_DOMAIN}/${REACT_APP_AUTHORITY_URI}/${REACT_APP_SIGNUP_SIGNIN_POLICY}`,
    },
    // ,
    // editProfile: {
    //     authority: "https://businessiamacc.b2clogin.com/businessiamacc.onmicrosoft.com/B2C_1A_SMART_HRD_SUSI"
    // }
  },
  authorityDomain: REACT_APP_AUTHORITY_DOMAIN,
};

export const msalConfig = {
  auth: {
    clientId: REACT_APP_CLIENT_ID,
    authority: b2cPolicies.authorities.signUpSignIn.authority,
    knownAuthorities: [b2cPolicies.authorityDomain],
    redirectUri: '/',
    postLogoutRedirectUri: '/',
  },
  cache: {
    cacheLocation: 'localStorage',
    storeAuthStateInCookie: isIE || isEdge || isFirefox,
  },
  system: {
    allowNativeBroker: false, // Disables WAM Broker
    loggerOptions: {
      // TODO: remove when no need to have logger
      loggerCallback: (level, message, containsPii) => {
        if (containsPii) {
          return;
        }
        switch (level) {
          case LogLevel.Error:
            console.error(message);
            return;
          case LogLevel.Info:
            console.info(message);
            return;
          case LogLevel.Verbose:
            console.debug(message);
            return;
          case LogLevel.Warning:
            console.warn(message);
            return;
          default:
            return;
        }
      },
    },
  },
};

// Scopes you add here will be prompted for consent during login
export const loginRequest = {
  scopes: [`${REACT_APP_SCOPES_URI}/access_as_user`],
  prompt: 'login', // login | none | consent | select_account | create ;
  // https://learn.microsoft.com/en-us/entra/identity-platform/msal-js-prompt-behavior
};

/**
 * Enter here the coordinates of your web API and scopes for access token request
 * The current application coordinates were pre-registered in a B2C tenant.
 */
export const apiConfig = {
  scopes: [`${REACT_APP_SCOPES_URI}/access_as_user`],
  uri: REACT_APP_SCOPES_URI,
};
