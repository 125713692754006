import React from 'react';
import { Card as MuiCard, CardActions, CardContent, CardMedia, Typography, styled } from '@mui/material';

/* ------- Styles ------- */
const CardTitle = styled(Typography)({
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
});

const Badges = styled(CardActions)({
  padding: '16px 20px 24px 20px',
  gap: '12px',
  justifyContent: 'flex-start',
  flexWrap: 'wrap',

  '&>:not(style)~:not(style)': {
    margin: 0,
  },
});

/* ------- Types ------- */
interface ICardProps {
  img: {
    src: string;
    alt: string;
  };
  title: string;
  text?: string;
  badges?: JSX.Element[];
  severity?: 'error' | 'warning' | 'info' | 'success' | 'default';
  onClick?: () => void;
}

const Card: React.FC<ICardProps> = ({ img, title, text, badges, severity = 'default', onClick }) => {
  return (
    <MuiCard
      onClick={onClick}
      sx={{
        width: 256,
        minHeight: 322,
        border: (theme) => `${severity === 'default' ? '1px' : '2px'} solid ${theme.palette[severity].main}`,
        cursor: onClick ? 'pointer' : 'auto',
      }}
    >
      <CardMedia component='img' alt={img.alt} height='170' image={img.src} />
      <CardContent sx={{ padding: '16px 20px 0 20px' }}>
        <CardTitle gutterBottom variant='h6' title={title}>
          {title}
        </CardTitle>
        {text && (
          <Typography variant='body2' color='text.secondary' sx={{ fontWeight: 400 }}>
            {text}
          </Typography>
        )}
      </CardContent>
      {badges && <Badges>{badges}</Badges>}
    </MuiCard>
  );
};

export default Card;
