import { createTheme } from '@mui/material/styles';

const palette = {
  primary: {
    main: '#780C44',
  },
  secondary: {
    main: '#0179E7',
  },
  success: {
    main: '#4AA669',
  },
  warning: {
    main: '#FFAD00',
  },
  error: {
    main: '#CD3331',
  },
  info: {
    main: '#0179E7',
  },
  default: {
    main: '#CFCFD0',
  },
  text: {
    primary: '#000000',
  },
  magenta: {
    100: '#780C44',
    80: '#933D69',
    60: '#AE6D8F',
    40: '#C99EB4',
    20: '#E4CEDA',
    10: '#F2E7EC',
  },
  purple: {
    100: '#9E39CD',
    20: '#E8CFF3',
    10: '#FAF5FC',
  },
  red: {
    100: '#CD3331',
    60: '#E38985',
    20: '#F5D6D6',
    10: '#FAEBEA',
  },
  black: {
    100: '#000000',
    80: '#333333',
    60: '#666666',
    40: '#999999',
    20: '#cccccc',
  },
  grey: {
    100: '#868789',
    80: '#9E9FA1',
    60: '#B6B7B8',
    40: '#CFCFD0',
    20: '#E7E7E7',
    10: '#F3F3F3',
  },
  white: {
    main: '#ffffff',
  },
  others: {
    orange: {
      100: '#FFAD00',
      60: '#FFCE80',
      20: '#FFEFCC',
      10: '#FFF7E6',
    },
    blue: {
      100: '#0179E7',
      40: '#7DBAF3',
      20: '#CCE4FA',
      10: '#E6F2FD',
    },
    green: {
      100: '#4AA669',
      20: '#DBEDE1',
      10: '#EDF6F0',
    },
    brown: {
      100: '#996800',
      20: '#EEE9DD',
      10: '#F9F7F3',
    },
  },
};

const theme = {
  palette,
  customShadows: {
    s: '0px 1px 3px 0px rgba(58, 58, 58, 0.05), 0px 1px 2px 0px rgba(58, 58, 58, 0.08)',
    m: '0px 2px 4px 0px rgba(0, 0, 0, 0.20)',
    l: '0px 8px 20px 2px rgba(0, 0, 0, 0.10)',
    xl: '0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 3px 5px -1px rgba(0, 0, 0, 0.20)',
  },
  typography: {
    fontFamily: ['Roboto', 'sans-serif'].join(','),
    fontSize: 16,
    htmlFontSize: 18,
    h1: {
      fontWeight: 300,
      fontSize: '96px',
    },
    h2: {
      fontWeight: 300,
      fontSize: '60px',
    },
    h3: {
      fontWeight: 400,
      fontSize: '48px',
    },
    h4: {
      fontWeight: 400,
      fontSize: '32px',
    },
    h5: {
      fontWeight: 500,
      fontSize: '24px',
    },
    h6: {
      fontWeight: 500,
      fontSize: '20px',
    },
    subtitle1: {
      textTransform: 'uppercase',
      fontWeight: 500,
    },
    body1: {
      fontWeight: 400,
      fontSize: '16px',
    },
    body2: {
      fontWeight: 500,
      fontSize: '14px',
    },
  },
  components: {
    MuiTab: {
      styleOverrides: {
        root: {
          textTransform: 'capitalize',
          padding: '12px',
        },
      },
    },
    MuiLink: {
      styleOverrides: {
        root: {
          color: palette.others.blue[100],
          textDecoration: 'none',
          fontSize: '16px',
          fontWeight: 500,

          '&[disabled]': {
            color: palette.black[40],
            pointerEvents: 'none',
          },
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: 'none',
          paddingLeft: '24px',
          paddingRight: '24px',
        },
      },
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          '&:hover': {
            backgroundColor: palette.others.blue[10],
          },

          '&.Mui-selected, &.Mui-selected.Mui-focusVisible': {
            backgroundColor: palette.others.blue[10],
            '&:hover': {
              backgroundColor: palette.others.blue[10],
            },
          },
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          borderRadius: '5px',
          borderColor: palette.black[40],
          fontSize: '16px',

          '&:hover': {
            borderColor: palette.others.blue[100],
          },

          '&.Mui-focused': {
            borderColor: palette.others.blue[100],
          },
        },
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          fontSize: '16px',
          fontWeight: 500,
          color: palette.black[100],
        },
      },
    },
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          color: palette.white[100],
          backgroundColor: palette.black[80],
          fontSize: '14px',
          fontWeight: 400,

          '& .MuiTooltip-arrow': {
            color: palette.black[80],
          },
        },
      },
    },
    MuiSnackbar: {
      styleOverrides: {
        root: {
          '& .MuiSnackbarContent-root': {
            color: palette.black[100],
          },
        },
      },
    },
    MuiAlert: {
      styleOverrides: {
        root: {
          '&.MuiAlert-standardSuccess': {
            backgroundColor: palette.others.green[20],
            color: palette.black[100],
            fontWeight: 400,
          },
          '&.MuiAlert-standardSuccess .MuiAlert-icon': {
            color: palette.others.green[100],
          },

          '&.MuiAlert-standardWarning': {
            backgroundColor: palette.others.orange[20],
            color: palette.black[100],
          },
          '&.MuiAlert-standardWarning .MuiAlert-icon': {
            color: palette.others.orange[100],
          },

          '&.MuiAlert-standardError': {
            backgroundColor: palette.red[20],
            color: palette.black[100],
          },
          '&.MuiAlert-standardError .MuiAlert-icon': {
            color: palette.error.main,
          },

          '&.MuiAlert-standardInfo': {
            backgroundColor: palette.others.blue[200],
            color: palette.black[100],
          },
          '&.MuiAlert-standardInfo .MuiAlert-icon': {
            color: palette.info.main,
          },
        },
      },
    },
    MuiTableRow: {
      styleOverrides: {
        root: {
          '&.MuiTableRow-hover:hover': {
            backgroundColor: 'initial',
          },
        },
      },
    },
    MuiTablePagination: {
      styleOverrides: {
        root: {
          '& .MuiTablePagination-selectLabel, & .MuiTablePagination-displayedRows': {
            fontWeight: 400,
          },
        },
      },
    },
    MuiCard: {
      styleOverrides: {
        root: {
          boxShadow: '0px 8px 20px 2px rgba(0, 0, 0, 0.1)',
        },
      },
    },
    MuiDivider: {
      styleOverrides: {
        root: {
          borderColor: palette.grey[10],
        },
      },
    },
  },
} as const;

export default createTheme(theme);
