import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, FormControl, MenuItem, Select, SelectChangeEvent, Typography, styled } from '@mui/material';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { useDispatch, useSelector } from '../../../hooks/redux';
import { selectMachines } from '../../../store/selectors/machine';
import { fetchMachinesPending } from '../../../store/slices/machine';
import { TICKET_STATUS, TServiceProvider, TTicket, TTimeFilterValue, TUrgency } from '../../../types';
import dayjs from 'dayjs';
import { formatDate } from '../../../helpers';
import { DateRange } from '@mui/x-date-pickers-pro/models';
import DateRangePicker from '../../../components/DateRangePicker';

/* ------- Styles ------- */
const SelectLabel = styled(Typography)(({ theme }) => ({
  display: 'inline',
  color: theme.palette.black[60],
  marginRight: '4px',
}));

/* ------- Types ------- */
export type TFilterValues = {
  machine?: 'all' | string;
  urgency: 'all' | TUrgency;
  serviceProvider: 'all' | TServiceProvider;
  contact: 'all' | string;
  created: 'all' | TTimeFilterValue;
  status: 'all' | TICKET_STATUS;
};

interface IFilterProps {
  contactPersons: TTicket['assignedUser'][];
  filterValues: TFilterValues;
  setFilterValues: (filterValues: TFilterValues) => void;
  machinesFilterActive?: boolean;
}

/* ------- Components ------- */
const Filter: React.FC<IFilterProps> = ({
  contactPersons,
  filterValues,
  setFilterValues,
  machinesFilterActive = false,
}) => {
  const {
    t,
    i18n: { language },
  } = useTranslation();
  const dispatch = useDispatch();
  const machines = useSelector(selectMachines);
  const [customRangeOpen, setCustomRangeOpen] = useState(false);
  const dateFormat: Intl.DateTimeFormatOptions = { day: '2-digit', month: '2-digit', year: 'numeric' };

  useEffect(() => {
    if (machinesFilterActive && !machines) {
      dispatch(fetchMachinesPending());
    }
  }, [machinesFilterActive, machines, dispatch]);

  const handleFilterChange = (event: SelectChangeEvent<string>) => {
    if (event.target.name === 'created' && event.target.value === 'customRange') {
      setCustomRangeOpen(true);
    } else {
      setFilterValues({ ...filterValues, [event.target.name]: event.target.value });
    }
  };

  const applyCustomRange = (range: DateRange<dayjs.Dayjs>) => {
    const rangeString = `${range[0]?.toISOString()},${range[1]?.toISOString()}`;
    setFilterValues({ ...filterValues, created: rangeString });
    setCustomRangeOpen(false);
  };

  return (
    <>
      <Box display='flex' gap='16px'>
        {machinesFilterActive && (
          <FormControl size='small'>
            <Select
              color='secondary'
              labelId='machine'
              name='machine'
              value={filterValues.machine}
              renderValue={(value) => (
                <>
                  <SelectLabel>{t('tickets:filter:labels:machines')}</SelectLabel>
                  {value === 'all'
                    ? t('tickets:filter:values:all')
                    : machines?.filter((machine) => machine.serialNumber === value)[0]?.name}
                </>
              )}
              onChange={handleFilterChange}
              IconComponent={KeyboardArrowDownIcon}
            >
              <MenuItem value='all'>{t('tickets:filter:values:all')}</MenuItem>
              {machines?.map((machine) => (
                <MenuItem key={machine.id} value={machine.serialNumber}>
                  {machine.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        )}

        <FormControl size='small'>
          <Select
            color='secondary'
            labelId='urgency'
            name='urgency'
            value={filterValues.urgency}
            renderValue={(value) => (
              <>
                <SelectLabel>{t('tickets:filter:labels:urgency')}</SelectLabel>
                {t(`tickets:filter:values:${value.toLowerCase()}`)}
              </>
            )}
            onChange={handleFilterChange}
            IconComponent={KeyboardArrowDownIcon}
          >
            <MenuItem value='all'>{t('tickets:filter:values:all')}</MenuItem>
            <MenuItem value='LOW'>{t('tickets:filter:values:low')}</MenuItem>
            <MenuItem value='MEDIUM'>{t('tickets:filter:values:medium')}</MenuItem>
            <MenuItem value='HIGHT'>{t('tickets:filter:values:hight')}</MenuItem>
          </Select>
        </FormControl>

        <FormControl size='small'>
          <Select
            color='secondary'
            labelId='serviceProvider'
            name='serviceProvider'
            value={filterValues.serviceProvider}
            renderValue={(value) => (
              <>
                <SelectLabel>{t('tickets:filter:labels:serviceProvider')}</SelectLabel>
                {t(`tickets:filter:values:${value}`)}
              </>
            )}
            onChange={handleFilterChange}
            IconComponent={KeyboardArrowDownIcon}
          >
            <MenuItem value='all'>{t('tickets:filter:values:all')}</MenuItem>
            <MenuItem value='INTERNAL'>{t('tickets:filter:values:internal')}</MenuItem>
            <MenuItem value='OPTOTECH'>{t('tickets:filter:values:optotech')}</MenuItem>
          </Select>
        </FormControl>

        <FormControl size='small'>
          <Select
            color='secondary'
            labelId='contact'
            name='contact'
            value={filterValues.contact}
            renderValue={(value) => (
              <>
                <SelectLabel>{t('tickets:filter:labels:contact')}</SelectLabel>
                {value === 'all'
                  ? t('tickets:filter:values:all')
                  : contactPersons?.filter((person) => person.id === value)[0]?.displayName}
              </>
            )}
            onChange={handleFilterChange}
            IconComponent={KeyboardArrowDownIcon}
          >
            <MenuItem value='all'>{t('tickets:filter:values:all')}</MenuItem>
            {contactPersons.map((contact) => (
              <MenuItem key={contact.id} value={contact.id}>
                {contact.displayName}
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        <FormControl size='small'>
          <Select
            color='secondary'
            labelId='created'
            name='created'
            value={filterValues.created}
            renderValue={(value) => (
              <>
                <SelectLabel>{t('tickets:filter:labels:created')}</SelectLabel>
                {dayjs(value.split(',')[0]).isValid()
                  ? `${formatDate(value.split(',')[0], language, dateFormat)} - ${formatDate(value.split(',')[1], language, dateFormat)}`
                  : t(`tickets:filter:values:${value}`)}
              </>
            )}
            onChange={handleFilterChange}
            IconComponent={KeyboardArrowDownIcon}
          >
            <MenuItem value='all'>{t('tickets:filter:values:all')}</MenuItem>
            <MenuItem value='today'>{t('tickets:filter:values:today')}</MenuItem>
            <MenuItem value='yesterday'>{t('tickets:filter:values:yesterday')}</MenuItem>
            <MenuItem value='currentWeek'>{t('tickets:filter:values:currentWeek')}</MenuItem>
            <MenuItem value='currentMonth'>{t('tickets:filter:values:currentMonth')}</MenuItem>
            <MenuItem value='currentYear'>{t('tickets:filter:values:currentYear')}</MenuItem>
            <MenuItem value='customRange' sx={{ borderTop: (theme) => `1px solid ${theme.palette.grey[40]}` }}>
              {t('tickets:filter:values:customRange')}
            </MenuItem>
          </Select>
        </FormControl>

        <FormControl size='small'>
          <Select
            color='secondary'
            labelId='status'
            name='status'
            value={filterValues.status}
            renderValue={(value) => (
              <>
                <SelectLabel>{t('tickets:filter:labels:status')}</SelectLabel>
                {t(`tickets:filter:values:${value.toLowerCase()}`)}
              </>
            )}
            onChange={handleFilterChange}
            IconComponent={KeyboardArrowDownIcon}
          >
            <MenuItem value='all'>{t('tickets:filter:values:all')}</MenuItem>
            <MenuItem value='NEW'>{t('tickets:filter:values:new')}</MenuItem>
            <MenuItem value='PROGRESS'>{t('tickets:filter:values:progress')}</MenuItem>
            <MenuItem value='CLOSED'>{t('tickets:filter:values:pending')}</MenuItem>
            <MenuItem value='RESOLVED'>{t('tickets:filter:values:resolved')}</MenuItem>
            <MenuItem value='CANCELED'>{t('tickets:filter:values:canceled')}</MenuItem>
          </Select>
        </FormControl>
      </Box>

      <DateRangePicker
        open={customRangeOpen}
        onClose={() => setCustomRangeOpen(false)}
        onApply={(value) => applyCustomRange(value)}
      />
    </>
  );
};

export default Filter;
