import React, { useState } from 'react';
import { Avatar, Box, Button, Divider, Link, styled, TextField, Typography } from '@mui/material';
import { TComment } from '../../types';
import { useTranslation } from 'react-i18next';
import { formatDate } from '../../helpers';
import { useSelector } from '../../hooks/redux';
import { selectUserId } from '../../store/selectors/user';
import DeleteDialog from '../DeleteDialog';

/* ------- Styles ------- */
const StyledComment = styled('div')`
  display: flex;
  gap: 16px;
  align-items: baseline;
  border-radius: 4px;
  padding: 24px;
  width: 100%;
`;

/* ------- Types ------- */
interface ICommentProps {
  comment: TComment;
  userIsAuthor?: boolean;
  onUpdate?: (comment: { id: string; comment: string }) => void;
  onDelete?: () => void;
  styles?: React.CSSProperties;
}

interface ICommentsProps {
  comments: TComment[];
  onSubmit: (comment: { id: string | null; comment: string }) => void;
  onDelete?: (id: string) => void;
  submitBtnText?: string;
  newCommentPlaceholder?: string;
}

/* ------- Components ------- */
export const Comment: React.FC<ICommentProps> = ({ comment, userIsAuthor, onUpdate, onDelete, styles }) => {
  const {
    t,
    i18n: { language },
  } = useTranslation();
  const dateFormat: Intl.DateTimeFormatOptions = {
    day: 'numeric',
    month: 'numeric',
    year: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
  };

  const [commentToBeUpdated, setCommentToBeUpdated] = useState<string>('');
  const [editMode, setEditMode] = useState<boolean>(false);
  const [showMore, setShowMore] = useState<boolean>(false);

  const handleEditMode = () => {
    setEditMode(true);
    setCommentToBeUpdated(comment.comment);
  };

  return (
    <StyledComment
      key={comment.id}
      sx={{
        backgroundColor: (theme) => (userIsAuthor ? theme.palette.others.brown[10] : theme.palette.others.blue[10]),
        alignSelf: userIsAuthor ? 'flex-end' : 'flex-start',
        ...styles,
      }}
    >
      <Avatar
        sx={{
          marginTop: '4px',
          textTransform: 'uppercase',
          backgroundColor: (theme) => (userIsAuthor ? theme.palette.others.brown[20] : theme.palette.others.blue[20]),
          color: (theme) => (userIsAuthor ? theme.palette.others.brown[100] : theme.palette.others.blue[100]),
          fontSize: '14px',
          fontWeight: 500,
        }}
      >
        {`${comment.createdBy.displayName.split(' ')[0].charAt(0)}${comment.createdBy.displayName.split(' ')[1].charAt(0)}`}
      </Avatar>
      <div
        style={{
          width: 'calc(100% - 40px - 16px)',
          alignSelf: 'center',
        }}
      >
        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'baseline', gap: '32px' }}>
          <Typography sx={{ fontWeight: 500, marginBottom: '4px' }}>{comment.createdBy.displayName}</Typography>
          <div style={{ display: 'flex', gap: '24px' }}>
            {userIsAuthor && !editMode && onDelete && onUpdate && (
              <>
                <Link component='button' variant='subtitle1' underline='none' onClick={() => onDelete && onDelete()}>
                  {t('common:delete')}
                </Link>
                <Link component='button' variant='subtitle1' underline='none' onClick={handleEditMode}>
                  {t('common:edit')}
                </Link>
              </>
            )}
            {editMode && onUpdate && (
              <>
                <Link component='button' variant='subtitle1' underline='none' onClick={() => setEditMode(false)}>
                  {t('common:cancel')}
                </Link>
                <Link
                  component='button'
                  variant='subtitle1'
                  underline='none'
                  onClick={() => {
                    onUpdate && onUpdate({ id: comment.id, comment: commentToBeUpdated });
                    setEditMode(false);
                  }}
                >
                  {t('common:save')}
                </Link>
              </>
            )}
          </div>
        </div>
        <div style={{ display: 'flex', marginBottom: '20px', gap: '30px', alignItems: 'center' }}>
          <Typography sx={{ fontSize: '14px', minWidth: 'fit-content' }}>
            {formatDate(comment.createdAt, language, dateFormat)}
            {comment.modifiedAt && ` | ${formatDate(comment.modifiedAt, language, dateFormat)}`}
          </Typography>
        </div>

        {editMode ? (
          <TextField
            fullWidth
            multiline
            maxRows={5}
            value={commentToBeUpdated}
            onChange={(e) => setCommentToBeUpdated(e.target.value)}
            sx={{ '& .MuiInputBase-root': { fontSize: '14px', backgroundColor: (theme) => theme.palette.white.main } }}
          />
        ) : (
          <Typography sx={{ wordBreak: 'break-word' }}>
            {comment.comment.length > 300 && !showMore ? comment.comment.slice(0, 300).concat('...') : comment.comment}
            {comment.comment.length > 300 && (
              <Link
                component='button'
                underline='none'
                onClick={() => setShowMore(!showMore)}
                sx={{ fontSize: '14px', marginLeft: '8px' }}
              >
                {showMore ? t('common:showLess') : t('common:showMore')}
              </Link>
            )}
          </Typography>
        )}
      </div>
    </StyledComment>
  );
};

const Comments: React.FC<ICommentsProps> = ({ comments, onSubmit, onDelete, submitBtnText, newCommentPlaceholder }) => {
  const { t } = useTranslation();
  const userId = useSelector(selectUserId);
  const [comment, setComment] = useState<string>('');
  const [commentToDelete, setCommentToDelete] = useState<string | null>(null);

  const handleDeleteConfirm = () => {
    commentToDelete && onDelete && onDelete(commentToDelete);
  };

  return (
    <>
      <Box display='flex' flexDirection='column' gap={2}>
        <Box
          component='form'
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: 2,
            width: '100%',
          }}
          noValidate
          autoComplete='off'
        >
          <TextField
            placeholder={newCommentPlaceholder}
            name='comment'
            multiline
            rows={4}
            value={comment}
            color='secondary'
            onChange={(e) => setComment(e.target.value)}
          />
          <Button
            variant='contained'
            color='secondary'
            disabled={!comment}
            onClick={() => onSubmit({ id: null, comment })}
            sx={{ width: 'fit-content', alignSelf: 'flex-end', marginBottom: 2 }}
          >
            {submitBtnText || t('common:submit')}
          </Button>
        </Box>
        {comments.length > 0 && <Divider />}
        <Box display='flex' flexDirection='column' gap={4}>
          {comments.map((comment: TComment) => (
            <Comment
              key={comment.id}
              comment={comment}
              userIsAuthor={comment.createdBy.id === userId}
              onUpdate={onSubmit}
              onDelete={() => setCommentToDelete(comment.id)}
            />
          ))}
        </Box>
      </Box>

      <DeleteDialog
        open={!!commentToDelete}
        onConfirm={handleDeleteConfirm}
        onClose={() => setCommentToDelete(null)}
        dialogTitle={t('common:deleteDialog:comments:title')}
        dialogText={t('common:deleteDialog:comments:text')}
      />
    </>
  );
};

export default Comments;
