import React from 'react';
import { useTranslation } from 'react-i18next';
import { Card, CardContent, Typography, styled } from '@mui/material';
import LockIcon from '@mui/icons-material/Lock';

/* ------- Styles ------- */
const Wrapper = styled('div')({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  height: '100vh',
  backgroundImage: 'url("/images/login-bg.png")',
  backgroundRepeat: 'no-repeat',
  backgroundSize: 'cover',
});

const StyledCard = styled(Card)({
  minWidth: '49%',
  minHeight: '51%',
  padding: '40px 60px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
});

const StyledCardContent = styled(CardContent)({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
});

const StyledLabel = styled(Typography)(
  ({ theme }) => `
    fontWeight: 700;
    color: ${theme.palette.text.primary};
  `,
);

const StyledText = styled(Typography)(
  ({ theme }) => `
    color: ${theme.palette.black[60]}
  `,
);

/* ------- Components ------- */
const UnauthorizedAccess: React.FC = () => {
  const { t } = useTranslation();

  return (
    <Wrapper>
      <StyledCard>
        <StyledCardContent>
          <LockIcon sx={{ alignSelf: 'center', color: 'primary.main', fontSize: '88px' }} />
          <StyledLabel variant='h4'>{t('common:accessDenied')}</StyledLabel>
          <br />
          <StyledText>{t('common:notAuthorized')}</StyledText>
          <StyledText>{t('common:contactAdministrator')}</StyledText>
        </StyledCardContent>
      </StyledCard>
    </Wrapper>
  );
};

export default UnauthorizedAccess;
