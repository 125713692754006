import React from 'react';
import { styled, Tooltip } from '@mui/material';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';

const MailToLink = styled('a')(({ theme }) => ({
  color: theme.palette.info.main,

  '&:-webkit-any-link:active': {
    color: theme.palette.info.main,
  },
}));

interface ICustomerSupportProps {
  supportMail: string;
  mailSubject: string;
  mailBody: string;
  tooltip?: string;
  tooltipPlacement?: 'top' | 'bottom' | 'left' | 'right';
  sx?: React.CSSProperties;
}

const CustomerSupport: React.FC<ICustomerSupportProps> = ({
  supportMail,
  mailSubject,
  mailBody,
  tooltip = 'Customer support',
  tooltipPlacement = 'bottom',
  sx,
}) => (
  <Tooltip title={tooltip} placement={tooltipPlacement} arrow>
    <MailToLink href={`mailto:${supportMail}?subject=${mailSubject}&body=${mailBody}`} style={sx}>
      <HelpOutlineIcon />
    </MailToLink>
  </Tooltip>
);

export default CustomerSupport;
