import { useState, useEffect } from 'react';
import useWindowDimensions from './useWindowDimensions';

type Sibling = HTMLElement | HTMLDivElement | null;

const useCalculatedDimensions = (siblingsHigh?: Sibling[], siblingsWidth?: Sibling[]) => {
  const { height: windowHeight, width: windowWidth } = useWindowDimensions();
  const [height, setHeight] = useState<number>(windowHeight);
  const [width, setWidth] = useState<number>(windowWidth);

  // calculate height
  useEffect(() => {
    setHeight(windowHeight);

    siblingsHigh?.forEach((sibling) => {
      const elHeight = sibling?.clientHeight;

      if (!elHeight) {
        return;
      }

      setHeight((prevHeight) => prevHeight - elHeight);
    });
  }, [windowHeight, siblingsHigh]);

  // calculate width
  useEffect(() => {
    setWidth(windowWidth);

    siblingsWidth?.forEach((sibling) => {
      const elWidth = sibling?.clientWidth;

      if (!elWidth) {
        return;
      }

      setWidth((prevWidth) => prevWidth - elWidth);
    });
  }, [windowWidth, siblingsWidth]);

  return { height, width };
};

export default useCalculatedDimensions;
