import React, { useRef, useState } from 'react';
import i18next from 'i18next';
import LanguageIcon from '@mui/icons-material/Language';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ContextMenu, { ContextButton } from '../ContextMenu';
import { Theme, Typography } from '@mui/material';

/* ------- Styles ------- */
const baseColorStyle = { color: '#000000' };

/* ------- Types ------- */
interface ILanguageSwitchProps {
  textColor?: (theme: Theme) => string;
  iconColor?: (theme: Theme) => string;
  hoverColor?: (theme: Theme) => string;
}

/* ------- Components ------- */
const LanguageSwitch: React.FC<ILanguageSwitchProps> = ({ textColor, iconColor, hoverColor }) => {
  const { language, languages, changeLanguage } = i18next;
  const [contextOpen, setContextOpen] = useState<boolean>(false);
  const menuAnchorRef = useRef<HTMLButtonElement | null>(null);

  const iconStyles = {
    ...(iconColor ? { color: iconColor } : baseColorStyle),
    ...(contextOpen && { transform: 'rotate(180deg)' }),
  };

  return (
    <div data-testid='languageSwitch'>
      <ContextButton
        ref={menuAnchorRef}
        testId='languageSwitch-button'
        menuOpen={contextOpen}
        handleOpenMenu={() => setContextOpen(true)}
      >
        <>
          <LanguageIcon
            sx={{ ...(iconColor ? { color: iconColor } : baseColorStyle), width: '24px', height: '24px' }}
          />
          <Typography
            data-testid='languageSwitch-button-text'
            color={textColor || baseColorStyle}
            sx={{
              textTransform: 'uppercase',
              marginLeft: '8px',
              fontWeight: 'bold',
            }}
          >
            {language}
          </Typography>
          <ArrowDropDownIcon sx={iconStyles} />
        </>
      </ContextButton>
      <ContextMenu
        menuOpen={contextOpen}
        anchorEl={menuAnchorRef.current}
        handleCloseMenu={() => setContextOpen(false)}
        contextActions={languages.map((lang) => ({
          name: lang.toUpperCase(),
          callback: () => changeLanguage(lang),
          disabled: lang === language,
        }))}
        hoverColor={hoverColor}
      />
    </div>
  );
};

export default LanguageSwitch;
