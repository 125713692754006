import React, { useEffect } from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { routes } from '../constants';
import { EventType } from '@azure/msal-browser';
import { useMsal } from '@azure/msal-react';
import { b2cPolicies } from '../services/auth/auth.config';
import Login from './Login';
import Lab from './Lab';
import DetailView from './Lab/containers/DetailView';
import TicketDetails from './Service/DetailView';
import Notifications from './Notifications';
import Service from './Service';
import Admin from './Admin';
import Settings from './Settings';
import UnauthorizedAccess from '../components/UnauthorizedAccess';
import { REACT_APP_COPILOT_DOMAIN } from '../config';

const Pages = () => {
  const { instance } = useMsal();

  useEffect(() => {
    // copilot script
    const script = document.createElement('script');
    script.src = `${REACT_APP_COPILOT_DOMAIN}/assets/index.js`;
    script.type = 'module';
    script.crossOrigin = 'true';
    document.head.appendChild(script);

    // copilot styles
    const styles = document.createElement('link');
    styles.rel = 'stylesheet';
    script.crossOrigin = 'true';
    styles.href = `${REACT_APP_COPILOT_DOMAIN}/assets/index.css`;
    document.head.appendChild(styles);

    return () => {
      document.head.removeChild(script);
      document.head.removeChild(styles);
    };
  }, []);

  useEffect(() => {
    const callbackId = instance.addEventCallback((event) => {
      if (
        (event.eventType === EventType.LOGIN_SUCCESS || event.eventType === EventType.ACQUIRE_TOKEN_SUCCESS) &&
        event?.payload?.account
      ) {
        /**
         * For the purpose of setting an active account for UI update, we want to consider only the auth
         * response resulting from SUSI flow. "tfp" claim in the id token tells us the policy (NOTE: legacy
         * policies may use "acr" instead of "tfp"). To learn more about B2C tokens, visit:
         * https://docs.microsoft.com/en-us/azure/active-directory-b2c/tokens-overview
         */
        if (event?.payload?.idTokenClaims?.['tfp'] === b2cPolicies.names.editProfile) {
          // retrieve the account from initial sing-in to the app
          const originalSignInAccount = instance
            .getAllAccounts()
            .find(
              (account) =>
                account?.idTokenClaims?.oid === event?.payload?.idTokenClaims?.oid &&
                account?.idTokenClaims?.sub === event?.payload?.idTokenClaims?.sub &&
                account?.idTokenClaims?.['tfp'] === b2cPolicies?.names.signUpSignIn,
            );

          const signUpSignInFlowRequest = {
            authority: b2cPolicies.authorities.signUpSignIn.authority,
            account: originalSignInAccount,
          };

          // silently login again with the signUpSignIn policy
          instance.ssoSilent(signUpSignInFlowRequest);
        }
      }
    });

    return () => {
      if (callbackId) {
        instance.removeEventCallback(callbackId);
      }
    };
    // eslint-disable-next-line
  }, []);

  return (
    <BrowserRouter>
      <Routes>
        <Route path={routes.LANDING} element={<Login />} />
        <Route path={routes.LAB} element={<Lab />}>
          <Route path=':machineId' element={<DetailView />} />
        </Route>
        <Route path={routes.NOTIFICATIONS} element={<Notifications />} />
        <Route path={routes.SERVICE} element={<Service />}>
          <Route path=':ticketId' element={<TicketDetails />} />
        </Route>
        <Route path={routes.ADMIN} element={<Admin />} />
        <Route path={routes.SETTINGS} element={<Settings />} />
        <Route path={routes.UNAUTHORIZED} element={<UnauthorizedAccess />} />
      </Routes>
    </BrowserRouter>
  );
};

export default Pages;
