import React, { useCallback, useEffect, useState } from 'react';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from '../../hooks/redux';
import { fetchMachinesPending } from '../../store/slices/machine';
import { selectLoading, selectMachines } from '../../store/selectors/machine';
import { Box, FormControl, MenuItem, Select, Typography, styled } from '@mui/material';
import Layout from '../../components/Layout';
import Card from '../../components/Card';
import StatusBadge from '../../components/StatusBadge';
import Loader from '../../components/Loader';
import { FETCH_INTERVAL, accessKeys, labOverviewGroups, routes } from '../../constants';
import { TMachine, TMachineState, TOverviewGroup } from '../../types';
import useCalculatedDimensions from '../../hooks/useCalculatedDimensions';
import { machineTypeToImgUrl } from '../../helpers';
import UnauthorizedAccess from '../../components/UnauthorizedAccess';
import useAccessControl from '../../hooks/useAccessControl';

/* ------- Styles ------- */
const MachineWrapper = styled(Box)<{ height: number }>(({ height }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: '32px',
  height,
  overflow: 'auto',
  padding: '0 32px',
}));

const MachineGroup = styled(Box)(() => ({
  display: 'flex',
  gap: '40px',
  flexFlow: 'row wrap',
  marginBottom: '24px',
}));

const HeadWrapper = styled(Box)(() => ({
  display: 'flex',
  justifyContent: 'space-between',
  gap: '32px',
  padding: '0 32px',
}));

const Lab = () => {
  const { AccessControl } = useAccessControl();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const machines = useSelector(selectMachines);
  const loading = useSelector(selectLoading);
  const navigate = useNavigate();
  const location = useLocation();
  const [isSubRoute, setIsSubRoute] = useState<boolean>(false);
  const breadcrumbRef = document.getElementById('breadcrumb');
  const { height } = useCalculatedDimensions([breadcrumbRef]);
  const [selectedGrouping, setSelectedGrouping] = useState<TOverviewGroup>(labOverviewGroups.PROCESS);

  const getCardSeverity = (machine: TMachine) => {
    if (machine.activeNotifications.errorsCount > 0) {
      return 'error';
    }

    if (machine.activeNotifications.warningsCount > 0) {
      return 'warning';
    }

    return 'default';
  };

  const renderBadges = (machine: TMachine) => {
    const { activeNotifications, state } = machine;
    const badges: JSX.Element[] = [];

    badges.push(
      <StatusBadge
        key={machine.id + state}
        status={state.toLowerCase() as TMachineState}
        label={t(`lab:status:${state.toLowerCase()}`)}
        tooltip={state.toLowerCase() === 'unknown' ? t('lab:status:tooltip:unknown') : undefined}
      />,
    );

    if (activeNotifications.errorsCount > 0) {
      badges.push(
        <StatusBadge
          key={machine.id + 'Error'}
          status='error'
          label={activeNotifications.errorsCount}
          tooltip={`${activeNotifications.errorsCount} ${t('lab:status:tooltip:error')}`}
          onClick={() => navigate(routes.NOTIFICATIONS)}
        />,
      );
    }

    if (activeNotifications.warningsCount > 0) {
      badges.push(
        <StatusBadge
          key={machine.id + 'Warning'}
          status='warning'
          label={activeNotifications.warningsCount}
          tooltip={`${activeNotifications.warningsCount} ${t('lab:status:tooltip:warning')}`}
          onClick={() => navigate(routes.NOTIFICATIONS)}
        />,
      );
    }

    return badges;
  };

  // group all machines by typeDescription
  const groupByType = useCallback(
    (machines: TMachine[] | null) => {
      let groupedMachines: Record<string, TMachine[]> = {};
      let groupParam: string;

      // group machines by process
      if (labOverviewGroups.PROCESS === selectedGrouping) {
        machines?.forEach((machine) => {
          if (!groupedMachines[machine.typeDescription]) {
            groupedMachines[machine.typeDescription] = [];
          }

          groupedMachines[machine.typeDescription].push(machine);
        });

        //sort groups
        groupedMachines = Object.keys(groupedMachines)
          .sort()
          .reduce((obj, key) => {
            obj[key] = groupedMachines[key];
            return obj;
          }, {});
      }

      /*
       * Group machines by issues:
       * Every machine with errorsCount higher than 0 will be in error group
       * Every machine with warningsCount higher than 0 will be in warning group
       * Every other machine will be in noIssues group
       */
      if (labOverviewGroups.ISSUES === selectedGrouping) {
        // init groups to have correct order
        groupedMachines['errors'] = [];
        groupedMachines['warnings'] = [];
        groupedMachines['noIssues'] = [];

        // fill groups
        machines?.forEach((machine) => {
          if (machine.activeNotifications.errorsCount > 0) {
            groupParam = 'errors';
          } else if (machine.activeNotifications.warningsCount > 0) {
            groupParam = 'warnings';
          } else {
            groupParam = 'noIssues';
          }

          groupedMachines[groupParam].push(machine);
        });

        // remove empty groups
        Object.keys(groupedMachines).forEach((key) => {
          if (groupedMachines[key].length === 0) {
            delete groupedMachines[key];
          }
        });
      }

      return groupedMachines;
    },
    [selectedGrouping],
  );

  const [groupedMachines, setGroupedMachines] = useState<Record<string, TMachine[]>>(groupByType(machines));

  useEffect(() => {
    setGroupedMachines(groupByType(machines));
  }, [selectedGrouping, groupByType, machines]);

  useEffect(() => {
    let interval;

    if (!isSubRoute && !loading) {
      // first load machines
      dispatch(fetchMachinesPending());

      // then refetch machines every x seconds based on FETCH_INTERVAL constant
      interval = setInterval(() => {
        dispatch(fetchMachinesPending());
      }, FETCH_INTERVAL);
    }

    if (isSubRoute) {
      // stop fetching if route is not overview
      clearInterval(interval);
    }

    return () => {
      clearInterval(interval);
    };
  }, [dispatch, isSubRoute]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    const isLabRoute = location.pathname.includes('/lab/');
    const isRouteWithId = location.pathname.lastIndexOf('/') < location.pathname.length - 1;
    setIsSubRoute(isLabRoute && isRouteWithId);
  }, [location]);

  return (
    <Layout noChildPadding>
      <AccessControl accessKeys={[accessKeys.LAB_ALLOWED]} noAccessComponent={<UnauthorizedAccess />}>
        {!isSubRoute ? (
          <Box sx={{ paddingTop: '24px' }}>
            <HeadWrapper>
              <Typography variant='h5' sx={{ marginBottom: '24px' }}>
                {t('lab:title')}
              </Typography>
              <FormControl sx={{ m: 1, width: 256 }} size='small'>
                <Select
                  color='secondary'
                  value={selectedGrouping}
                  onChange={(e) => setSelectedGrouping(e.target.value as TOverviewGroup)}
                  renderValue={(value) => (
                    <>
                      <Typography component='span' sx={{ color: (theme) => theme.palette.black[60] }}>
                        {t('lab:grouping:preLabel')}
                      </Typography>
                      {t(`lab:grouping:${value}`)}
                    </>
                  )}
                >
                  {Object.values(labOverviewGroups).map((option) => (
                    <MenuItem key={option} value={option} color='secondary'>
                      {t(`lab:grouping:${option}`)}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </HeadWrapper>
            {loading ? (
              <Loader />
            ) : (
              <MachineWrapper height={height - 80}>
                {Object.entries(groupedMachines).map(([key, value]) => (
                  <Box key={key}>
                    <Typography variant='h6' sx={{ marginBottom: '16px', color: (theme) => theme.palette.black[60] }}>
                      {t(`lab:grouping:${key}`)}
                    </Typography>
                    <MachineGroup>
                      {value.map((machine) => (
                        <Card
                          key={machine.id}
                          img={{
                            src: machineTypeToImgUrl(machine.type),
                            alt: machine.type,
                          }}
                          title={machine.name}
                          text={machine.serialNumber}
                          badges={renderBadges(machine)}
                          severity={getCardSeverity(machine)}
                          onClick={() => navigate(`/lab/${machine.id}`)}
                        />
                      ))}
                    </MachineGroup>
                  </Box>
                ))}
              </MachineWrapper>
            )}
          </Box>
        ) : (
          <Box sx={{ padding: '24px 32px 0 32px' }}>
            <Outlet />
          </Box>
        )}
      </AccessControl>
    </Layout>
  );
};

export default Lab;
