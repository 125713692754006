import React, { useEffect, useState } from 'react';
import { LicenseInfo } from '@mui/x-license';
import { AuthenticatedTemplate, UnauthenticatedTemplate, useAccount, useMsal } from '@azure/msal-react';
import { loginRequest } from '../../services/auth/auth.config';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from '../../hooks/redux';
import { selectMachineDetails, selectMachines } from '../../store/selectors/machine';
import { selectFeatureFlags, selectUser, selectUserRoles, selectUsers } from '../../store/selectors/user';
import {
  fetchFeatureFlagsPending,
  fetchUserDataPending,
  fetchUserRolesPending,
  fetchUsersPending,
  setAuthUser,
} from '../../store/slices/user';
import Grid from '@mui/material/Unstable_Grid2'; // Grid version 2
import AssessmentOutlinedIcon from '@mui/icons-material/AssessmentOutlined';
import NotificationsNoneIcon from '@mui/icons-material/NotificationsNone';
import BuildOutlinedIcon from '@mui/icons-material/BuildOutlined';
import GroupOutlinedIcon from '@mui/icons-material/GroupOutlined';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import LogoutIcon from '@mui/icons-material/Logout';
import Sidebar from '../../components/Sidebar';
import Breadcrumb from '../../components/Breadcrumb';
import { MUI_PRO_KEY, routes } from '../../constants';
import { Box } from '@mui/material';
import Unauthenticated from '../Unauthenticated';
import useAccessControl from '../../hooks/useAccessControl';
import CustomerSupport from '../CustomerSupport';

/* ------- Types ------- */
interface ILayout {
  children: React.ReactNode;
  sidebarOnChange?: () => void;
  noChildPadding?: boolean;
}

/* ------- Components ------- */
const Layout: React.FC<ILayout> = ({ children, sidebarOnChange, noChildPadding = false }) => {
  const dispatch = useDispatch();
  const { pathname } = useLocation();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const user = useSelector(selectUser);
  const users = useSelector(selectUsers);
  const roles = useSelector(selectUserRoles);
  const featureFlags = useSelector(selectFeatureFlags);
  const account = useAccount();
  const { instance } = useMsal();
  const { userAccess } = useAccessControl();
  const machines = useSelector(selectMachines);
  const machineDetails = useSelector(selectMachineDetails);
  const [breadcrumb, setBreadcrumb] = useState<{ pathname: string; label: string }[]>([]);
  const [activeSidebarItem, setActiveSidebarItem] = useState<string | undefined>(
    Object.values(routes).find((route) => pathname === route),
  );

  // set breadcrumb when url changes
  useEffect(() => {
    const items = window.location.pathname
      .split('/')
      .splice(1)
      .filter((item) => item.length);

    const newBreadcrumb = items.map((item) => {
      switch (item) {
        case 'lab':
          return { pathname: 'lab', label: t('lab:title') };
        case 'notifications':
          return { pathname: 'notifications', label: t('notifications:title') };
        case 'service':
          return { pathname: 'service', label: t('tickets:title') };
        case 'administration':
          return { pathname: 'administration', label: t('administration:userManagement:title') };
        case 'settings':
          return { pathname: 'settings', label: 'Settings' };
        default:
          return { pathname: item, label: item };
      }
    });

    // check if item is same like machine id and replace id with name
    if (newBreadcrumb.length > 1 && newBreadcrumb[0].pathname === 'lab') {
      newBreadcrumb[1].label =
        machines?.find((machine) => machine.id === newBreadcrumb[1].pathname)?.name || (machineDetails?.name as string);
      setBreadcrumb(newBreadcrumb);
    } else if (newBreadcrumb.length > 1 && newBreadcrumb[0].pathname === 'service') {
      newBreadcrumb[1].label = `Ticket #${newBreadcrumb[1].pathname}`;
      setBreadcrumb(newBreadcrumb);
    } else {
      setBreadcrumb(newBreadcrumb);
    }
  }, [window.location.pathname, machineDetails]); // eslint-disable-line

  useEffect(() => {
    if (account) {
      // set auth user when account changes
      dispatch(
        setAuthUser({
          tenantId: account.tenantId,
          organizationId: (account?.idTokenClaims?.extension_organizationId as string) || account.tenantId,
          organizationPermissions: (account?.idTokenClaims?.extension_permissions as string)?.split(' '),
          localAccountId: account.localAccountId,
          name: account.name,
        }),
      );

      // set MUI license key
      LicenseInfo.setLicenseKey(MUI_PRO_KEY);
    }
  }, [account, dispatch]);

  // fetch roles when no roles are available and route is admin
  useEffect(() => {
    if (pathname.includes(routes.ADMIN) && !roles) {
      dispatch(fetchUserRolesPending());
    }
  }, [dispatch, pathname]); // eslint-disable-line react-hooks/exhaustive-deps

  // load users when no users are available
  useEffect(() => {
    if (!user) {
      dispatch(fetchUserDataPending());
    }
    if (!users) {
      dispatch(fetchUsersPending());
    }
  }, [dispatch]); // eslint-disable-line react-hooks/exhaustive-deps

  // set active sidebar item when route changes
  useEffect(() => {
    Object.values(routes).forEach((route) => {
      if (pathname.includes(route)) {
        setActiveSidebarItem(route);
      }
    });
  }, [pathname]);

  // load feature flags for offline mode check
  useEffect(() => {
    if (!featureFlags.length) {
      dispatch(fetchFeatureFlagsPending());
    }
  }, [dispatch]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleLogin = async () => {
    await instance.loginRedirect(loginRequest);
  };

  const sidebarItems = [
    [
      {
        id: routes.LAB,
        name: t('common:sidebar:views:lab'),
        icon: <AssessmentOutlinedIcon />,
        onClick: () => navigate(routes.LAB),
        permitted: userAccess.lab_allowed,
      },
      {
        id: routes.NOTIFICATIONS,
        name: t('common:sidebar:views:notifications'),
        icon: <NotificationsNoneIcon />,
        onClick: () => navigate(routes.NOTIFICATIONS),
        permitted: userAccess.notifications_allowed,
      },
      {
        id: routes.SERVICE,
        name: t('common:sidebar:views:service'),
        icon: <BuildOutlinedIcon />,
        onClick: () => navigate(routes.SERVICE),
        permitted: userAccess.service_allowed,
      },
      {
        id: routes.ADMIN,
        name: t('common:sidebar:views:admin'),
        icon: <GroupOutlinedIcon />,
        onClick: () => navigate(routes.ADMIN),
        permitted: userAccess.user_management_allowed && featureFlags?.includes('OnlineMode'),
      },
      {
        id: routes.SETTINGS,
        name: t('common:sidebar:views:settings'),
        icon: <SettingsOutlinedIcon />,
        onClick: () => navigate(routes.SETTINGS),
        permitted: true,
        disabled: true,
      },
    ],
    [
      {
        id: 'logout',
        name: t('common:sidebar:views:logout'),
        icon: <LogoutIcon />,
        onClick: () => instance.logoutRedirect(),
        permitted: true,
      },
    ],
  ];

  return (
    <>
      <AuthenticatedTemplate>
        <Grid container>
          <Grid xs={12} sx={{ display: 'flex', flexWrap: 'nowrap' }}>
            <Sidebar
              open={false}
              onChange={sidebarOnChange}
              logo={
                <Link to={routes.LAB}>
                  <img src='/images/Logo_Sidebar.svg' width='96' height='138' alt={t('common:logoAlt')} />
                </Link>
              }
              activeItemId={activeSidebarItem}
              groupedItems={sidebarItems}
            />
            <Box sx={{ display: 'flex', flexDirection: 'column', flexWrap: 'nowrap', width: '100%' }}>
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  gap: '32px',
                  borderBottom: (theme) => `1px solid ${theme.palette.grey[20]}`,
                }}
              >
                <Breadcrumb breadcrumbItems={breadcrumb} />
                <CustomerSupport
                  supportMail='vineeth.vellappatt@optotech.net'
                  mailSubject={t('common:customerSupport:mailSubject')}
                  mailBody={t('common:customerSupport:mailBody', {
                    customerNumber: user?.userId,
                    dateTime: new Date().toLocaleString(),
                    lineBreak: '%0D%0A',
                  })}
                  tooltip={t('common:customerSupport:tooltip')}
                  sx={{ height: 'fit-content', alignSelf: 'center', marginRight: '24px', marginTop: '12px' }}
                />
              </Box>
              <Box sx={{ padding: noChildPadding ? '0' : '24px 32px' }}>{children}</Box>
            </Box>
          </Grid>
        </Grid>
        <schunkchat-wc />
      </AuthenticatedTemplate>
      <UnauthenticatedTemplate>
        <Unauthenticated handleClick={handleLogin} />
      </UnauthenticatedTemplate>
    </>
  );
};

export default Layout;
