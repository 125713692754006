import React from 'react';
import { useTranslation } from 'react-i18next';
import { Box, FormControl, MenuItem, Select, SelectChangeEvent, Typography, styled } from '@mui/material';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { TFilterValues } from '..';

/* ------- Styles ------- */
const SelectLabel = styled(Typography)(({ theme }) => ({
  display: 'inline',
  color: theme.palette.black[60],
  marginRight: '4px',
}));

/* ------- Types ------- */
interface IFilterProps {
  filterValues: TFilterValues;
  setFilterValues: (filterValues: TFilterValues) => void;
}

/* ------- Components ------- */
const Filter: React.FC<IFilterProps> = ({ filterValues, setFilterValues }) => {
  const { t } = useTranslation();

  const handleFilterChange = (event: SelectChangeEvent<string>) => {
    setFilterValues({ ...filterValues, [event.target.name]: event.target.value });
  };

  return (
    <Box display='flex' gap='16px'>
      <FormControl size='small'>
        <Select
          labelId='uploadedDate'
          name='uploadedDate'
          value={filterValues.uploadedDate}
          renderValue={(value) => (
            <>
              <SelectLabel>{t('lab:detailView:serviceDocuments:filter:labels:uploadedDate')}</SelectLabel>
              {t(`lab:detailView:serviceDocuments:filter:values:${value}`)}
            </>
          )}
          onChange={handleFilterChange}
          IconComponent={KeyboardArrowDownIcon}
        >
          <MenuItem value='all'>{t('lab:detailView:serviceDocuments:filter:values:all')}</MenuItem>
          <MenuItem value='currentWeek'>{t('lab:detailView:serviceDocuments:filter:values:currentWeek')}</MenuItem>
          <MenuItem value='currentMonth'>{t('lab:detailView:serviceDocuments:filter:values:currentMonth')}</MenuItem>
          <MenuItem value='currentYear'>{t('lab:detailView:serviceDocuments:filter:values:currentYear')}</MenuItem>
        </Select>
      </FormControl>
    </Box>
  );
};

export default Filter;
