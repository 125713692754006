import React, { useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import Grid from '@mui/material/Unstable_Grid2'; // Grid version 2
import { Avatar, Box, Button, Chip, Divider, Tab, Tabs, Typography, styled } from '@mui/material';
import ModeCommentOutlinedIcon from '@mui/icons-material/ModeCommentOutlined';
import HistoryOutlinedIcon from '@mui/icons-material/HistoryOutlined';
import Loader from '../../../components/Loader';
import { useDispatch, useSelector } from '../../../hooks/redux';
import {
  selectActionSuccessfully,
  selectLoading,
  selectTicketDetails,
  selectTicketHistory,
} from '../../../store/selectors/tickets';
import { formatDate } from '../../../helpers';
import {
  addCommentPending,
  deleteCommentPending,
  fetchTicketDetailsPending,
  fetchTicketHistoryPending,
  resetActionSuccessfullyPending,
  setTicketStatusPending,
} from '../../../store/slices/tickets';
import { TICKET_STATUS } from '../../../types';
import DeleteDialog from '../../../components/DeleteDialog';
import CustomSnackbar from '../../../components/Snackbar';
import NewTicket from '../NewTicket';
import useAccessControl from '../../../hooks/useAccessControl';
import { selectFeatureFlags } from '../../../store/selectors/user';
import ChangeHistory from '../../../components/ChangeHistory';
import Comments from '../../../components/Comments';
import { FileInfo } from '../../../components/FileUpload';

/* ------- Styles ------- */
const statusBaseStyle = {
  fontSize: '14px',
  fontWeight: 500,
};

const StatusWrapper = styled(Box)(() => ({
  display: 'inline-flex',
  gap: '12px',
  justifyContent: 'flex-start',
}));

const SubHeader = styled(Box)(() => ({
  display: 'flex',
  gap: '40px',
  justifyContent: 'space-between',

  '& > div': {
    display: 'inline-flex',
    gap: '24px',
  },
}));

const TicketInfo = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  gap: '8px',
  color: theme.palette.black[60],
}));

const ColorDot = styled('div')<{ color: 'error' | 'warning' | 'success' }>(({ color, theme }) => ({
  width: '8px',
  height: '8px',
  borderRadius: '50%',
  backgroundColor: theme.palette[color].main,
}));

const SubTitle = styled(Typography)(({ theme }) => ({
  color: theme.palette.black[40],
  marginBottom: '8px',
}));

const CustomAvatar = styled(Avatar)<{ color: string; backgroundcolor: string }>(({ color, backgroundcolor }) => ({
  width: '28px',
  height: '28px',
  fontSize: '10px',
  fontWeight: 500,
  color,
  backgroundColor: backgroundcolor,
}));

const TabPanel = styled('div')(() => ({
  padding: '32px 0',
  overflow: 'auto',
  msOverflowStyle: 'none' /* IE and Edge */,
  scrollbarWidth: 'none' /* Firefox */,
  '&::-webkit-scrollbar': {
    display: 'none',
  },
}));

/* ------- Types ------- */
interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

/* ------- Components ------- */
const CustomTabPanel: React.FC<TabPanelProps> = ({ children, value, index, ...other }) => (
  <TabPanel role='tabpanel' hidden={value !== index} {...other}>
    {value === index && children}
  </TabPanel>
);

const TicketDetails = () => {
  const {
    t,
    i18n: { language },
  } = useTranslation();
  const { userAccess } = useAccessControl();
  const featureFlags = useSelector(selectFeatureFlags);
  const dispatch = useDispatch();
  const loading = useSelector(selectLoading);
  const actionSuccessfully = useSelector(selectActionSuccessfully);
  const ticketDetails = useSelector(selectTicketDetails);
  const ticketHistory = useSelector(selectTicketHistory);
  const [cancelTicket, setCancelTicket] = useState<boolean>(false);
  const [openEdit, setOpenEdit] = useState<boolean>(false);
  const [tabValue, setTabValue] = useState<number>(0);
  const dateFormat: Intl.DateTimeFormatOptions = {
    day: 'numeric',
    month: 'numeric',
    year: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
  };

  useEffect(() => {
    const ticketId = window.location.pathname.split('/').splice(1)[1];

    if (ticketDetails?.id.toString() !== ticketId) {
      dispatch(fetchTicketDetailsPending({ ticketId }));
      dispatch(fetchTicketHistoryPending({ ticketId }));
    }
  }, [dispatch, ticketDetails]);

  const handleTabChange = (_event: React.SyntheticEvent, newValue: number) => {
    setTabValue(newValue);
  };

  const handleCancelTicket = () => {
    setCancelTicket(false);
    ticketDetails?.id && dispatch(setTicketStatusPending({ ticketId: ticketDetails.id, ticketStateAction: 'Cancel' }));
  };

  const getSuccessMessage = () => {
    switch (actionSuccessfully) {
      case 'Update':
        return t('tickets:createTicket:successMessages:Update');
      case 'Cancel':
        return t('tickets:ticketDetails:successMessages:canceled');
      case 'Approve':
        return t('tickets:ticketDetails:successMessages:approved');
      case 'Accept':
        return t('tickets:ticketDetails:successMessages:resolved');
      case 'DeleteComment':
        return t('common:deleteDialog:comments:deleteSuccess');
    }
  };

  const handleEditTicket = () => {
    setOpenEdit(true);
  };

  const handleApproveTicket = () => {
    ticketDetails?.id && dispatch(setTicketStatusPending({ ticketId: ticketDetails.id, ticketStateAction: 'Approve' }));
  };

  const handleResolveTicket = () => {
    ticketDetails?.id && dispatch(setTicketStatusPending({ ticketId: ticketDetails.id, ticketStateAction: 'Accept' }));
  };

  const handleSnackbarClose = () => {
    dispatch(resetActionSuccessfullyPending());
  };

  const handleAddComment = (comment) => {
    ticketDetails?.id && dispatch(addCommentPending({ ticketId: ticketDetails.id, comment }));
  };

  const handleDeleteComment = (commentId) => {
    ticketDetails?.id && dispatch(deleteCommentPending({ ticketId: ticketDetails.id, commentId }));
  };

  const urgencyColors = {
    HIGH: 'error',
    MEDIUM: 'warning',
    LOW: 'success',
  };

  const renderChip = (status: TICKET_STATUS) => {
    switch (status) {
      case TICKET_STATUS.NEW:
        return (
          <Chip
            label={t('tickets:table:status:new')}
            sx={{
              ...statusBaseStyle,
              color: (theme) => theme.palette.others.blue[100],
              border: '1px solid rgba(1, 121, 231, .3)',
              backgroundColor: (theme) => theme.palette.others.blue[10],
            }}
          />
        );
      case TICKET_STATUS.PROGRESS:
        return (
          <Chip
            label={t('tickets:table:status:progress')}
            sx={{
              ...statusBaseStyle,
              color: (theme) => theme.palette.others.brown[100],
              border: '1px solid rgba(255, 173, 0, .3)',
              backgroundColor: (theme) => theme.palette.others.orange[10],
            }}
          />
        );
      case TICKET_STATUS.RESOLVED:
        return (
          <Chip
            label={t('tickets:table:status:resolved')}
            sx={{
              ...statusBaseStyle,
              color: (theme) => theme.palette.others.green[100],
              border: '1px solid rgba(74, 166, 105, 1)',
              backgroundColor: (theme) => theme.palette.others.green[10],
            }}
          />
        );
      case TICKET_STATUS.CANCELED:
        return (
          <Chip
            label={t('tickets:table:status:canceled')}
            sx={{
              ...statusBaseStyle,
              color: (theme) => theme.palette.grey[100],
              border: (theme) => `1px solid ${theme.palette.grey[20]}`,
              backgroundColor: (theme) => theme.palette.grey[10],
            }}
          />
        );
      case TICKET_STATUS.PENDING:
        return (
          <Chip
            label={t('tickets:table:status:pending')}
            sx={{
              ...statusBaseStyle,
              color: (theme) => theme.palette.purple[100],
              border: (theme) => `1px solid ${theme.palette.purple[20]}`,
              backgroundColor: (theme) => theme.palette.purple[10],
            }}
          />
        );
      default:
        return '--';
    }
  };

  const getTicketDefaults = () =>
    ticketDetails
      ? {
          id: ticketDetails.id,
          relatedError: ticketDetails.relatedError?.messageId,
          title: ticketDetails.title,
          description: ticketDetails.description,
          serviceProvider: ticketDetails.serviceProvider,
          urgency: ticketDetails.urgency,
          assignee: ticketDetails.serviceProvider === 'InternalService' ? ticketDetails.assignedUser?.id : '',
          contactPerson: ticketDetails.serviceProvider === 'ExternalService' ? ticketDetails.assignedUser?.id : '',
          storedAttachments: ticketDetails.fileAttachments || [],
          includedErrorProtocol: false,
          timePeriodFrom: null,
          timePeriodTo: null,
        }
      : undefined;

  return loading ? (
    <Loader />
  ) : (
    ticketDetails && (
      <>
        <Grid container sx={{ border: (theme) => `1px solid ${theme.palette.grey[10]}`, padding: '24px 32px' }}>
          <Grid xs={12}>
            <Box display='flex' alignItems='center' gap={3} marginBottom={3}>
              <Typography variant='h5'>{`Ticket #${ticketDetails.id}`}</Typography>
              <StatusWrapper>{renderChip(ticketDetails.state)}</StatusWrapper>
            </Box>
          </Grid>

          <Grid xs={12}>
            <SubHeader>
              <Box display='flex' gap={3}>
                <TicketInfo>{`${t('tickets:table:header:created')}: ${formatDate(ticketDetails.createdAt, language, dateFormat)}`}</TicketInfo>
                {ticketDetails.updatedAt && (
                  <TicketInfo>{`${t('tickets:table:header:updated')}: ${formatDate(ticketDetails.updatedAt, language, dateFormat)}`}</TicketInfo>
                )}
                <TicketInfo>
                  <span>{t('tickets:table:header:urgency')}:</span>
                  <ColorDot color={urgencyColors[ticketDetails.urgency.toUpperCase()]} />
                  <span>{t(`tickets:table:urgency:${ticketDetails.urgency.toUpperCase()}`)}</span>
                </TicketInfo>
              </Box>
              {ticketDetails.state !== TICKET_STATUS.CANCELED && ticketDetails.state !== TICKET_STATUS.RESOLVED && (
                <Box display='flex' gap={2}>
                  <Button variant='outlined' color='error' onClick={() => setCancelTicket(true)}>
                    {t('common:cancel')}
                  </Button>
                  <Button variant='outlined' color='info' onClick={handleEditTicket}>
                    {t('common:edit')}
                  </Button>
                  {ticketDetails.state === TICKET_STATUS.PENDING && (
                    <Button variant='contained' color='success' onClick={handleApproveTicket}>
                      {t('common:approve')}
                    </Button>
                  )}
                  {ticketDetails.state === TICKET_STATUS.PROGRESS && (
                    <Button variant='contained' color='success' onClick={handleResolveTicket}>
                      {t('common:resolved')}
                    </Button>
                  )}
                </Box>
              )}
            </SubHeader>
          </Grid>
        </Grid>

        <Box display='flex' width='fit-content' margin='0 auto' padding='24px 32px'>
          <Box display='flex' flexDirection='column' gap={2} sx={{ paddingRight: '24px', width: '700px' }}>
            <Typography variant='h6'>{ticketDetails.title}</Typography>

            {ticketDetails.relatedError && (
              <>
                <Box>
                  <SubTitle variant='body2'>{t('tickets:ticketDetails:relatedError')}</SubTitle>
                  <Typography variant='body1'>{ticketDetails.relatedError.message}</Typography>
                </Box>
                <Divider />
              </>
            )}
            <Box>
              <SubTitle variant='body2'>{t('tickets:ticketDetails:description')}</SubTitle>
              <Typography variant='body1'>{ticketDetails.description}</Typography>
            </Box>
            <Divider />

            <Box>
              <Tabs value={tabValue} onChange={handleTabChange} sx={{ '& .MuiTabs-indicator': { display: 'none' } }}>
                <Tab
                  sx={{ padding: 0, marginRight: '16px' }}
                  label={
                    <Box display={'flex'} alignItems='center' gap={1}>
                      <ModeCommentOutlinedIcon />
                      {t('tickets:ticketDetails:tabs:comments')}
                    </Box>
                  }
                />
                <Tab
                  sx={{ padding: 0 }}
                  label={
                    <Box display={'flex'} alignItems='center' gap={1}>
                      <HistoryOutlinedIcon />
                      {t('tickets:ticketDetails:tabs:history')}
                    </Box>
                  }
                />
              </Tabs>

              <CustomTabPanel value={tabValue} index={0}>
                <Comments
                  comments={ticketDetails.comments}
                  onSubmit={handleAddComment}
                  onDelete={handleDeleteComment}
                  submitBtnText={t('tickets:ticketDetails:postBtn')}
                  newCommentPlaceholder={t('tickets:ticketDetails:newCommentPlaceholder')}
                />
              </CustomTabPanel>
              <CustomTabPanel value={tabValue} index={1}>
                <ChangeHistory history={ticketHistory} ignoreAttributes={['id', 'updatedAt']} />
              </CustomTabPanel>
            </Box>
          </Box>

          <Box
            display='flex'
            flexDirection='column'
            gap={2}
            sx={{ width: '300px', paddingLeft: '24px', borderLeft: (theme) => `1px solid ${theme.palette.grey[10]}` }}
          >
            <Typography variant='body1' fontWeight={500}>
              {t('tickets:ticketDetails:stakeholder')}
            </Typography>
            <Grid container spacing={2}>
              <Grid xs={5}>
                <SubTitle variant='body2' sx={{ margin: 0 }}>
                  {t('tickets:ticketDetails:owner')}
                </SubTitle>
              </Grid>
              <Grid xs={5}>
                <Box display='flex' alignItems='center' gap={1}>
                  <CustomAvatar color='#866815' backgroundcolor='rgba(134, 104, 21, 0.1)'>
                    {`${ticketDetails.createdByUser.displayName.split(' ')[0].charAt(0)}${ticketDetails.createdByUser.displayName.split(' ')[1].charAt(0)}`}
                  </CustomAvatar>
                  <Typography variant='body2' fontWeight={400} sx={{ whiteSpace: 'nowrap' }}>
                    {ticketDetails.createdByUser.displayName}
                  </Typography>
                </Box>
              </Grid>
            </Grid>

            {ticketDetails.approvedByUser && (
              <Grid container spacing={2}>
                <Grid xs={5}>
                  <SubTitle variant='body2' sx={{ margin: 0 }}>
                    {t('tickets:ticketDetails:approver')}
                  </SubTitle>
                </Grid>
                <Grid xs={7}>
                  <Box display='flex' alignItems='center' gap={1}>
                    <CustomAvatar color='#866815' backgroundcolor='rgba(134, 104, 21, 0.1)'>
                      {`${ticketDetails.approvedByUser.displayName.split(' ')[0].charAt(0)}${ticketDetails.approvedByUser.displayName.split(' ')[1].charAt(0)}`}
                    </CustomAvatar>
                    <Typography variant='body2' fontWeight={400} sx={{ whiteSpace: 'nowrap' }}>
                      {ticketDetails.approvedByUser.displayName}
                    </Typography>
                  </Box>
                </Grid>
              </Grid>
            )}

            <Grid container spacing={2}>
              <Grid xs={5}>
                <SubTitle variant='body2' sx={{ margin: 0 }}>
                  {t('tickets:ticketDetails:serviceProvider')}
                </SubTitle>
              </Grid>
              <Grid xs={7}>
                <Typography variant='body2' fontWeight={400} sx={{ whiteSpace: 'nowrap' }}>
                  {t(`tickets:ticketDetails:${ticketDetails.serviceProvider}`)}
                </Typography>
              </Grid>
            </Grid>

            <Grid container spacing={2}>
              <Grid xs={5}>
                <SubTitle variant='body2' sx={{ margin: 0 }}>
                  {t('tickets:ticketDetails:contactPerson')}
                </SubTitle>
              </Grid>
              {ticketDetails.assignedUser ? (
                <Grid xs={7}>
                  <Box display='flex' alignItems='center' gap={1}>
                    <CustomAvatar color='#4A66BF' backgroundcolor='rgba(74, 102, 191, 0.1)'>
                      {`${ticketDetails.assignedUser.displayName.split(' ')[0].charAt(0)}${ticketDetails.assignedUser.displayName.split(' ')[1].charAt(0)}`}
                    </CustomAvatar>
                    <Typography variant='body2' fontWeight={400} sx={{ whiteSpace: 'nowrap' }}>
                      {ticketDetails.assignedUser.displayName}
                    </Typography>
                  </Box>
                </Grid>
              ) : (
                <Grid xs={6}>
                  <Typography variant='body2' fontWeight={400}>
                    --
                  </Typography>
                </Grid>
              )}
            </Grid>

            {ticketDetails.machineDetails && (
              <>
                <Divider />

                <Typography variant='body1' fontWeight={500}>
                  {t('tickets:ticketDetails:machineDetails:title')}
                </Typography>

                <Grid container spacing={2}>
                  <Grid xs={5}>
                    <SubTitle variant='body2' sx={{ margin: 0 }}>
                      {t('tickets:ticketDetails:machineDetails:name')}
                    </SubTitle>
                  </Grid>
                  <Grid xs={7}>
                    <Typography variant='body2' fontWeight={400} sx={{ whiteSpace: 'nowrap' }}>
                      {ticketDetails.machineDetails.name}
                    </Typography>
                  </Grid>
                </Grid>

                <Grid container spacing={2}>
                  <Grid xs={5}>
                    <SubTitle variant='body2' sx={{ margin: 0 }}>
                      {t('tickets:ticketDetails:machineDetails:type')}
                    </SubTitle>
                  </Grid>
                  <Grid xs={7}>
                    <Typography variant='body2' fontWeight={400} sx={{ whiteSpace: 'nowrap' }}>
                      {ticketDetails.machineDetails.type}
                    </Typography>
                  </Grid>
                </Grid>

                <Grid container spacing={2}>
                  <Grid xs={5}>
                    <SubTitle variant='body2' sx={{ margin: 0 }}>
                      {t('tickets:ticketDetails:machineDetails:serialNumber')}
                    </SubTitle>
                  </Grid>
                  <Grid xs={7}>
                    <Typography variant='body2' fontWeight={400} sx={{ whiteSpace: 'nowrap' }}>
                      {ticketDetails.machineDetails.serialNumber}
                    </Typography>
                  </Grid>
                </Grid>

                {ticketDetails.machineDetails.lastMaintenance && (
                  <Grid container spacing={2}>
                    <Grid xs={5}>
                      <SubTitle variant='body2' sx={{ margin: 0 }}>
                        {t('tickets:ticketDetails:machineDetails:lastMaintenance')}
                      </SubTitle>
                    </Grid>
                    <Grid xs={7}>
                      <Typography variant='body2' fontWeight={400} sx={{ whiteSpace: 'nowrap' }}>
                        {ticketDetails.machineDetails.lastMaintenance}
                      </Typography>
                    </Grid>
                  </Grid>
                )}
              </>
            )}
            {ticketDetails.fileAttachments.length > 0 && (
              <>
                <Divider />

                <Typography variant='body1' fontWeight={500}>
                  {t('tickets:ticketDetails:fileAttachments')}
                </Typography>

                <Box display={'flex'} flexDirection='column' gap={2}>
                  {ticketDetails.fileAttachments.map(({ fileName, fileSize }) => (
                    <FileInfo key={fileName} fileName={fileName} fileSize={fileSize} />
                  ))}
                </Box>
              </>
            )}
          </Box>
        </Box>

        {userAccess.creat_edit_tickets_allowed && featureFlags?.includes('OnlineMode') && (
          <NewTicket
            open={openEdit}
            onClose={() => {
              setOpenEdit(false);
            }}
            actionType='Update'
            ticketDefaultValues={getTicketDefaults()}
          />
        )}

        <DeleteDialog
          open={cancelTicket}
          onConfirm={handleCancelTicket}
          onClose={() => setCancelTicket(false)}
          dialogTitle={t('tickets:ticketDetails:cancelDialog:title')}
          dialogConfirmLabel={t('tickets:ticketDetails:cancelDialog:confirmLabel')}
          dialogText={
            <Trans
              i18nKey='tickets:ticketDetails:cancelDialog:text'
              values={{
                ticketId: ticketDetails.id,
              }}
              components={{ bold: <strong /> }}
            />
          }
        />

        <CustomSnackbar
          severity='success'
          open={!!actionSuccessfully}
          onClose={handleSnackbarClose}
          message={getSuccessMessage()}
        />
      </>
    )
  );
};

export default TicketDetails;
