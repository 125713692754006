import { call, put, takeLatest } from 'redux-saga/effects';
import apiClient from '../services/apiClient';
import { fetchNotificationsPending } from '../store/slices/notifications';
import {
  fetchTicketsPending,
  fetchTicketsSuccess,
  createTicketPending,
  createTicketSuccess,
  fetchTicketDetailsPending,
  fetchTicketDetailsSuccess,
  fetchTicketHistoryPending,
  fetchTicketHistorySuccess,
  setTicketStatusPending,
  setTicketStatusSuccess,
  addCommentPending,
  addCommentSuccess,
  actionFailed,
  TPayloadTickets,
  TPayloadCreateTicket,
  TPayloadTicketDetails,
  TPayloadTicketStatus,
  TPayloadTicketComment,
  deleteCommentPending,
  deleteCommentSuccess,
  TPayloadTicketCommentDelete,
} from '../store/slices/tickets';

function* fetchTicketsSaga({ payload }: { payload: TPayloadTickets }) {
  try {
    const { data, totalCount } = yield call(apiClient.post, `/tickets`, payload, {
      useOptoUrl: true,
    });

    yield put(fetchTicketsSuccess({ list: data, total: totalCount }));
  } catch (error) {
    yield put(actionFailed(error));
  }
}

function* createTicketSaga({ payload }: { payload: TPayloadCreateTicket }) {
  try {
    const { ticket, action } = payload;
    const result = yield call(apiClient.put, `/tickets`, ticket, {
      useOptoUrl: true,
      headers: {
        'Content-Type': 'multipart/form-data',
        'X-Requested-With': 'XMLHttpRequest',
      },
    });

    yield put(createTicketSuccess({ ticket: result, action }));
    yield put(fetchTicketHistoryPending({ ticketId: result.id }));
    yield put(fetchNotificationsPending({ page: { pageNumber: 1, pageSize: 25 } }));
  } catch (error) {
    yield put(actionFailed(error));
  }
}

function* fetchTicketDetailsSaga({ payload }: { payload: TPayloadTicketDetails }) {
  try {
    const { ticketId } = payload;
    const result = yield call(apiClient.get, `/tickets/${ticketId}`, { useOptoUrl: true });

    yield put(fetchTicketDetailsSuccess(result));
  } catch (error) {
    yield put(actionFailed(error));
  }
}

function* fetchTicketHistorySaga({ payload }: { payload: TPayloadTicketDetails }) {
  try {
    const { ticketId } = payload;
    const result = yield call(apiClient.get, `/tickets/${ticketId}/history`, { useOptoUrl: true });

    const sortedTickets = yield result
      .sort((a, b) => new Date(a.validTo).getTime() - new Date(b.validTo).getTime())
      .map((item) => item.ticket);

    yield put(fetchTicketHistorySuccess(sortedTickets));
  } catch (error) {
    yield put(actionFailed(error));
  }
}

function* setTicketStatusSaga({ payload }: { payload: TPayloadTicketStatus }) {
  try {
    const { ticketId, ticketStateAction } = payload;
    const result = yield call(apiClient.put, `/tickets/${ticketId}/state`, { ticketStateAction }, { useOptoUrl: true });

    yield put(setTicketStatusSuccess({ ticket: result, ticketStateAction }));
  } catch (error) {
    yield put(actionFailed(error));
  }
}

function* addCommentSaga({ payload }: { payload: TPayloadTicketComment }) {
  try {
    const { ticketId, comment } = payload;
    const result = yield call(apiClient.put, `/tickets/${ticketId}/comment`, comment, { useOptoUrl: true });

    yield put(addCommentSuccess(result));
  } catch (error) {
    yield put(actionFailed(error));
  }
}

function* deleteCommentSaga({ payload }: { payload: TPayloadTicketCommentDelete }) {
  try {
    const { ticketId, commentId } = payload;
    const result = yield call(apiClient.delete, `/tickets/${ticketId}/comment`, commentId, { useOptoUrl: true });

    yield put(deleteCommentSuccess(result));
  } catch (error) {
    yield put(actionFailed(error));
  }
}

export default function* watch() {
  yield takeLatest(fetchTicketsPending, fetchTicketsSaga);
  yield takeLatest(createTicketPending, createTicketSaga);
  yield takeLatest(fetchTicketDetailsPending, fetchTicketDetailsSaga);
  yield takeLatest(fetchTicketHistoryPending, fetchTicketHistorySaga);
  yield takeLatest(setTicketStatusPending, setTicketStatusSaga);
  yield takeLatest(addCommentPending, addCommentSaga);
  yield takeLatest(deleteCommentPending, deleteCommentSaga);
}
