import React from 'react';
import { useTranslation } from 'react-i18next';
import { LocalizationProvider } from '@mui/x-date-pickers-pro/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers-pro/AdapterDayjs';
import { DateRangeCalendar } from '@mui/x-date-pickers-pro/DateRangeCalendar';
import { DateRange } from '@mui/x-date-pickers-pro/models';
import { Dialog, DialogActions, DialogContent, DialogTitle, styled } from '@mui/material';
import ExtraWidthBtn from '../Buttons/ExtraWidthButton';
import dayjs, { Dayjs } from 'dayjs';
import weekday from 'dayjs/plugin/weekday';
import locale_de from 'dayjs/locale/de';
import locale_en from 'dayjs/locale/en';

dayjs.extend(weekday);

/* ------- Styles ------- */
const StyledDateRangeCalendar = styled(DateRangeCalendar)(({ theme }) => ({
  justifyContent: 'center',

  '& .MuiDateRangePickerDay-day.Mui-selected': {
    backgroundColor: theme.palette.secondary.main,
  },
  '& .MuiDateRangePickerDay-day.Mui-selected:hover': {
    backgroundColor: theme.palette.secondary.main,
  },
  '& .MuiDateRangePickerDay-day.Mui-selected:focus': {
    backgroundColor: theme.palette.secondary.main,
  },
  '& .MuiDateRangePickerDay-dayInsideRangeInterval, .MuiDateRangePickerDay-rangeIntervalDayHighlight': {
    backgroundColor: theme.palette.others.blue[10],
  },
}));

/* ------- Types ------- */
interface IDateRangePickerProps {
  open: boolean;
  onClose: () => void;
  onApply: (value: DateRange<Dayjs>) => void;
}

const DateRangePicker: React.FC<IDateRangePickerProps> = ({ open, onClose, onApply }) => {
  const {
    t,
    i18n: { language },
  } = useTranslation();
  const [value, setValue] = React.useState<DateRange<Dayjs> | undefined>(undefined);

  const handleClose = () => {
    setValue(undefined);
    onClose();
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle sx={{ padding: '40px 40px 8px 40px' }}>{t('common:dateRangePicker.title')}</DialogTitle>
      <DialogContent sx={{ padding: '0 17px' }}>
        <LocalizationProvider
          dateAdapter={AdapterDayjs}
          adapterLocale={language === 'de' ? locale_de.name : locale_en.name}
        >
          <StyledDateRangeCalendar
            calendars={1}
            dayOfWeekFormatter={(date) => dayjs(date).format('ddd')}
            disableFuture
            value={value}
            onChange={(newValue) => setValue(newValue)}
          />
        </LocalizationProvider>
      </DialogContent>
      <DialogActions
        sx={{ gap: '8px', padding: '24px 40px', borderTop: (theme) => `1px solid ${theme.palette.grey[20]}` }}
      >
        <ExtraWidthBtn
          variant='outlined'
          onClick={handleClose}
          sx={{
            color: (theme) => theme.palette.black[60],
            borderColor: (theme) => theme.palette.grey[40],
            '&:hover': {
              borderColor: (theme) => theme.palette.black[60],
              background: (theme) => theme.palette.grey[10],
            },
          }}
        >
          {t('common:cancel')}
        </ExtraWidthBtn>
        <ExtraWidthBtn variant='contained' color='secondary' onClick={() => value && onApply(value)} disabled={!value}>
          {t('common:dateRangePicker:apply')}
        </ExtraWidthBtn>
      </DialogActions>
    </Dialog>
  );
};

export default DateRangePicker;
