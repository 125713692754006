import React, { useState } from 'react';
import { Box, styled } from '@mui/material';
import { Document, Page, pdfjs } from 'react-pdf';
import FileHeader from './partials/FileHeader';
import PageControl from './partials/PageControl';
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
import 'react-pdf/dist/esm/Page/TextLayer.css';
import Loader from '../Loader';

pdfjs.GlobalWorkerOptions.workerSrc = new URL('pdfjs-dist/build/pdf.worker.min.mjs', import.meta.url).toString();

/*----- Styles -------*/
const FullScreen = styled(Box)<{ open: boolean }>(({ open }) => ({
  display: open ? 'block' : 'none',
  position: 'fixed',
  top: 0,
  left: 0,
  width: '100%',
  height: '100%',
  backgroundColor: 'rgba(0, 0, 0, 0.6)',
  zIndex: 10000,

  '& .react-pdf__Document': {
    width: 'fit-content',
    margin: '0 auto',
  },
}));

/*----- Types -------*/
interface IPDFReaderProps {
  file: string | null;
  fileName?: string;
  open: boolean;
  onClose: () => void;
  closeLabel: string | React.ReactNode;
}

/*------- Components -------*/
const PDFReader: React.FC<IPDFReaderProps> = ({ file, fileName, open, onClose, closeLabel }) => {
  const [scale, setScale] = useState<number>(1);
  const [numPages, setNumPages] = useState<number>(0);
  const [pageNumber, setPageNumber] = useState<number>(1);

  const onDocumentLoadSuccess = ({ numPages }) => {
    setNumPages(numPages);
  };

  const handleClose = () => {
    setNumPages(0);
    setPageNumber(1);
    setScale(1);
    onClose();
  };

  return (
    <FullScreen open={open} data-testid='pdf-viewer'>
      <FileHeader fileName={fileName} scale={scale} setScale={setScale} closeLabel={closeLabel} onClose={handleClose} />
      {!file ? (
        <Loader sx={{ backgroundColor: 'rgba(255, 255, 255, 0.2)' }} />
      ) : (
        <div data-testid='document'>
          <Document file={file} onLoadSuccess={onDocumentLoadSuccess}>
            <Page pageNumber={pageNumber} scale={scale} />
          </Document>
          <PageControl numPages={numPages} pageNumber={pageNumber} setPageNumber={setPageNumber} />
        </div>
      )}
    </FullScreen>
  );
};

export default PDFReader;
