import common from './common.json';
import user from './user.json';
import administration from './administration.json';
import lab from './lab.json';
import notifications from './notifications.json';
import tickets from './tickets.json';

const localesDe = {
  common,
  user,
  administration,
  lab,
  notifications,
  tickets,
};

export default localesDe;
