import React from 'react';
import { useTranslation } from 'react-i18next';
import Layout from '../../components/Layout';
import UserManagement from './containers/UserManagement';
import useAccessControl from '../../hooks/useAccessControl';
import { accessKeys, routes } from '../../constants';
import { useSelector } from '../../hooks/redux';
import { selectFeatureFlags } from '../../store/selectors/user';
import { Box, Typography, styled } from '@mui/material';
import WifiOffIcon from '@mui/icons-material/WifiOff';

const OfflineMode = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
  alignItems: 'center',
  verticalAlign: 'middle',
  height: '80vh',

  '& svg': {
    fontSize: '74px',
    color: theme.palette.grey[80],
  },

  '& h6': {
    color: theme.palette.black[60],
    fontWeight: 700,
  },

  '& p': {
    color: theme.palette.black[60],
  },
}));

const Admin = () => {
  const { t } = useTranslation();
  const { AccessControl } = useAccessControl();
  const featureFlags = useSelector(selectFeatureFlags);

  return (
    <Layout>
      <AccessControl accessKeys={[accessKeys.USER_MANAGEMENT_ALLOWED]} redirectUrl={routes.UNAUTHORIZED}>
        {featureFlags?.includes('OnlineMode') ? (
          <UserManagement />
        ) : (
          <OfflineMode>
            <WifiOffIcon />
            <Typography variant='h6' component='h6'>
              {t('common:offlineMode:title')}
            </Typography>
            <Typography>{t('common:offlineMode:text')}</Typography>
          </OfflineMode>
        )}
      </AccessControl>
    </Layout>
  );
};

export default Admin;
