import React from 'react';
import { Box, LinearProgress, Tooltip, Typography, linearProgressClasses, styled } from '@mui/material';

/* ------- Styles ------- */
const DiagramWrapper = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
  gap: '8px',
}));

const NoDataLabel = styled(Typography)(({ theme }) => ({
  textAlign: 'center',
  fontWeight: 700,
  color: theme.palette.grey[60],
}));

const Diagram = styled(Box)(() => ({
  display: 'flex',
  width: 'calc(100% - 4px)',

  '& > *:not(:first-of-type)': {
    marginLeft: '2px',
  },

  '& > div:first-of-type *[role="progressbar"]': {
    borderRadius: '8px 0 0 8px',
  },

  '& > div:last-of-type *[role="progressbar"]': {
    borderRadius: '0 8px 8px 0',
  },

  '& > div:only-child *[role="progressbar"]': {
    borderRadius: '8px',
  },
}));

const BorderLinearProgress = styled(LinearProgress)<{ linecolor: string }>(({ linecolor }) => ({
  height: 10,
  width: '100%',

  [`& .${linearProgressClasses.bar}`]: {
    backgroundColor: linecolor,
  },
}));

const ProgressLabel = styled(Typography)<{ color: string; hidelabel?: number }>(({ theme, color, hidelabel = 0 }) => ({
  visibility: hidelabel ? 'hidden' : 'visible',
  minHeight: '30px',
  textAlign: 'center',
  fontWeight: 700,
  color: color,
  '&:after': {
    content: '""',
    display: hidelabel ? 'none' : 'block',
    width: '1px',
    height: '8px',
    backgroundColor: theme.palette.black[40],
    margin: '0 auto 4px auto',
  },
}));

const Legend = styled(Box)(() => ({
  display: 'flex',
  gap: '24px',
  marginTop: '24px',
}));

const LegendLabel = styled(Typography)<{ color: string }>(({ theme, color }) => ({
  color: theme.palette.black[60],
  textTransform: 'capitalize',
  fontWeight: 400,

  '&:before': {
    content: '""',
    display: 'inline-block',
    marginRight: '8px',
    width: '10px',
    height: '10px',
    backgroundColor: color,
  },
}));

/* ------- Types ------- */
interface ILinePieDiagram {
  sections: { state: string; availability: number }[];
  noDataLabel?: string;
  title?: string;
  showProgressLabel?: boolean;
  showLegend?: boolean;
  colorMap?: Record<string, string>;
}

/* ------- Components ------- */
const LinePieDiagram: React.FC<ILinePieDiagram> = ({
  title,
  sections,
  noDataLabel = 'No data',
  showProgressLabel = false,
  showLegend = false,
  colorMap = {},
}) => {
  const renderWithProgressLabel: (state: string, availability: number) => React.ReactNode = (state, availability) => {
    if (availability < 2) {
      return (
        <>
          <ProgressLabel variant='body2' color={colorMap[state]} hidelabel={1} />
          <Tooltip title={`${availability}%`} placement='top' arrow>
            <BorderLinearProgress variant='determinate' value={100} linecolor={colorMap[state]} />
          </Tooltip>
        </>
      );
    }

    return (
      <>
        <ProgressLabel variant='body2' color={colorMap[state]}>
          {availability}%
        </ProgressLabel>
        <BorderLinearProgress variant='determinate' value={100} linecolor={colorMap[state]} />
      </>
    );
  };

  return (
    <DiagramWrapper data-testid='linePieDiagram'>
      {title && <Typography variant='body2'>{title}</Typography>}

      {sections.length <= 0 ? (
        <NoDataLabel variant='body1'>{noDataLabel}</NoDataLabel>
      ) : (
        <Diagram>
          {sections.map(({ state, availability }) => (
            <Box key={state} sx={{ width: `${availability}%` }}>
              {showProgressLabel ? (
                renderWithProgressLabel(state, availability)
              ) : (
                <BorderLinearProgress variant='determinate' value={100} linecolor={colorMap[state]} />
              )}
            </Box>
          ))}
        </Diagram>
      )}

      {showLegend && (
        <Legend>
          {sections.map(({ state }) => (
            <LegendLabel key={state} variant='body2' color={colorMap[state]}>
              {state}
            </LegendLabel>
          ))}
        </Legend>
      )}
    </DiagramWrapper>
  );
};

export default LinePieDiagram;
