import { LinearProgress, styled } from '@mui/material';
import React from 'react';

/*----- Styles -------*/
const StyledProgress = styled(LinearProgress)(({ theme }) => ({
  width: '30%',
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  borderRadius: '4px',
  backgroundColor: theme.palette.black[100],

  '& > span': {
    borderRadius: '4px',
    backgroundColor: theme.palette.grey[20],
  },
}));

/*----- Types -------*/
interface ILoaderProps {
  sx?: object;
}

/*----- Components -------*/
const Loader: React.FC<ILoaderProps> = ({ sx }) => {
  return <StyledProgress sx={sx} />;
};

export default Loader;
