import { call, put, takeLatest } from 'redux-saga/effects';
import apiClient from '../services/apiClient';
import {
  fetchMachinesPending,
  fetchMachinesSuccess,
  fetchMachineDataPending,
  fetchMachineDataSuccess,
  setMachineNamePending,
  setMachineNameSuccess,
  fetchTelemetryPending,
  fetchTelemetrySuccess,
  fetchAvailabilityGraphPending,
  fetchAvailabilityGraphSuccess,
  fetchAvailabilityHistogramPending,
  fetchAvailabilityHistogramSuccess,
  fetchServiceDocumentsPending,
  fetchServiceDocumentsSuccess,
  fetchMachineNotificationsPending,
  fetchMachineNotificationsSuccess,
  fetchDocumentFilePending,
  fetchDocumentFileSuccess,
  sendServiceDocumentPending,
  sendServiceDocumentSuccess,
  sendServiceDocumentFailed,
  deleteServiceDocumentPending,
  deleteServiceDocumentSuccess,
  deleteServiceDocumentFailed,
  downloadDocumentFile,
  fetchKnowledgeFilesPending,
  fetchKnowledgeFilesSuccess,
  downloadKnowledgeFilePending,
  actionFailed,
  TPayloadMachineData,
  TPayloadServiceDocument,
  TPayloadNewServiceDocument,
  TPayloadNotifications,
  TPayloadTelemetryHistogram,
  TPayloadTelemetryGraph,
  TPayloadMachineName,
  TPayloadKnowledgeFiles,
  TPayloadKnowledgeFile,
} from '../store/slices/machine';

function* fetchMachinesSaga() {
  try {
    const result = yield call(apiClient.get, '/machines', { useOptoUrl: true });

    yield put(fetchMachinesSuccess(result.machines));
  } catch (error) {
    yield put(actionFailed(error));
  }
}

function* fetchMachineDataSaga({ payload }: { payload: TPayloadMachineData }) {
  try {
    const { machineId } = payload;
    const result = yield call(apiClient.get, `/machines/${machineId}`, { useOptoUrl: true });

    yield put(fetchMachineDataSuccess(result));
  } catch (error) {
    yield put(actionFailed(error));
  }
}

function* setMachineNameSaga({ payload }: { payload: TPayloadMachineName }) {
  try {
    const { machineId, name } = payload;
    const result = yield call(apiClient.put, `/machines/${machineId}`, { name }, { useOptoUrl: true });

    yield put(setMachineNameSuccess(result));
  } catch (error) {
    yield put(actionFailed(error));
  }
}

function* fetchTelemetrySaga({ payload }: { payload: TPayloadMachineData }) {
  try {
    const { machineId } = payload;
    const result = yield call(apiClient.get, `/machines/${machineId}/telemetry/latest`, { useOptoUrl: true });

    yield put(fetchTelemetrySuccess(result));
  } catch (error) {
    yield put(actionFailed(error));
  }
}

function* fetchAvailabilityGraphSaga({ payload }: { payload: TPayloadTelemetryGraph }) {
  try {
    const { machineId, times } = payload;

    // call api first params of compare
    const compareGraphOne = yield call(apiClient.get, `/machines/${machineId}/telemetry/availability`, {
      useOptoUrl: true,
      params: { fromTime: times.compareGraphOne.fromTime, toTime: times.compareGraphOne.toTime },
    });

    // call api second params of compare
    const compareGraphTwo = yield call(apiClient.get, `/machines/${machineId}/telemetry/availability`, {
      useOptoUrl: true,
      params: { fromTime: times.compareGraphTwo.fromTime, toTime: times.compareGraphTwo.toTime },
    });

    yield put(
      fetchAvailabilityGraphSuccess({
        compareGraphOne: compareGraphOne.machineStatusAvailability,
        compareGraphTwo: compareGraphTwo.machineStatusAvailability,
      }),
    );
  } catch (error) {
    yield put(actionFailed(error));
  }
}

function* fetchAvailabilityHistogramSaga({ payload }: { payload: TPayloadTelemetryHistogram }) {
  try {
    const { machineId, fromTime, toTime, binTimeSpan } = payload;
    const result = yield call(apiClient.get, `/machines/${machineId}/telemetry/availability/histogram`, {
      useOptoUrl: true,
      params: { fromTime, toTime, binTimeSpan },
    });

    yield put(fetchAvailabilityHistogramSuccess(result.machineStatusAvailabilityHistogram));
  } catch (error) {
    yield put(actionFailed(error));
  }
}

function* fetchMachineNotificationsSaga({ payload }: { payload: TPayloadNotifications }) {
  try {
    const { serialNumber, body } = payload;
    const { data } = yield call(apiClient.post, `/machines/${serialNumber}/notifications`, body, {
      useOptoUrl: true,
    });

    yield put(fetchMachineNotificationsSuccess({ list: data.data, total: data.totalCount }));
  } catch (error) {
    yield put(actionFailed(error));
  }
}

function* fetchServiceDocumentsSaga({ payload }: { payload: TPayloadMachineData }) {
  try {
    const { machineId } = payload;
    const result = yield call(apiClient.get, `/machines/${machineId}/files`, {
      useOptoUrl: true,
    });

    yield put(fetchServiceDocumentsSuccess(result));
  } catch (error) {
    yield put(actionFailed(error));
  }
}

function* fetchDocumentFileSaga({ payload }: { payload: TPayloadServiceDocument }) {
  try {
    const { machineId, documentName } = payload;
    const result = yield call(apiClient.get, `/machines/${machineId}/file?documentName=${documentName}`, {
      useOptoUrl: true,
      responseType: 'blob',
    });

    const url = yield URL.createObjectURL(result);
    yield put(fetchDocumentFileSuccess(url));
  } catch (error) {
    yield put(actionFailed(error));
  }
}

function* downloadDocumentFileSaga({ payload }: { payload: TPayloadServiceDocument }) {
  try {
    const { machineId, documentName } = payload;
    const result = yield call(apiClient.get, `/machines/${machineId}/file?documentName=${documentName}`, {
      useOptoUrl: true,
      responseType: 'blob',
    });

    const url = yield URL.createObjectURL(result);
    yield window.open(url, '_blank');
  } catch (error) {
    yield put(actionFailed(error));
  }
}

function* sendServiceDocumentSaga({ payload }: { payload: TPayloadNewServiceDocument }) {
  try {
    const { machineId, file } = payload;
    const result = yield call(apiClient.post, `/machines/${machineId}/file`, file, {
      useOptoUrl: true,
      headers: {
        'Content-Type': 'multipart/form-data',
        'X-Requested-With': 'XMLHttpRequest',
      },
    });

    if (result) {
      yield put(sendServiceDocumentSuccess({ success: true }));
      yield put(fetchServiceDocumentsPending({ machineId }));
    }
  } catch (error) {
    yield put(sendServiceDocumentFailed(error));
  }
}

function* deleteServiceDocumentSaga({ payload }: { payload: TPayloadServiceDocument }) {
  try {
    const { machineId, documentName } = payload;
    yield call(apiClient.delete, `/machines/${machineId}/file`, `?documentName=${documentName}`, {
      useOptoUrl: true,
    });

    yield put(deleteServiceDocumentSuccess({ success: true }));
    yield put(fetchServiceDocumentsPending({ machineId }));
  } catch (error) {
    yield put(deleteServiceDocumentFailed(error));
  }
}

function* fetchKnowledgeFilesSaga({ payload }: { payload: TPayloadKnowledgeFiles }) {
  try {
    const { machineType, machineVersion } = payload;
    const result = yield call(apiClient.get, `/machines/files`, {
      useOptoUrl: true,
      params: { machineType, machineVersion },
    });

    yield put(fetchKnowledgeFilesSuccess(result));
  } catch (error) {
    yield put(actionFailed(error));
  }
}

function* downloadKnowledgeFileSaga({ payload }: { payload: TPayloadKnowledgeFile }) {
  try {
    const { machineType, machineVersion, documentName } = payload;
    const result = yield call(apiClient.get, `/machines/file`, {
      useOptoUrl: true,
      responseType: 'blob',
      params: { machineType, machineVersion, documentName },
    });

    const url = yield URL.createObjectURL(result);
    yield window.open(url, '_blank');
  } catch (error) {
    yield put(actionFailed(error));
  }
}

export default function* watch() {
  yield takeLatest(fetchMachinesPending, fetchMachinesSaga);
  yield takeLatest(fetchMachineDataPending, fetchMachineDataSaga);
  yield takeLatest(setMachineNamePending, setMachineNameSaga);
  yield takeLatest(fetchTelemetryPending, fetchTelemetrySaga);
  yield takeLatest(fetchAvailabilityGraphPending, fetchAvailabilityGraphSaga);
  yield takeLatest(fetchAvailabilityHistogramPending, fetchAvailabilityHistogramSaga);
  yield takeLatest(fetchMachineNotificationsPending, fetchMachineNotificationsSaga);
  yield takeLatest(fetchServiceDocumentsPending, fetchServiceDocumentsSaga);
  yield takeLatest(fetchDocumentFilePending, fetchDocumentFileSaga);
  yield takeLatest(downloadDocumentFile, downloadDocumentFileSaga);
  yield takeLatest(sendServiceDocumentPending, sendServiceDocumentSaga);
  yield takeLatest(deleteServiceDocumentPending, deleteServiceDocumentSaga);
  yield takeLatest(fetchKnowledgeFilesPending, fetchKnowledgeFilesSaga);
  yield takeLatest(downloadKnowledgeFilePending, downloadKnowledgeFileSaga);
}
