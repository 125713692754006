import { createSlice } from '@reduxjs/toolkit';
import { ApolloError } from '@apollo/client/errors';
import { ISSUE_STATUS, NOTIFICATION_TYPE, TNotification } from '../../types';

export type TPayloadNotifications = {
  page: {
    pageNumber: number;
    pageSize: number;
  };
  timeRange?: {
    from: string;
    to: string;
  };
  issueStatus?: ISSUE_STATUS;
  notificationType?: NOTIFICATION_TYPE;
  machineSerialNumbers?: string[];
};

type TPayloadError = {
  error: ApolloError;
};

export interface INotifications {
  notifications: {
    list: TNotification[] | null;
    total: number;
    loading: boolean;
  };
  error: ApolloError | null;
}

/*-----------------INITIAL STATE-----------------*/
export const initialState: INotifications = {
  notifications: {
    list: null,
    total: 0,
    loading: false,
  },
  error: null,
};

/*-----------------SLICE-----------------*/
const notificationsSlice = createSlice({
  name: 'notifications',
  initialState,
  reducers: {
    /*-----------------Pending Actions/Reducers-----------------*/
    fetchNotificationsPending: (
      state,
      _action: {
        payload: TPayloadNotifications;
      },
    ) => ({
      ...state,
      notifications: {
        ...state.notifications,
        loading: true,
      },
    }),

    /*-----------------Success Actions/Reducers-----------------*/
    fetchNotificationsSuccess: (state, { payload }: { payload: { list: TNotification[]; total: number } }) => {
      return {
        ...state,
        notifications: {
          list: payload.list,
          total: payload.total,
          loading: false,
        },
      };
    },

    /*-----------------Failed Actions/Reducers-----------------*/
    actionFailed: (state, { payload }: { payload: TPayloadError }) => {
      return {
        ...state,
        notifications: {
          ...state.notifications,
          loading: false,
        },
        error: payload.error,
      };
    },
  },
});

export const { fetchNotificationsPending, fetchNotificationsSuccess, actionFailed } = notificationsSlice.actions; // eslint-disable-line

export default notificationsSlice.reducer;
