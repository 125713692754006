import { PublicClientApplication, EventType } from '@azure/msal-browser';
import { msalConfig, loginRequest } from './auth.config';

let token, expiresOn;

export const msalInstance = new PublicClientApplication(msalConfig);

export const getToken = async () => {
  const expirationDateTime = new Date(expiresOn).getTime();
  const currentDateTime = new Date().getTime();

  if (token && expirationDateTime > currentDateTime + 1000) {
    // add small 1 second gap for check
    return token;
  }

  const account = msalInstance.getActiveAccount();

  if (!account) {
    throw Error('No active account! Verify a user has been signed in and setActiveAccount has been called.');
  }

  const tokenResponse = await msalInstance.acquireTokenSilent({
    ...loginRequest,
    account: account,
  });

  token = tokenResponse.idToken;
  expiresOn = tokenResponse.expiresOn;

  return token;
};

export const msalInstanceInitCallback = async (instance, render) => {
  try {
    await msalInstance.handleRedirectPromise();
    // Default to using the first account if no account is active on page load
    if (!instance.getActiveAccount() && instance.getAllAccounts().length > 0) {
      // Account selection logic is app dependent. Adjust as needed for different use cases.
      instance.setActiveAccount(instance.getAllAccounts()[0]);
    }

    // Optional - This will update account state if a user signs in from another tab or window
    instance.enableAccountStorageEvents();

    instance.addEventCallback((event) => {
      if (
        event.eventType === EventType.LOGIN_SUCCESS ||
        event.eventType === EventType.ACQUIRE_TOKEN_SUCCESS ||
        event.eventType === EventType.SSO_SILENT_SUCCESS
      ) {
        const account = event?.payload?.account || null;
        instance.setActiveAccount(account);
      }
      if (event.eventType === EventType.ACQUIRE_TOKEN_FAILURE) {
        instance.logoutRedirect();
      }
    });

    return render(instance);
  } catch (_error) {
    return render(instance);
  }
};
