import { configureStore } from '@reduxjs/toolkit';
import createSagaMiddleware from '@redux-saga/core';
import { createReduxHistoryContext } from 'redux-first-history';
import rootSaga from '../sagas';
import rootReducer from './reducers';
import { createBrowserHistory } from 'history';

const { createReduxHistory, routerMiddleware } = createReduxHistoryContext({ history: createBrowserHistory() });
const sagaMiddleware = createSagaMiddleware();
const middlewares = [sagaMiddleware, routerMiddleware];

export const store = configureStore({
  reducer: rootReducer,
  devTools: process.env.NODE_ENV === 'development',
  middleware: (getDefaultMiddleware) => getDefaultMiddleware({ thunk: false }).concat(middlewares),
});

sagaMiddleware.run(rootSaga);

export const browserHistory = createReduxHistory(store);
export default store;

export type RootState = ReturnType<typeof rootReducer>;
export type AppStore = ReturnType<typeof configureStore>;
export type AppDispatch = AppStore['dispatch'];
