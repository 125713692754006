import React, { useCallback, useState } from 'react';
import { Accept, useDropzone } from 'react-dropzone';
import { Box, Link, Typography, styled } from '@mui/material';
import FileUploadOutlinedIcon from '@mui/icons-material/FileUploadOutlined';
import InsertDriveFileOutlinedIcon from '@mui/icons-material/InsertDriveFileOutlined';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';

/* ------- Styles ------- */
const DragDropWrapper = styled(Box)(({ theme }) => ({
  flex: 1,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  padding: '16px 0',
  border: `1px solid ${theme.palette.grey[20]}`,
  borderRadius: 4,
  color: theme.palette.black[60],
  outline: 'none',
  transition: 'border .24s ease-in-out',
}));

const DragDropInfo = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
  gap: '8px',
  alignItems: 'center',
}));

const FileUploadIcon = styled(FileUploadOutlinedIcon)(({ theme }) => ({
  width: '36px',
  height: '36px',
  padding: '8px',
  borderRadius: '100%',
  backgroundColor: theme.palette.info.main,
  fill: theme.palette.white.main,
}));

const InfoLabel = styled(Typography)(({ theme }) => ({
  fontWeight: 400,
  color: theme.palette.black[60],
}));

const DocumentList = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
  gap: '16px',
  marginTop: '16px',
}));

const Document = styled(Box)(({ theme }) => ({
  display: 'flex',
  gap: '16px',
  alignItems: 'center',
  border: `1px solid ${theme.palette.grey[20]}`,
  borderRadius: '4px',
  padding: '16px',

  '& > svg:last-child': {
    marginLeft: 'auto',
  },
}));

const FileIcon = styled(InsertDriveFileOutlinedIcon)(({ theme }) => ({
  width: '48px',
  height: '48px',
  padding: '8px',
  borderRadius: '4px',
  backgroundColor: theme.palette.magenta[10],
  fill: theme.palette.magenta[100],
}));

const FileSize = styled('span')(({ theme }) => ({
  display: 'inline-block',
  marginTop: '4px',
  fontWeight: 400,
  color: theme.palette.black[60],
}));

const RemoveFile = styled(DeleteOutlineIcon)(({ theme }) => ({
  cursor: 'pointer',
  color: theme.palette.black[60],
}));

/*-------- Types -------*/
interface IFileInfoProps {
  fileName: string;
  fileSize: number;
  onRemove?: () => void;
}
interface IFileUploadProps {
  selectFileText?: string;
  infoText?: string;
  fileTypes?: Accept | undefined;
  maxSize?: number;
  maxFiles?: number; // 0 = unlimited
  onChange?: (files: File[]) => void;
  hideFileInfo?: boolean;
}

/* ------- Components ------- */
export const FileInfo: React.FC<IFileInfoProps> = ({ fileName, fileSize, onRemove }) => (
  <Document>
    <FileIcon />
    <Typography variant='body2' sx={{ fontWeight: 400 }}>
      {fileName} <br /> <FileSize>{(fileSize / 1024).toFixed(2)} KB</FileSize>
    </Typography>
    {onRemove && <RemoveFile onClick={onRemove} />}
  </Document>
);

const FileUpload: React.FC<IFileUploadProps> = ({
  selectFileText = 'Select File',
  infoText,
  fileTypes,
  maxSize = 10485760,
  maxFiles = 0,
  onChange,
  hideFileInfo = false,
}) => {
  const [files, setFiles] = useState<File[]>([]);

  const onDrop = useCallback(
    (acceptedFiles) => {
      const allFiles = [...files, ...acceptedFiles];
      setFiles(allFiles);
      onChange?.(allFiles);
    },
    [files, onChange],
  );

  const removeFile = (file) => {
    const newFiles = [...files];
    newFiles.splice(newFiles.indexOf(file), 1);
    setFiles(newFiles);
  };

  const { getRootProps, getInputProps, open } = useDropzone({
    accept: fileTypes,
    minSize: 0,
    maxSize,
    maxFiles,
    multiple: maxFiles !== 1,
    noClick: true,
    noKeyboard: true,
    onDrop,
  });

  return (
    <>
      <DragDropWrapper {...getRootProps()}>
        <input {...getInputProps()} data-testid='file-input' />
        <DragDropInfo>
          <FileUploadIcon />
          <Link component='button' sx={{ textDecoration: 'underline' }} onClick={open}>
            {selectFileText}
          </Link>
          <InfoLabel variant='body2'>{infoText}</InfoLabel>
        </DragDropInfo>
      </DragDropWrapper>
      {!hideFileInfo && (
        <DocumentList>
          {files.map((file) => (
            <FileInfo key={file.name} fileName={file.name} fileSize={file.size} onRemove={() => removeFile(file)} />
          ))}
        </DocumentList>
      )}
    </>
  );
};

export default FileUpload;
