import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from '../../../../../../hooks/redux';
import { selectMachineDetails } from '../../../../../../store/selectors/machine';
import { Box, Divider, Typography, styled } from '@mui/material';
import { Gauge, gaugeClasses } from '@mui/x-charts/Gauge';

/* ------- Styles ------- */
const OverridesWrapper = styled(Box)(() => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  flexDirection: 'column',
}));

const Label = styled(Typography)(({ theme }) => ({
  textAlign: 'center',
  textTransform: 'uppercase',
  color: theme.palette.black[60],
  marginBottom: '4px',
}));

/* ------- Types ------- */
interface IOverridesProps {
  spindle: number | undefined;
  axis: number | undefined;
}

/* ------- Components ------- */
const Overrides: React.FC<IOverridesProps> = ({ spindle, axis }) => {
  const { t } = useTranslation();
  const machineDetails = useSelector(selectMachineDetails);

  const overrides = [
    { label: t('lab:detailView:overview:overrides:spindles'), value: Math.round(spindle || 0) },
    { label: t('lab:detailView:overview:overrides:axes'), value: Math.round(axis || 0) },
  ];

  const getColors = (value: number) => {
    if (machineDetails?.typeDescription === 'Polishing') {
      if (value <= 100) {
        return {
          value: (theme) => theme.palette.others.green[100],
          reference: (theme) => theme.palette.others.green[20],
        };
      } else {
        return {
          value: (theme) => theme.palette.others.orange[100],
          reference: (theme) => theme.palette.others.orange[20],
        };
      }
    }

    if (machineDetails?.typeDescription === 'Grinding') {
      if (value <= 99) {
        return {
          value: (theme) => theme.palette.others.orange[100],
          reference: (theme) => theme.palette.others.orange[20],
        };
      } else {
        return {
          value: (theme) => theme.palette.others.green[100],
          reference: (theme) => theme.palette.others.green[20],
        };
      }
    }

    return {
      value: (theme) => theme.palette.others.green[100],
      reference: (theme) => theme.palette.others.green[20],
    };
  };

  return (
    <OverridesWrapper>
      {overrides.map(({ label, value }, index) => (
        <div key={label}>
          <Label variant='body2'>{label}</Label>
          <Gauge
            value={value}
            valueMax={120}
            width={200}
            height={100}
            startAngle={-90}
            endAngle={90}
            innerRadius='88%'
            sx={{
              [`& .${gaugeClasses.valueText}`]: {
                fontSize: '18px',
                fontWeight: 700,
                transform: 'translate(0px, -16px)',
              },
              [`& .${gaugeClasses.valueArc}`]: {
                fill: getColors(value)?.value,
              },
              [`& .${gaugeClasses.referenceArc}`]: {
                fill: getColors(value)?.reference,
              },
            }}
            text={({ value, valueMax }) => `${value}/${valueMax}`}
          />
          {index !== overrides.length - 1 && <Divider variant='middle' flexItem sx={{ margin: '16px 0' }} />}
        </div>
      ))}
    </OverridesWrapper>
  );
};

export default Overrides;
