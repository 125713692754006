import React, { useEffect } from 'react';
import { useIsAuthenticated, useMsal } from '@azure/msal-react';
import { loginRequest } from '../../services/auth/auth.config';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { Button, Card, CardContent } from '@mui/material';
import { styled } from '@mui/material/styles';
import { routes } from '../../constants';

const Wrapper = styled('div')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  height: '100vh',
  backgroundColor: theme.palette.grey[10],
  backgroundImage: 'url("/images/login-bg.png")',
  backgroundRepeat: 'no-repeat',
  backgroundSize: 'cover',
}));

const Content = styled(CardContent)(() => ({
  display: 'flex',
  flexDirection: 'column',
  gap: '24px',
  padding: '0',
}));

const Login = () => {
  const { instance } = useMsal();
  const isAuthenticated = useIsAuthenticated();
  const navigate = useNavigate();
  const { t } = useTranslation();

  useEffect(() => {
    if (isAuthenticated) {
      navigate(routes.LAB);
    }
  }, [isAuthenticated, navigate]);

  const handleLogin = async () => {
    await instance.loginRedirect(loginRequest);
  };

  return (
    <Wrapper>
      <Card sx={{ minWidth: '400px', padding: '64px' }}>
        <Content>
          <img
            src='/images/Logo_Login.svg'
            width='384'
            height='69'
            alt={t('common:logoAlt')}
            style={{ alignSelf: 'flex-start' }}
          />
          <Button variant='contained' color='secondary' sx={{ fontSize: '16px' }} onClick={handleLogin}>
            {t('user:login:submit')}
          </Button>
        </Content>
      </Card>
    </Wrapper>
  );
};

export default Login;
