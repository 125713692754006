import React, { useRef, useState, useEffect } from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Paper,
  TableHead,
  Typography,
  Link,
  Select,
  OutlinedInput,
  MenuItem,
  SelectChangeEvent,
  FormControl,
  Checkbox,
  ListItemText,
  Box,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { Trans, useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from '../../../../hooks/redux';
import {
  selectActionFlags,
  selectError,
  selectOrganizations,
  selectSelectedOrganization,
  selectUserRoles,
  selectUsers,
} from '../../../../store/selectors/user';
import {
  TCreateUser,
  createUserPending,
  deleteUserPending,
  fetchOrganizationsPending,
  fetchUserRolesPending,
  fetchUsersPending,
  resetActionFlagsPending,
  resetError,
  setSelectedOrganization,
  updateUserRolesPending,
} from '../../../../store/slices/user';
import { TUserRole } from '../../../../types';
import InviteUser from './InviteUser';
import DeleteDialog from '../../../../components/DeleteDialog';
import CustomSnackbar from '../../../../components/Snackbar';
import ExtraWidthButton from '../../../../components/Buttons/ExtraWidthButton';
import useCalculatedDimensions from '../../../../hooks/useCalculatedDimensions';

/* ------- Styles ------- */
const StyledCell = styled(TableCell)({
  fontSize: '16px',
  fontWeight: 400,
  height: '73px',
});

const UserManagementHeader = styled('div')({
  display: 'flex',
  alignItems: 'center',
  marginBottom: '24px',
});

const UserManagementTitle = styled(Typography)({
  flex: '1 1 100%',
});

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

/* ------- Component ------- */
const UserManagement: React.FC = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const errorUserCall = useSelector(selectError);
  const organizations = useSelector(selectOrganizations);
  const selectedOrganization = useSelector(selectSelectedOrganization);
  const users = useSelector(selectUsers);
  const roles = useSelector(selectUserRoles);
  const { createSuccess, deleteSuccess } = useSelector(selectActionFlags);

  const userManagementHeaderRef = useRef<HTMLDivElement | null>(null);
  const breadcrumbRef = document.getElementById('breadcrumb');
  const { height } = useCalculatedDimensions([breadcrumbRef, userManagementHeaderRef.current]);

  const [editedUser, setEditedUser] = useState<string | null>(null);
  const [userRoles, setUserRoles] = useState<string[]>([]);
  const [openInviteUser, setOpenInviteUser] = useState<boolean>(false);
  const [deleteUser, setDeleteUser] = useState<{ userId: string; userName: string } | null>(null);

  useEffect(() => {
    dispatch(fetchOrganizationsPending());
  }, [dispatch]);

  const handleUserEdit = () => {
    dispatch(
      updateUserRolesPending({
        userId: editedUser as string,
        roleIds: userRoles,
      }),
    );

    // clear states
    setUserRoles([]);
    setEditedUser(null);
  };

  const handleEditUser = (userId: string, roles: TUserRole[]) => {
    setEditedUser(userId);
    setUserRoles(roles.map((role) => role.roleId));
  };

  const handleChangeRoles = (event: SelectChangeEvent<typeof userRoles>) => {
    const {
      target: { value },
    } = event;

    setUserRoles(value as string[]);
  };

  // translates role ids to role names
  const getRenderedValue = (roleIds: string | string[]) => {
    if (Array.isArray(roleIds)) {
      return roleIds
        .map((roleId) => {
          // role name but without spaces
          const roleName = roles?.find((role) => role.roleId === roleId)?.name.replace(/\s/g, '');
          return t(`administration:roles:${roleName}`);
        })
        .join(', ');
    }

    // role name but without spaces
    const roleName = roles?.find((role) => role.roleId === roleIds)?.name.replace(/\s/g, '');
    return t(`administration:roles:${roleName}`);
  };

  const handleSaveDeleteUser = (user) => {
    setDeleteUser({ userId: user.userId, userName: user.displayName });
  };

  const handleDeleteUser = () => {
    dispatch(deleteUserPending({ userId: deleteUser?.userId as string }));
    setDeleteUser(null);
  };

  const handleInviteUser = (newUser: TCreateUser) => {
    dispatch(createUserPending(newUser));
  };

  const handleSnackbarClose = () => {
    if (createSuccess) {
      dispatch(resetActionFlagsPending({ createSuccess: false }));
    }
    if (deleteSuccess) {
      dispatch(resetActionFlagsPending({ deleteSuccess: false }));
    }
    if (errorUserCall) {
      dispatch(resetError());
    }
  };

  const handleSelectOrganization = (event: SelectChangeEvent) => {
    const {
      target: { value },
    } = event;

    dispatch(setSelectedOrganization(value as string));
    dispatch(fetchUsersPending());
    dispatch(fetchUserRolesPending());
  };

  const tableHeader = [
    { id: 'space_1', label: '' },
    { id: 'name', label: t('administration:userManagement:headers:name').toUpperCase(), width: '250px' },
    { id: 'email', label: t('administration:userManagement:headers:email').toUpperCase(), width: '400px' },
    { id: 'role', label: t('administration:userManagement:headers:role').toUpperCase() },
    { id: 'action_1', label: '', width: '180px' },
    { id: 'action_2', label: '', width: '180px' },
    { id: 'space_2', label: '' },
  ];

  const mapUsers = () =>
    users?.map((user) => (
      <TableRow hover tabIndex={-1} key={user.userId}>
        <StyledCell sx={{ width: '0px' }} align='left' />
        <StyledCell sx={{ width: '250px' }} scope='row' align='left'>
          {user.displayName}
        </StyledCell>
        <StyledCell sx={{ width: '400px' }} align='left'>
          {user.email}
        </StyledCell>
        <StyledCell align='left'>
          {editedUser === user.userId ? (
            <FormControl size='small'>
              <Select
                labelId='role-edit'
                value={userRoles}
                onChange={handleChangeRoles}
                input={<OutlinedInput />}
                renderValue={() => getRenderedValue(userRoles)}
                MenuProps={MenuProps}
                autoWidth
                multiple
                color='secondary'
              >
                {roles?.map((role) => (
                  <MenuItem key={role.roleId} value={role.roleId} color='secondary'>
                    <Checkbox checked={userRoles?.includes(role.roleId)} color='secondary' />
                    <ListItemText primary={getRenderedValue(role.roleId as string)} />
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          ) : (
            user.roles?.map((role) => getRenderedValue(role.roleId as string)).join(', ')
          )}
        </StyledCell>
        {editedUser !== user.userId && (
          <>
            <StyledCell sx={{ width: '180px' }} align='left'>
              <Link
                component='button'
                onClick={() => handleEditUser(user.userId, user.roles)}
                variant='body1'
                sx={{ fontSize: '15px' }}
              >
                {t('administration:userManagement:actions:edit')}
              </Link>
            </StyledCell>
            <StyledCell sx={{ width: '180px' }} align='left'>
              <Link
                component='button'
                onClick={() => handleSaveDeleteUser(user)}
                variant='body1'
                sx={{ fontSize: '15px' }}
              >
                {t('administration:userManagement:actions:remove')}
              </Link>
            </StyledCell>
          </>
        )}
        {editedUser === user.userId && (
          <>
            <StyledCell sx={{ width: '180px' }} align='left'>
              <Link component='button' onClick={() => setEditedUser(null)}>
                {t('common:cancel')}
              </Link>
            </StyledCell>
            <StyledCell sx={{ width: '180px' }} align='left'>
              <Link component='button' onClick={handleUserEdit} disabled={!userRoles.length}>
                {t('common:save')}
              </Link>
            </StyledCell>
          </>
        )}
        <StyledCell sx={{ width: '0px' }} scope='row' align='left' />
      </TableRow>
    ));

  return (
    <Box sx={{ height: height - 8, overflow: 'auto' }}>
      <UserManagementHeader ref={userManagementHeaderRef} data-testid={'user-management-header'}>
        <UserManagementTitle variant='h5'>{t('administration:userManagement:title')}</UserManagementTitle>
        <Box display='flex' gap={2}>
          <Select
            value={selectedOrganization || undefined}
            onChange={handleSelectOrganization}
            input={<OutlinedInput />}
            MenuProps={MenuProps}
            autoWidth
            size='small'
            color='secondary'
          >
            {organizations?.map((org) => (
              <MenuItem key={org.id} value={org.id}>
                {org.name}
              </MenuItem>
            ))}
          </Select>
          <ExtraWidthButton variant='contained' color='info' onClick={() => setOpenInviteUser(true)}>
            {t('administration:userManagement:actions:invite')}
          </ExtraWidthButton>
        </Box>
      </UserManagementHeader>
      <Paper sx={{ width: '100%', overflow: 'hidden' }}>
        <TableContainer data-testid={'user-management-table'}>
          <Table stickyHeader aria-label='user table'>
            <TableHead>
              <TableRow>
                {tableHeader.map((item) => (
                  <TableCell
                    key={item.id}
                    align='left'
                    sx={{
                      textTransform: 'uppercase',
                      color: (theme) => theme.palette.grey[100],
                      minWidth: item.width,
                    }}
                  >
                    <b>{item.label || null}</b>
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>{mapUsers()}</TableBody>
          </Table>
        </TableContainer>
      </Paper>

      <DeleteDialog
        open={!!deleteUser}
        onConfirm={handleDeleteUser}
        onClose={() => setDeleteUser(null)}
        dialogTitle={t('administration:userManagement:deleteUser:title')}
        dialogText={
          <Trans
            i18nKey='administration:userManagement:deleteUser:text'
            values={{
              user: deleteUser?.userName,
              lineBreak: '<br />',
            }}
            components={{ bold: <strong /> }}
          />
        }
      />

      <InviteUser
        open={openInviteUser}
        onClose={() => setOpenInviteUser(false)}
        onInvite={handleInviteUser}
        roles={roles?.map((role) => ({
          ...role,
          i18nKey: `${role.name.replace(/\s/g, '')}`,
        }))}
      />

      <CustomSnackbar
        severity='success'
        open={createSuccess || deleteSuccess}
        onClose={handleSnackbarClose}
        message={
          createSuccess
            ? t('administration:userManagement:invite:successMsg')
            : t('administration:userManagement:deleteUser:successMsg')
        }
      />
      <CustomSnackbar
        severity='error'
        open={!!errorUserCall}
        onClose={handleSnackbarClose}
        message={t('common:apiErrorMsg')}
      />
    </Box>
  );
};

export default UserManagement;
