import { AxiosError, AxiosInstance } from 'axios';
import { getToken } from '../auth';

export const setAuthHeader = (instance: AxiosInstance) =>
  instance.interceptors.request.use(
    async (request) => {
      const accessToken = await getToken();

      request.headers.Authorization = `Bearer ${accessToken}`;
      return request;
    },
    (error) => {
      return Promise.reject(error);
    },
  );

export const getErrorMessage = (error: AxiosError) => {
  if (error?.response?.data?.message) {
    return error?.response?.data?.message;
  } else if (error?.message) {
    return error?.message;
  } else {
    return error;
  }
};
