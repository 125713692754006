import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from '../../../../../hooks/redux';
import { ReactSVG } from 'react-svg';
import { Box, Typography, styled } from '@mui/material';
import { selectLoading, selectMachineDetails, selectTelemetry } from '../../../../../store/selectors/machine';
import Grid from '@mui/material/Unstable_Grid2'; // Grid version 2
import HdrAutoOutlinedIcon from '@mui/icons-material/HdrAutoOutlined';
import BuildOutlinedIcon from '@mui/icons-material/BuildOutlined';
import Availability from '../partials/Availability';
import SpindleSpeeds from '../partials/SpindleSpeeds';
import Overrides from '../partials/Overrides';
import Loader from '../../../../../components/Loader';
import { formatDate, machineTypeToImgUrl } from '../../../../../helpers';

/* ------- Styles ------- */
const InfoPane = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
  gap: '24px',
  height: '100%',
}));

const InfoCards = styled(Box)(() => ({
  display: 'grid',
  gridTemplateRows: '1fr 1fr 1fr',
  gap: '24px',
  height: '50%',
}));

const ICard = styled(Box)(({ theme }) => ({
  display: 'flex',
  gap: '16px',
  alignItems: 'center',
  padding: '20px',
  border: `1px solid ${theme.palette.grey[20]}`,
  borderRadius: '4px',
}));

const IconWrapper = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  width: '48px',
  height: '48px',
  border: `1px solid ${theme.palette.grey[20]}`,
  borderRadius: '100%',
}));

const MachineParams = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
  gap: '24px',
  width: '100%',
  height: '100%',
}));

/* ------- Types ------- */
interface IInfoCardProps {
  icon: React.ReactNode;
  label: string;
  value: string | number;
  sx?: object;
  iconWrapperSx?: object;
}

/* ------- Components ------- */
const InfoCard: React.FC<IInfoCardProps> = ({ icon, label, value, sx, iconWrapperSx }) => (
  <ICard sx={{ gap: '24px', ...sx }}>
    <IconWrapper sx={iconWrapperSx}>{icon}</IconWrapper>
    <Box>
      <Typography color={(theme) => theme.palette.black[60]}>{label}</Typography>
      <Typography sx={{ size: '18px', fontWeight: 500 }}>{value}</Typography>
    </Box>
  </ICard>
);

const Overview = () => {
  const {
    t,
    i18n: { language },
  } = useTranslation();
  const loading = useSelector(selectLoading);
  const machineDetails = useSelector(selectMachineDetails);
  const telemetry = useSelector(selectTelemetry);
  const controlVoltageOn = telemetry.telemetryData?.controlVoltage === 1;

  const getProgrammStatus = () => {
    if (telemetry.telemetryData?.programAutomatic === 1) return t('lab:detailView:overview:programStatus.automatic');
    if (telemetry.telemetryData?.programManual === 1) return t('lab:detailView:overview:programStatus.manual');
    return t('lab:detailView:overview:programStatus.unknown');
  };

  return loading ? (
    <Loader />
  ) : (
    <Grid container spacing={3}>
      <Grid xs={3}>
        <InfoPane>
          <img
            src={machineDetails?.type && machineTypeToImgUrl(machineDetails.type)}
            alt={machineDetails?.type}
            height={344}
            style={{ objectFit: 'cover', borderRadius: '4px' }}
          />
          <InfoCards>
            <InfoCard
              icon={
                telemetry.telemetryData?.programAutomatic === 0 ? (
                  <HdrAutoOutlinedIcon />
                ) : (
                  <ReactSVG src='/images/icons/manual.svg' style={{ display: 'inline-block', marginTop: '6px' }} />
                )
              }
              label={t('lab:detailView:overview:programStatus.title')}
              value={getProgrammStatus()}
            />
            <InfoCard
              icon={<BuildOutlinedIcon />}
              label={t('lab:detailView:overview:lastMaintenance')}
              value={formatDate(new Date().toDateString(), language, {
                day: '2-digit',
                month: '2-digit',
                year: 'numeric',
              })}
            />
            <InfoCard
              sx={{
                borderColor: (theme) => (!controlVoltageOn ? theme.palette.error.main : theme.palette.grey[20]),
              }}
              iconWrapperSx={{
                borderColor: (theme) => (!controlVoltageOn ? theme.palette.error.main : theme.palette.grey[20]),
                backgroundColor: (theme) => (!controlVoltageOn ? theme.palette.error.main : theme.palette.white.main),
              }}
              icon={
                <ReactSVG
                  src='/images/icons/voltage.svg'
                  style={{ display: 'inline-block', marginTop: '6px', color: !controlVoltageOn ? 'white' : 'black' }}
                />
              }
              label={t('lab:detailView:overview:controlVoltage.title')}
              value={
                controlVoltageOn
                  ? t('lab:detailView:overview:controlVoltage.on')
                  : t('lab:detailView:overview:controlVoltage.off')
              }
            />
          </InfoCards>
        </InfoPane>
      </Grid>

      <Grid xs={9}>
        <MachineParams>
          <Availability />

          <Grid container spacing={3}>
            <Grid xs={12} md={9}>
              <ICard sx={{ flexDirection: 'column', height: '100%' }}>
                <Typography variant='h6' sx={{ fontWeight: 700, alignSelf: 'flex-start' }}>
                  {t('lab:detailView:overview:spindleSpeeds:title')}
                </Typography>
                <SpindleSpeeds />
              </ICard>
            </Grid>

            <Grid xs={12} md={3}>
              <ICard sx={{ flexDirection: 'column', height: '100%' }}>
                <Typography variant='h6' sx={{ fontWeight: 700 }}>
                  {t('lab:detailView:overview:overrides:title')}
                </Typography>
                <Overrides
                  spindle={telemetry.telemetryData?.overrideSpindle}
                  axis={telemetry.telemetryData?.overrideAxis}
                />
              </ICard>
            </Grid>
          </Grid>
        </MachineParams>
      </Grid>
    </Grid>
  );
};

export default Overview;
