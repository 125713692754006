import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from '../../../../../hooks/redux';
import { Box, Typography, styled } from '@mui/material';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import InsertDriveFileOutlinedIcon from '@mui/icons-material/InsertDriveFileOutlined';
import ContextMenu, { ContextButton } from '../../../../../components/ContextMenu';
import Loader from '../../../../../components/Loader';
import { selectKnowledgeFiles, selectMachineDetails } from '../../../../../store/selectors/machine';
import { downloadKnowledgeFilePending, fetchKnowledgeFilesPending } from '../../../../../store/slices/machine';

/* ------- Styles ------- */
const NoDocuments = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
  alignItems: 'center',
  verticalAlign: 'middle',
  height: '100%',

  '& svg': {
    fontSize: '74px',
    color: theme.palette.grey[80],
  },

  '& h6': {
    color: theme.palette.black[60],
    fontWeight: 700,
  },

  '& p': {
    color: theme.palette.black[60],
  },
}));

const KnowledgePreview = styled('div')(({ theme }) => ({
  display: 'flex',
  gap: '32px',
  justifyContent: 'space-between',
  alignItems: 'center',
  width: '100%',
  padding: '16px',
  borderRadius: '4px',
  border: `1px solid ${theme.palette.grey[20]}`,
}));

const FileType = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  width: '48px',
  height: '48px',
  borderRadius: '4px',
  backgroundColor: theme.palette.grey[20],
  color: theme.palette.grey[100],
  textTransform: 'uppercase',
  fontWeight: 500,
}));

const Title = styled(Typography)(({ theme }) => ({
  fontWeight: 500,
  fontSize: '16px',
  lineHeight: '23px',
  color: theme.palette.others.blue[100],
  textDecoration: 'underline',
}));

const Description = styled(Typography)(({ theme }) => ({
  fontWeight: 400,
  fontSize: '14px',
  lineHeight: '20px',
  color: theme.palette.black[60],
}));

/* ------- Components ------- */
const Actions: React.FC<{ onDownload: () => void }> = ({ onDownload }) => {
  const { t } = useTranslation();
  const [contextOpen, setContextOpen] = useState<boolean>(false);
  const menuAnchorRef = useRef<HTMLButtonElement | null>(null);

  const handleDownload = () => {
    setContextOpen(false);
    onDownload();
  };

  return (
    <Box>
      <ContextButton
        ref={menuAnchorRef}
        testId='tableActions'
        menuOpen={contextOpen}
        handleOpenMenu={() => setContextOpen(true)}
      >
        <MoreHorizIcon />
      </ContextButton>
      <ContextMenu
        menuOpen={contextOpen}
        anchorEl={menuAnchorRef.current}
        handleCloseMenu={() => setContextOpen(false)}
        contextActions={[{ name: t('common:download'), callback: handleDownload }]}
        hoverColor={(theme) => theme.palette.others.blue[20]}
      />
    </Box>
  );
};

const KnowledgeDatabase = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const knowledgeFiles = useSelector(selectKnowledgeFiles);
  const machineDetails = useSelector(selectMachineDetails);

  useEffect(() => {
    if (machineDetails) {
      dispatch(
        fetchKnowledgeFilesPending({
          machineType: machineDetails?.type,
          machineVersion: machineDetails?.version,
        }),
      );
    }
  }, [machineDetails, dispatch]);

  const handleFileDownload = (documentName: string) => {
    if (machineDetails) {
      dispatch(
        downloadKnowledgeFilePending({
          machineType: machineDetails?.type,
          machineVersion: machineDetails?.version,
          documentName,
        }),
      );
    }
  };

  return knowledgeFiles.loading ? (
    <Loader />
  ) : (
    <>
      {!knowledgeFiles.list?.length ? (
        <NoDocuments>
          <InsertDriveFileOutlinedIcon />
          <Typography variant='h6' component='h6'>
            {t('lab:detailView:serviceDocuments:noDocuments:title')}
          </Typography>
          <Typography>{t('lab:detailView:serviceDocuments:noDocuments:text')}</Typography>
        </NoDocuments>
      ) : (
        <Box display='flex' flexDirection='column' gap='16px'>
          {knowledgeFiles.list?.map(({ name, description, size }) => (
            <KnowledgePreview key={name}>
              <Box display='flex' gap='16px' alignItems='center'>
                <FileType>PDF</FileType>
                <Box display={'flex'} flexDirection='column' gap='8px'>
                  <Title>{name}</Title>
                  <Description>{description}</Description>
                </Box>
              </Box>
              <Box display='flex' gap='40px' alignItems='center'>
                <Typography variant='body1' sx={{ color: (theme) => theme.palette.black[60] }}>
                  {Math.round(size * 0.001)} KB
                </Typography>
                <Actions onDownload={() => handleFileDownload(name)} />
              </Box>
            </KnowledgePreview>
          ))}
        </Box>
      )}
    </>
  );
};

export default KnowledgeDatabase;
