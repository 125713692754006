import { fork, all } from 'redux-saga/effects';
import user from './user.saga';
import machine from './machine.saga';
import notifications from './notifications.saga';
import tickets from './tickets.saga';

export default function* rootSaga() {
  yield all([fork(user)]);
  yield all([fork(machine)]);
  yield all([fork(notifications)]);
  yield all([fork(tickets)]);
}
